import  React,{useEffect, useState} from 'react';
import {Form, Input, Button, Select, notification, Spin, AutoComplete} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import { PHONE_NUMBER_REGEX } from 'shared/constants/AppConstants';
import ReCAPTCHA from 'react-google-recaptcha';
import API_SERVICE from "shared/services/api-service";

interface Props {
    fields: any[];
    onChange: (fields: any[]) => void;
    onLogin: (mobileNumber: string, password: string) => void;
    loading: boolean;
    setLoading: any;
    reCaptchaTokenForConfirmMobile: any;
    setReCaptchaTokenForConfirmMobile: any;
    showCaptchaForConfirmMobile: any;
    setShowCaptchaForConfirmMobile:any;
    
}

export default function LoginForm({ fields, onChange, onLogin, loading, setLoading, reCaptchaTokenForConfirmMobile, setReCaptchaTokenForConfirmMobile, showCaptchaForConfirmMobile,setShowCaptchaForConfirmMobile}: Props) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [loadingCaptcha, setLoadingCaptcha] = useState(false);

    const prefixSelector = (
        <Form.Item name="phoneCode" noStyle>
          <Select>
            <Select.Option value="+91">+91</Select.Option>
          </Select>
        </Form.Item>
    );

    function checkMobile(e){
      if(e.target.value.length===10){
        setLoading(true);
            const payload = {
                mobileNumber: e.target.value,
                reCaptchaScreenName: "SIGN_IN"
              }                       
              API_SERVICE.checkRecaptchaResponse(payload)
              .then(({ data }) => {
                if (data) {
                  setShowCaptchaForConfirmMobile(data?.payload?.captchaRequired);
                } 
              })
              .catch(function (e) {
                setShowCaptchaForConfirmMobile(false);
              })
              .finally(() => {
                setLoading(false);
              });

        }  
    }

    function callSigninMobile(mobileNumber){

              const payload = {
                  mobileNumber: mobileNumber,
                  reCaptchaScreenName: "SIGN_IN"
                }                       
                API_SERVICE.checkRecaptchaResponse(payload)
                .then(({ data }) => {
                  if (data) {
                    setShowCaptchaForConfirmMobile(data?.payload?.captchaRequired);
                  } 
                })
                .catch(function (e) {
                  setShowCaptchaForConfirmMobile(false);
                })
      }

    function handleSubmit(values: any) {
        callSigninMobile(values.mobileNumber);
        if (onLogin) {

            if ((showCaptchaForConfirmMobile && reCaptchaTokenForConfirmMobile.length === 0)){
                notification.error({
                  message: "Please check reCaptcha",
                });
                setLoading(false);
              }
              else{
                setShowCaptchaForConfirmMobile(false);
            setReCaptchaTokenForConfirmMobile("");
                const {mobileNumber, password} = values;
                onLogin(mobileNumber, password);
              }
        }
    }
    return (
        <Form initialValues={{ phoneCode: '+91'}}
            size="large"
            form={form}
            fields={fields}
            onFieldsChange={(changedFields, allFields) => {
                onChange(allFields);
            }}
            onFinish={handleSubmit}
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
        >            
            <Form.Item
                label={"Mobile No."}
                name="mobileNumber"
                rules={[
                    {required: true, message: 'Mobile no is required'},
                    {
                        pattern: PHONE_NUMBER_REGEX,
                        message: "Please enter valid number",
                    }
                ]}
            >
                <Input className='custom-input-phone' onChange={checkMobile} prefix="+91" autoComplete="off" />
            </Form.Item>
            <div className="__password">
                <Form.Item
                    label={"Password"}
                    name="password"
                    rules={[
                        {required: true, message: 'Password is required'},
                    ]}
                >
                    <Input.Password
                        type="password"  className='custom-input-phone' autoComplete="off"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
            </div>
            <br/>

                    {showCaptchaForConfirmMobile &&
                      <div className="g-recaptcha">
                        <Form.Item name="reCaptchaToken">

                            <ReCAPTCHA
                            sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY" // Replace with your site key
                            onChange={(token) => setReCaptchaTokenForConfirmMobile(token)}
                            />
                        </Form.Item>
                      </div>
                    }
                    

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    size={"large"}
                    className="full-width"

                >
                    {"Login"}
                </Button>
            </Form.Item>
        </Form>
    );
}
