import "../Notices/index.scss";

import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import moment from "moment";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import {
  base64ToArrayBuffer,
  saveByteArray,
  hasUserRole,
} from "shared/services/Utility";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import {ReactComponent as FileBorder} from "../../assets/images/file-border.svg";
import {ReactComponent as IconEyeBorder} from "../../assets/images/eye_border.svg";
import {ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";
import {ReactComponent as IconDeleteBorder} from "../../assets/images/delete_border.svg";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";


type Props = {};

const Dialler: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const [allNotices, setAllNotices] = useState<"" | any>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [apiCount, setApiCount] = useState(0);
  const [pages, setPages] = useState();
  const [searchValue, setSearchValue] = useState(null as any);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState();
  const [form] = Form.useForm();
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Dialler Configuration"}));
    getAllDialler();
  }, []);

  const columns = [
    {
      title: "Campaign Name",
      key: "campaignName",
      dataIndex: "campaignName",
    },
    {
      title: "Endpoint",
      dataIndex: "endpoint",
      key: "endpoint",
    },
    {
      title: "Status",
      key: "status",
      render: (_: any, record: any) => (
        <Switch
          style={customStyles}
          checked={record.status}
          disabled={userRolePermission["ReadOnly"]}
          onChange={(event) => onChangeStatus(event, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Button
            className="custome-btn-icon-secondary"
            style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
            size="small"
            type="text"
            icon={<IconEditBorder />}
            disabled={userRolePermission["ReadOnly"]}
            hidden={
              !(
                userRolePermission["UpdateLead"] ||
                userRolePermission["UpdateOperations"] ||
                userRolePermission["Admin"]
              )
            }
            onClick={() => goToLeadDetails(record)}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={(event) => handleDelete(event, record)}
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
          ><Button
                style={{backgroundColor:"transparent", border:'none'}}
                size="small"
                type="text"        
                disabled={userRolePermission["ReadOnly"]}
                hidden={
                  !(
                    userRolePermission["UpdateLead"] ||
                    userRolePermission["UpdateOperations"] ||
                    userRolePermission["Admin"]
                  )
                }
                onClick={() => handleDelete}
                icon={<IconDeleteBorder width={24} height={24}/>}
              />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleDelete = (event: any, item: any) => {
    console.log("item",item);
    const id = item.campaignId;
    setLoading(true);
    API_SERVICE.getAPIForDeleteDiallerInList(id)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.payload });
          let page = null as any;
                    if((totalCount/10) === parseFloat((currentPage-1)+"."+1)){
                        setCurrentPage(1);
                        page = 1;
                    }else{
                        setCurrentPage(currentPage);
                        page = currentPage;
                    }
          getAllDialler(page,pageSize);
        }
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const goToLeadDetails = (item: any) => {

    history.push(`/dialler/add-dialler/${item.campaignId}`);
  };

  const getAllDialler = (pageNo: number = 1,pageSize: any = 10, searchVal: any = null) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, filterNameParam: searchVal};
    API_SERVICE.getDialler(params)
      .then(({ data }: any) => {
        //console.log("data",data.content);
        if (data.content.length > 0) {
          mapLeadsData(data);
        } else {
          notification.error({ message: "No Dialler found." });
          mapLeadsData({});
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
    if (allNotices.length !== 0) {
    }
  };

  const mapLeadsData = (data: any) => {
    setTotalCount(data?.totalElements ?? 0);
    setAllNotices(data?.content ?? []);
    setApiCount(1);
  };

  const onChangeStatus = (event: any, item: any) => {
    item.status = event;
    const id = item.campaignId;
    setLoading(true);
    API_SERVICE.updateDialler(
      {
        status: item.status,
      },
      id
    )
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.payload });
          getAllDialler(currentPage,pageSize,searchValue);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const addOfferHandler = () => {
    history.push("/dialler/add-dialler");
  };

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearchValue(event.target.value);
    getAllDialler(1,pageSize,event.target.value);
  };

  const handlePaginationChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setTotal( pagination.total);
    getAllDialler(pagination.current, pagination.pageSize);
    setPages(pagination.current);
  };

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};

  return (
    <>
      <div className="content-box">
        <Form form={form}>
        <Row>
          <Col className="filter-bar" span={20} xs={{ span: 12 }}>
          <Form.Item name="searchBox">
            <Input
              size="large"
              className="searchBox"
              onChange={debounce(handleSearch, 1000)}
              placeholder="Search"
              allowClear
              prefix={<FaSearch />}
            />
            </Form.Item>
          </Col>

          <Col span={4} xs={{ span: 12 }} className="filter-bar filter-bar-right">
          <Button
              type="primary"
              className="refresh-button"
              onClick={() => {
                form.setFieldsValue({
                  searchBox: ""
                })
                setSearchValue("");
                getAllDialler(currentPage, pageSize);
              }}
              shape="round"
              //icon={<ReloadOutlined />}
              size={"middle"}
            ><img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}}/></Button>
            <Button
              type="primary"
              className="add-button dynamic-btn-primary"
              style={btnPrimaryStyles}
              onClick={addOfferHandler}
              shape="round"
              icon={<FaPlus />}
              size={"middle"}
              disabled={!(userRolePermission["Admin"] ||  userRolePermission["CreateOperations"])}
              hidden={
                !(
                  userRolePermission["CreateLead"] ||
                  userRolePermission["CreateOperations"] ||
                  userRolePermission["Admin"]
                )
              }
            >
              Add Campaign
            </Button>
          </Col>
        </Row>
        </Form>
        <Row>
          <Col span={24}>
            <Table
              className="dynamic-pagination-checkbox paginationview"
              style={customStyles}
              loading={loading}
              columns={columns}
              dataSource={allNotices}
              size="small"
              onChange={handlePaginationChange}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount, // total count returned from backend
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dialler;
