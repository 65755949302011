import {
  Button,
  Checkbox,
  Col,
  Input,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import "./permissions.scss";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { Title } = Typography;
const { Search } = Input;

const EditPermissions: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [allLoanType, setAllLoanType] = useState(false);
  const [allLender, setAllLender] = useState(false);
  const [allLocation, setAllLocation] = useState(false);
  const [searchedLenders, setSearchedLenders] = useState([] as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  const [searchedLoanTypes, setSearchedLoanTypes] = useState([] as any);
  const [searchedLocations, setSearchedLocations] = useState([] as any);
  const dispatch = useDispatch();

  let { id } = useParams() as any;
  const permissionName: any = location.state;
  if (!id) {
    history.goBack();
  }

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Permissions",
        backScreenPath: "/permissions",
        screenTitle: permissionName?.fullName,
      })
    );
    getUsersList();
  }, []);

  const onAdminRoleCheckboxClick = (userDataPayload, checked, isAdmin) => {
    if (isAdmin) {
      userDataPayload.loanProducts = userDataPayload.loanProducts.map(
        (item) => {
          item.selected = checked;
          return item;
        }
      );

      userDataPayload.locations = userDataPayload.locations.map((item) => {
        item.selected = checked;
        return item;
      });

      userDataPayload.lenders = userDataPayload.lenders.map((item) => {
        item.hub.selected = checked;
        return item;
      });
      setUser(userDataPayload);
      setAllLender(checked);
      setAllLoanType(checked);
      setAllLocation(checked);
    }
  };
  const getUsersList = () => {
    API_SERVICE.getUsersPermission(id).then(({ data }: any) => {
      if (data.payload) {
        if(tenantInfo?.businessProductType === "INSURANCE") {
          const roles = data.payload?.roles?.filter((item) => !item?.roleName?.includes("Payout"))
          setUser({...data.payload, roles: roles})
        } else {
          setUser(data.payload);
        }
        setSearchedLenders(data.payload.lenders);
        setSearchedLoanTypes(data.payload.loanProducts);
        setSearchedLocations(data.payload.locations);
        const findLenders = data.payload.lenders.find(
          (lender) => !lender.hub.selected
        );
        setAllLender(!findLenders);
        const findLoanType = data.payload.loanProducts.find(
          (type) => !type.selected
        );
        setAllLoanType(!findLoanType);
        const findLocations = data.payload.locations.find(
          (type) => !type.selected
        );
        setAllLocation(!findLocations);
      }
    });
  };

  const getUsersPermissions = () => {
    const user = localStorage.getItem("user");
    API_SERVICE.getUsersPermission(
      JSON.parse(user as string).userDetails.userId
    )
      .then(({ data }) => {
        if (data) {
          localStorage.setItem(
            "userPermission",
            JSON.stringify(data.payload.roles)
          );
          window.location.reload();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const savePermission = () => {
    setLoading(true);
    const payload = {
      lenders: user.lenders,
      loanProducts: user.loanProducts,
      locations: user.locations,
      roles: user.roles,
    };
    API_SERVICE.updatePermission(id, payload)
      .then(({ data }: any) => {
        if (data.payload) {
          notification.success({ message: data.message });
          setUser(data.payload);
          setSearchedLenders(data.payload.lenders);
          // getAllTypes()
          getUsersPermissions();
          history.push("/permissions");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeAllLoanType = (event: any) => {
    setAllLoanType(event.target.checked);
    let userData = { ...user };
    for (let i = 0; i < userData.loanProducts.length; i++) {
      userData.loanProducts[i].selected = event.target.checked;
    }
    setUser(userData);
  };

  const onLoanTypeChange = (event, index) => {
    let onLoanTypeChange = [...searchedLoanTypes];
    onLoanTypeChange[index].selected = event.target.checked;
    setSearchedLoanTypes(onLoanTypeChange);

    let userData = { ...user };
    const tmpUserData = user.loanProducts.map((type) => {
      if (type == searchedLoanTypes[index]) {
        return onLoanTypeChange[index];
      } else {
        return type;
      }
    });
    userData.loanProducts = tmpUserData;
    setUser(userData);

    const find = onLoanTypeChange.find((type) => !type.selected);
    setAllLoanType(!find);
  };

  const onChangeAllLocation = (event: any) => {
    setAllLocation(event.target.checked);
    let userData = { ...user };
    for (let i = 0; i < userData.locations.length; i++) {
      userData.locations[i].selected = event.target.checked;
    }
    setUser(userData);
  };


  const onLocationChange = (event, index) => {
    let onLocationChange = [...searchedLocations];
    onLocationChange[index].selected = event.target.checked;
    setSearchedLocations(onLocationChange);

    let userData = { ...user };
    const tmpUserData = user.locations.map((type) => {
      if (type == searchedLocations[index]) {
        return onLocationChange[index];
      } else {
        return type;
      }
    });
    userData.locations = tmpUserData;
    setUser(userData);

    const find = onLocationChange.find((type) => !type.selected);
    setAllLocation(!find);
  };

  const onRolesChange = (event: any, index: number) => {
    let userData = { ...user };
    userData.roles[index].selected = event.target.checked;
    setUser(userData);

    onAdminRoleCheckboxClick(
      userData,
      event.target.checked,
      event.target.value === "Admin"
    );
  };

  const onChangeAllLenders = (event: any) => {
    setAllLender(event.target.checked);

    let tmpSearchedLenders = [...searchedLenders];
    for (let i = 0; i < tmpSearchedLenders.length; i++) {
      tmpSearchedLenders[i].hub.selected = event.target.checked;
    }
    setSearchedLenders(tmpSearchedLenders);

    let userData = { ...user };
    const tmpUserData = user.lenders.map((lender) => {
      const filetr = tmpSearchedLenders.filter((lender1) => lender1 === lender);
      if ((filetr?.length ?? 0) > 0) {
        return filetr[0];
      } else {
        return lender;
      }
    });
    userData.lenders = tmpUserData;
    setUser(userData);
  };

  const onLenderTypeChange = (event, index) => {
    let tmpSearchedLenders = [...searchedLenders];
    tmpSearchedLenders[index].hub.selected = event.target.checked;
    setSearchedLenders(tmpSearchedLenders);

    let userData = { ...user };
    const tmpUserData = user.lenders.map((lender) => {
      if (lender == searchedLenders[index]) {
        return tmpSearchedLenders[index];
      } else {
        return lender;
      }
    });
    userData.lenders = tmpUserData;
    setUser(userData);

    const find = tmpSearchedLenders.find((lender) => !lender.hub.selected);
    setAllLender(!find);
  };

  const onSearchLender = (e) => {
    const searched = user?.lenders?.filter(
      (item) =>
        item?.bankName?.toLowerCase().includes(e.target.value) ||
        item?.hub?.hubName?.toLowerCase().includes(e.target.value)
    );
    setSearchedLenders(searched);

    const find = searched.find((lender) => !lender.hub.selected);
    setAllLender(!find);
  };

  const onSearchLoanType = (e) => {
    const searched = user?.loanProducts?.filter((item: any) =>
      item?.loanProduct?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchedLoanTypes(searched);
  
    const find = searched.find((type) => !type.selected);
    setAllLoanType(!find);
  };

  const onSearchLocation = (e: any) => {
    const searched = user?.locations?.filter((item: any) =>
      item?.officeName?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchedLocations(searched);
  
    const find = searched.find((location) => !location.selected);
    setAllLocation(!find);
  };

  const customStyles: any = { "--bgColor": appColors?.appPrimaryColor ?? "" };
  const btnPrimaryStyles: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
  };

  return (
    <div className="permission-wrapper content-box mb-20">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={6} span={6}>
          <div className="permission-col">
            <Title className="permission-heading" level={5}>
              {tenantInfo?.businessProductType === "INSURANCE"
                ? "Product"
                : "Loan Type"}
            </Title>
            <div className="permission-box">
            <Space direction="vertical" style={{ width: "100%" }}>
                <Search placeholder="Search Loan Type" 
                onChange={onSearchLoanType} 
                style={{ width: "100%" }}
                />
              <div className="lender-list-box">
                  {(searchedLoanTypes?.length ?? 0) > 0 && (
                    <Checkbox
                      className="dynamic-pagination-checkbox"
                      style={customStyles}
                      onChange={onChangeAllLoanType}
                      key={"allLoanType"}
                      checked={allLoanType}
                    >
                      Select All
                    </Checkbox>
                  )}
                  {searchedLoanTypes?.map((item, i) => (
                    <Checkbox
                      className="dynamic-pagination-checkbox"
                      style={customStyles}
                      onChange={(e) => onLoanTypeChange(e, i)}
                      key={i}
                      checked={item.selected}
                      value={item.loanProduct}
                    >
                      {item.loanProduct}
                    </Checkbox>
                  ))}
              </div>
            </Space>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={6} span={6}>
          <div className="permission-col">
            <Title className="permission-heading" level={5}>
              {tenantInfo?.businessProductType === "INSURANCE"
                ? "Insurer"
                : "Lender"}
            </Title>
            <div className="permission-box">
              <Space direction="vertical" style={{ width: "100%" }}>
                <Search
                  placeholder="Search Lender"
                  onChange={onSearchLender}
                  style={{ width: "100%" }}
                />
                <div className="lender-list-box">
                  {(searchedLenders?.length ?? 0) > 0 && (
                    <Checkbox
                      className="dynamic-pagination-checkbox"
                      style={customStyles}
                      onChange={onChangeAllLenders}
                      key={"allLender"}
                      checked={allLender}
                    >
                      Select All
                    </Checkbox>
                  )}
                  {searchedLenders?.map((item, i) => (
                    <Checkbox
                      className="dynamic-pagination-checkbox"
                      style={customStyles}
                      onChange={(e) => onLenderTypeChange(e, i)}
                      key={i}
                      checked={item.hub.selected}
                      value={item.bankName}
                    >
                      {`${item.bankName} ${item?.hub?.hubName ? "-" : ""} ${
                        item?.hub?.hubName ?? ""
                      }`}
                    </Checkbox>
                  ))}
                </div>
              </Space>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={6} span={6}>
          <div className="permission-col">
            <Title className="permission-heading" level={5}>
              Location
            </Title>
            <div className="permission-box">
            <Space direction="vertical" style={{ width: "100%" }}>
                <Search 
                placeholder="Search Location"
                onChange={onSearchLocation}
                style={{ width: "100%" }}
                />
              <div className="lender-list-box">
                  {(searchedLocations?.length ?? 0) > 0 && (
                    <Checkbox
                      className="dynamic-pagination-checkbox"
                      style={customStyles}
                      onChange={onChangeAllLocation}
                      key={"allLocation"}
                      checked={allLocation}
                    >
                      Select All
                    </Checkbox>
                  )}
                  {searchedLocations?.map((item, i) => (
                    <Checkbox
                      className="dynamic-pagination-checkbox"
                      style={customStyles}
                      onChange={(e) => onLocationChange(e, i)}
                      key={i}
                      checked={item.selected}
                      value={item.officeName}
                    >
                      {item.officeName}
                    </Checkbox>
                  ))}
              </div>
            </Space>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={6} span={6}>
          <div className="permission-col">
            <Title className="permission-heading" level={5}>
              Role
            </Title>
            <div className="permission-box">
              <div className="lender-list-box" style={{ height: "570px" }}>
                <Space direction="vertical">
                  {user?.roles?.map((item, i) => (
                    <Checkbox
                      className="dynamic-pagination-checkbox"
                      style={customStyles}
                      onChange={(e) => onRolesChange(e, i)}
                      key={i}
                      checked={item.selected}
                      value={item.roleName}
                    >
                      {item.roleName}
                    </Checkbox>
                  ))}
                </Space>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="permission-btn">
        <Button
          type="primary"
          loading={loading}
          onClick={savePermission}
          className="dynamic-btn-primary"
          style={btnPrimaryStyles}
        >
          Save & Update
        </Button>
      </Row>
    </div>
  );
};

export default EditPermissions;
