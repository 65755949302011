import { Button, Checkbox, Form, Modal, Table, Tooltip, Typography, notification } from "antd";
import { ColumnsType } from "antd/lib/table";
import arrowLeft from "../../../../../assets/images/arrow_left.svg";
import { ReactComponent as ArrowLeft } from "../../../../../assets/images/arrow_left.svg";
import wrong from "../../../../../assets/images/wrong.svg";
import apporved from "../../../../../assets/images/emptyApproved.svg";
import blueApporved from "../../../../../assets/images/emptyBlueApporved.svg";
import API_SERVICE from "shared/services/api-service";
import { useEffect, useState } from "react";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { getEnumKey, getEnumValue } from "shared/services/Utility";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { ReactComponent as IconAction } from "../../../../../assets/images/bluePlay.svg";
import {ReactComponent as IconInfo} from "../../../../../assets/images/info.svg"

interface IVasOutput {
  setLeadViewType: any;
  leadData: any;
  leadViewType:any
  selectedLenderExecutionDataList:any
  descisioningDetail:any;
  setListDescisioningDetail:any;
  decisioningnTabkey:any
  setDecisioningTabkey:any
  productWorkflowConfig?:any
}

const { Title } = Typography;

const VASOutput: React.FC<IVasOutput> = (props: IVasOutput) => {
  const { 
    setLeadViewType, 
    leadData, 
    leadViewType,
    selectedLenderExecutionDataList,
    descisioningDetail,
    setListDescisioningDetail,
    decisioningnTabkey,
    setDecisioningTabkey,
    productWorkflowConfig
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [vasStatusData, setVasStatusData] = useState([] as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const [selectedJourneyId, setSelectedJourneyId] = useState([leadData?.journeyConfigResponseList?.at(0)?.journeyId]);
  const [showDecisioningLoop, setShowDecisioningLoop] = useState(false)
  const [checkEligibilityError, setCheckEligibilityError] = useState(false)
  const [checkEligibility, setCheckEligibility] = useState(descisioningDetail?.at(0)?.executedBy!=undefined)
  const [checkLenderExecutionError, setCheckLenderExecutionError] = useState(false)
  
   useEffect(() => {
      getVASActionStatus();
      setDecisioningTabkey(decisioningnTabkey===undefined ? leadData?.journeyConfigResponseList?.at(0)?.journeyId : decisioningnTabkey)
      const tmpDecisionData = descisioningDetail
        ?.filter(record => record.journeyId === decisioningnTabkey)
        .map(record => ({
          executedBy: record?.executedBy,
      }));
      setCheckEligibility((tmpDecisionData[0]?.executedBy.length ?? 0) > 0 )
    },[decisioningnTabkey, descisioningDetail])

  const getVASActionStatus = () => {
    setIsLoading(true);
    API_SERVICE.getVASActionStatus(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        const tmpData = [
          {
            vas: getEnumValue("MASTER_PRODUCT_VAS_SERVICES", "BANK_STATEMENT_ANALYSIS"),
            status: data?.payload?.vasTypeAndStatusMap?.BANK_STATEMENT_ANALYSIS,
          },
          {
            vas: getEnumValue("MASTER_PRODUCT_VAS_SERVICES", "GET_BUREAU"),
            status: data?.payload?.vasTypeAndStatusMap?.GET_BUREAU?.status,
            color: data?.payload?.vasTypeAndStatusMap?.GET_BUREAU?.color
          },
        ];
        const tmpDecisionData = descisioningDetail
        ?.filter(record => record.journeyId === decisioningnTabkey)
        .map(record => ({
          executedBy: record?.executedBy,
          executedOn: record?.executionTime,
          offerExecutedBy: record?.offerExecutedBy,
          offerExecutedOn: record?.offerExecutionTime
        }));
        const tmpVasDecisionData = [
        productWorkflowConfig?.stages[2]?.vas?.at(0)?.isActive && 
        {
          vas: getEnumValue("MASTER_PRODUCT_VAS_SERVICES", "CHECK_ELIGIBILITY"),
          executedBy:tmpDecisionData[0]?.executedBy,
          executedOn:tmpDecisionData[0]?.executedOn,
        },
        productWorkflowConfig?.stages[2]?.vas?.at(1)?.isActive && 
        {
          vas: getEnumValue("MASTER_PRODUCT_VAS_SERVICES", "CHECK_OFFER"),
          executedBy:tmpDecisionData[0]?.offerExecutedBy,
          executedOn:tmpDecisionData[0]?.offerExecutedOn,
        },
      ].filter(Boolean)
      leadViewType === "decisioningOutput"?setVasStatusData(tmpVasDecisionData):setVasStatusData(tmpData)
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const columns: ColumnsType<any> = [
    {
      title: "VAS",
      dataIndex: "vas",
      key: "vas",
      render: (rec: any) => (
        <span>{getEnumValue("MASTER_PRODUCT_VAS_SERVICES", rec)}</span>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (rec: any, _: any) => {
        return rec == "Expired" || rec == "Incompleted" ? (
          <span style={{ color: _?.color ? _?.color : "#E12121" }}>
            <img src={wrong} alt="" width={20} /> {rec}
          </span>
        ) : rec == "Completed" ? (
          <span style={{ color: _?.color ? _?.color : "#20A74E" }}>
            <img src={apporved} alt="" width={20} /> {rec}
          </span>
        ) : rec == "Initiated" ? (
          <span style={{ color: _?.color ? _?.color : "#273896" }}>
            <img src={blueApporved} alt="" width={20} /> {rec}
          </span>
        ) : (
          <span style={{ color: _?.color ? _?.color : "#E12121" }}>{rec ?? "--"}</span>
        );
      },
    },
  ];

  const decisioningColumns : ColumnsType<any> = [
    {
      title: "VAS Services",
      dataIndex: "vas",
      key: "vas",
      render: (rec: any) => (
        <span>{getEnumValue("MASTER_PRODUCT_VAS_SERVICES", rec)}
         <Tooltip
          title= {getEnumValue("MASTER_PRODUCT_VAS_SERVICES", rec) === "Check Eligibility" ? "Run credit analysis to ensure successful eligibility check" 
          :"Run bank statement analyzer for accurate business Installment Loan offer output" }> <IconInfo />
         </Tooltip>
        </span>
      ),
    },
    {
      title: "Executed On",
      dataIndex: "executedOn",
      key: "executedOn",
    },
    {
      title: "Executed By",
      dataIndex: "executedBy",
      key: "executedBy",
    },
    {
      title: "Action",
      dataIndex: "status",
      render: (_: any, rec: any) => {
          return (
          <Button
            className="custome-btn-icon"
            style={{ '--bgColor': appColors?.appPrimaryColor ?? "", '--width': "16px" } as any}
            icon={<IconAction />}
            type="text"
            onClick={() => onVasAction(getEnumKey("MASTER_PRODUCT_VAS_SERVICES", rec))}
          />
        );
      },
    },
  ];

  const onVasAction = (data:any)=>{
    let vas= getEnumKey("MASTER_PRODUCT_VAS_SERVICES",data?.vas)
    if(vas === "CHECK_OFFER"){
      if(!checkEligibility){
        setCheckEligibilityError(true) 
      }
      else if(checkEligibility && ((selectedLenderExecutionDataList?.length ?? 0) == 0)){
        setCheckLenderExecutionError(true)
      }
      else{
        getCheckEligibilityOffer(vas)
      }
    }
    if (vas === "CHECK_ELIGIBILITY"){
      if (leadData?.journeyConfigResponseList.length > 1){
        setSelectedJourneyId([])
        setShowDecisioningLoop(true)
      }
      else{
        getCheckEligibilityOffer(vas)
      }
    }
  }
    
  const getCheckEligibilityOffer = (checkEligibiltyType:any) =>{
    let payload
    if(checkEligibiltyType === "CHECK_ELIGIBILITY")
    payload={
      vas : checkEligibiltyType,
      caseId:leadData?.caseDetails?.caseId,
      selectedJourneys:selectedJourneyId
    }   
    else{
      let tmpselectedLenderExecutionDataList = 
    selectedLenderExecutionDataList.map((item:any)=>{
     return{
       lenderId: item.lenderInfoId,
       decisioningPurpose:item.decisioningPurpose
     }          
   })
    payload={
     vas : checkEligibiltyType,
     caseId:leadData?.caseDetails?.caseId,
     selectedJourneys:[decisioningnTabkey],
     selectedLenderExecutionDataList:tmpselectedLenderExecutionDataList
   }          
   tmpselectedLenderExecutionDataList = null   
  }
  setIsLoading(true)
  API_SERVICE.checkEligibilityOffer(payload)
  .then(({data}) => {
    if(data?.payload){
      setCheckEligibility(true)
      setIsLoading(false)
      listDecisioning(checkEligibiltyType)
    }      
  })
  .catch((e: any) => {
      setCheckEligibility(false)
      notification.error({ message: API_SERVICE?.handleErrors(e) });
      setIsLoading(false);
  })
  .finally(() => {
    setIsLoading(false)
  })
  }
  
  const listDecisioning = (vasType:any=null) => {
    setIsLoading(true)
    let tmpId = leadData?.journeyConfigResponseList?.map((item: any) => {
      return item?.journeyId
    })
    API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, true, '', tmpId)
      .then(({ data }) => {
        setListDescisioningDetail(data?.payload?.journeyWiseDecisioningDataList);
        setDecisioningTabkey(vasType === "CHECK_ELIGIBILITY" ? selectedJourneyId.includes(decisioningnTabkey)?decisioningnTabkey:selectedJourneyId.toString():decisioningnTabkey)
        setSelectedJourneyId(selectedJourneyId.includes(decisioningnTabkey)?[decisioningnTabkey]:selectedJourneyId)
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const getColumns = () => {
    const tmpCol = leadViewType === "VasOutput"
        ? columns
        : decisioningColumns

    return tmpCol

  };
  const renderLenderExecutionError = () =>{
    return (
      <Modal
        title=""
        centered
        width={290}
        closable={false}
        footer={null}
        visible={checkLenderExecutionError}
      >
        <div style={{ padding: "10px" }}>
          <div style={{ textAlign: "center" }}>Please select a lender first to calculate offers.</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              className="dynamic-btn-primary"
              style={modalBtnPrimaryStyles}
              size="large"
              type="primary"
              onClick={() => setCheckLenderExecutionError(false)}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderCheckEligibilityError = () =>{
    return (
        <Modal
          title=""
          centered
          width={290}
          closable={false}
          footer={null}
          visible={checkEligibilityError}
        >
          <div style={{ padding: "10px" }}>
            <div style={{ textAlign: "center" }}>Eligibility check is required before calculating offers. Please run 'Check Eligibility' first.</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <Button
                className="dynamic-btn-primary"
                style={modalBtnPrimaryStyles}
                size="large"
                type="primary"
                onClick={() => setCheckEligibilityError(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      );
    };
  
  const renderDecisioningLoop = () => {
    const onSubmitLoop = () => {
      setShowDecisioningLoop(false)
      const tmp = [...selectedJourneyId]
      setSelectedJourneyId(tmp)
      getCheckEligibilityOffer("CHECK_ELIGIBILITY")
    }    
    return (
      <Modal
        width={300}
        title="Select Decisioning Loop"
        visible={showDecisioningLoop}
        closable={true}
        maskClosable={false}
        footer={false}
        onCancel={()=>setShowDecisioningLoop(false)}
        centered
      >
        <Form>
          {leadData?.journeyConfigResponseList?.map((item: any, i: number) => {
            return (
              <Form.Item name={`check${i}`}>
                <Checkbox checked={selectedJourneyId?.some(
                  (id: any) => id == item?.journeyId
                )}
                  onChange={((e) => {
                    let tmp = [...selectedJourneyId];
                    if (selectedJourneyId?.includes(item?.journeyId)) {
                      tmp = selectedJourneyId?.filter(
                        (id: any) => id != item?.journeyId
                      );
                    } else {
                      tmp.push(item?.journeyId);
                    }
                    setSelectedJourneyId(tmp);
                  })}>{item?.journeyName}</Checkbox>
              </Form.Item>
            )
          })}
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" style={{ width: "100%" }} disabled={selectedJourneyId?.length == 0}
            onClick={onSubmitLoop}
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  
  const btnPrimaryStyles: any = { '--btnColor': appColors?.appPrimaryColor ?? "" };
  const modalBtnPrimaryStyles: any = { '--btnColor': appColors?.appPrimaryColor ?? "", width: "140px" }
 

  return (
    <>
     {leadViewType === "VasOutput"  &&         
      <div
        className="heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "1.1rem",
        }}
      >
       <>
          <Title level={5} style={{ marginTop: "15px" }}>
            VAS Output
          </Title>
          <Button
            onClick={() => setLeadViewType("LeadDetailNewTabs")}
            className="back-btn"
            style={btnPrimaryStyles}
            icon={<ArrowLeft width={20} />}
          >
            Back
          </Button>
        </>        
      </div>
      }
      {(vasStatusData?.length?? 0) > 0 &&
       <div style={{ width: "100%" }}>
        <Table
          columns={getColumns()}
          dataSource={vasStatusData}
          size="small"
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </div>
      }
      {isLoading && <AppLoading />}
      {showDecisioningLoop && renderDecisioningLoop()}
      {checkEligibilityError && renderCheckEligibilityError()}
      {checkLenderExecutionError && renderLenderExecutionError()}
    </>
  );
};

export default VASOutput;
