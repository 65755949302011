import React, { useEffect, useState } from "react";
import {
  Typography,
  Row,
  Col,
  Form,
  Button,
  Input,
  DatePicker,
  Select,
  notification,
  Radio,
  Space,
  InputNumber,
  Checkbox,
  List,
  AutoComplete,
  Popconfirm,
} from "antd";
import debounce from "lodash/debounce";
import API_SERVICE from "shared/services/api-service";
import type { RangePickerProps } from "antd/es/date-picker";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import {
  AADHAR_CARD_NUMBER_REGEX,
  AADHAR_CARD_NUMBER_REGEX_HIDE,
  NUMBER_REGEX,
  PHONE_NUMBER_REGEX,
} from "shared/constants/AppConstants";
import { PAN_REGAX } from "shared/constants/AppConstants";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { setScreenHeader } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { ReactComponent as FileBorder } from "../../assets/images/file-border.svg";
import { ReactComponent as DownloadBlack } from "../../assets/images/download-black.svg";
import { MdDelete } from "react-icons/md";
import { base64ToArrayBuffer, saveByteArray } from "shared/services/Utility";
import "./employee.scss";
import AppUpload from "shared/components/Lead/AppUpload/app-upload";
const { Text } = Typography;
type Props = {};

const EditEmployee: React.FunctionComponent<Props> = () => {
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [location, setLocation] = useState([]);
  const [lineManagers, setLineManagers] = useState([]);
  const [isPrimaryUser, setIsPrimaryUser] = useState(false);
  const [manager,setManager]=useState(" ");
  const [lineManager, setLineManager] = useState("");
  const [data, setData] = useState(null as any);
  const nameLocation = useLocation();
  const userName: any = nameLocation.state;
  const history = useHistory();
  let { id } = useParams() as any;
  const [form] = Form.useForm();
  const [genders, setGenders] = useState([]);
  const [isAadharChanged, setIsAadharChanged] = useState(false);
  const [isTelecaller, setIsTelecaller] = useState(false);
  const [isDisabledContinue, setIsDisabledContinue] = useState(true);
  const [bureauCreditDetails, setBureauCreditDetails] = useState(null as any);
  const [isDocumentUpload, setIsDocumentUpload] = useState(false);
  const [allFileList, setAllFileList] = useState([] as any);
  const [documentBusinessID, setDocBusinessID] = useState([] as any);
  const [documentDetails, setDocumentDetails] = useState([] as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Employee",
        backScreenPath: "/employees",
        screenTitle: userName?.fullName,
      })
    );
    getDepartments();
  }, []);

  if (!id) {
    history.goBack();
  }
  const getDepartments = () => {
    API_SERVICE.getDepartments()
      .then(({ data }) => setDepartment(data.payload))
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => getDesignation());
  };

  const getAllTypes = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        let filteredGenderType = data.find(
          (item: any) => item.propertyName === "GENDER"
        );
        setGenders(filteredGenderType.keyAndDisplayValueList);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => getUserDetails());
  };

  const getDesignation = () => {
    API_SERVICE.getDesignations()
      .then(({ data }) => setDesignation(data.payload))
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => getLocation());
  };

  const getLocation = () => {
    API_SERVICE.getLocations()
      .then(({ data }) => setLocation(data.payload))
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => getAllTypes());
  };

  const getManager = (value?: any) => {
    API_SERVICE.getUsersWithRole({ name: value })
      .then(({ data }) => setLineManagers(data.payload))
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getBureauCreditsData = () => {
    API_SERVICE.getBureauCredits(id)
      .then(({ data }) => setBureauCreditDetails(data?.payload))
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsDisabledContinue(false));
  };

  const bureauRequestsSummary = [
    {
      title: "Current Month Consumed:",
      list: bureauCreditDetails?.currentMonthConsumed,
    },
    {
      title: "Current Month Remaining:",
      list: bureauCreditDetails?.currentMonthRemaining,
    },
    {
      title: "Lifetime Consumed:",
      list: bureauCreditDetails?.lifeTimeConsumed,
    },
  ];

  const buildPayload = (formData: any) => {
    const payload = {
      aadhar: formData.aadharChange ?? data?.userDetails.aadhar,
      branch: formData.branch,
      department: formData.department,
      designation: formData.designation,
      gender: formData.gender,
      dob: moment(formData.dob).format("YYYY-MM-DD"),
      email: formData.email,
      lineManager: lineManager,
      name: formData.name.trim(),
      pan: formData?.pan?.toUpperCase() ?? "",
      panUploadUrl: "",
      phoneNumber: formData.phoneNumber,
      role: formData.role,
      userId: id,
      profileUploadUrl: "",
      bureauCredits: formData.bureauCredits,
      isTelecaller: isTelecaller,
      telecallerId: isTelecaller ? formData.telecallerId : "",
      documentsUrlList: Array.from(new Set(allFileList ?? [])),
    };
    return payload;
  };

  const onFinishForm = (values: any) => {
    setManager(values?.lineManager);
    if (values.bureauCredits > 99) {
      notification.error({
        message: "You can not enter Bureau Score Limit grater then 99",
      });
      return;
    }
    if (!isPrimaryUser && (manager==null && values?.lineManager.length==0)) {
      notification.error({
        message: "Please select proper Line Manager",
      });
      return;
    }
    const payload = buildPayload(values);
    API_SERVICE.updateEmployee(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/employees");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getUserDetails = () => {
    setDocumentDetails([]);
    API_SERVICE.getUser(id)
      .then(({ data }) => {
        setData(data?.payload);
        if (data) {
          const dob = data.payload.userDetails.dob;
          let gender = data?.payload?.userDetails?.gender;
          gender =
            gender === "Male"
              ? "M"
              : gender === "Female"
              ? "F"
              : gender === "Other"
              ? "O"
              : gender;
            setManager(data.payload.userDetails.lineManager);
          form.setFieldsValue({
            name: data.payload.userDetails.fullName,
            phoneNumber: data.payload.userDetails.phoneNumber,
            email: data.payload.userDetails.emailId,
            gender: gender,
            dob: dob ? moment(`${dob}`) : "",
            pan: data.payload.userDetails.panCard,
            aadhar: data.payload.userDetails.aadhar,
            department: data.payload.userDetails.department,
            designation: data.payload.userDetails.designation,
            branch: data.payload.userDetails.branch,
            bureauCredits: data?.payload?.userDetails?.bureauCredits,
            lineManager: data.payload.userDetails.lineManager,
            isTelecaller: data.payload.userDetails?.isTelecaller,
            telecallerId: data.payload.userDetails?.isTelecaller
              ? data.payload.userDetails?.telecallerId
              : null,
            isPrimaryUser:data.payload.userDetails.isPrimaryUser,
          });
          setIsPrimaryUser(data.payload.userDetails.isPrimaryUser);
          setIsTelecaller(data.payload.userDetails?.isTelecaller ?? false);
          getManager(data.payload.userDetails.lineManager);
          setLineManager(data.payload.userDetails?.managerDetails?.id ?? null);
          setDocBusinessID(
            data.payload.userDetails?.additionalInfo?.employeeDocumentIdList ??
              []
          );
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => getBureauCreditsData());
  };

  const dateFormat = "YYYY-MM-DD";

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
  };

  const onTelecaller = (e) => {
    setIsTelecaller(e.target.checked);
  };

  const btnRadioStyles: any = {
    "--borderColor": appColors?.appSecondaryColor ?? "",
  };
  const customStylesCheckbox: any = {
    "--bgColor": appColors?.appPrimaryColor ?? "",
    alignItems: "flex-start",
  };
  const btnPrimaryStyles: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
  };

  useEffect(() => {
    setIsDocumentUpload(tenantInfo?.businessProductType === "INSURANCE");
  }, [tenantInfo?.businessProductType]);

  useEffect(() => {
    if (documentBusinessID?.length > 0) {
      documentBusinessID.map((ids) => {
        API_SERVICE.getDocDetails(ids)
          .then(({ data }) => {
            setDocumentDetails((prev) => [...prev, data?.payload]);
          })
          .catch((e: any) => {
            API_SERVICE.handleErrors(e);
          });
      });
    }
  }, [documentBusinessID, documentBusinessID.length]);

  const customStyles: any = {
    "--fileIconColor": appColors?.appPrimaryColor ?? "",
  };

  const downloadDocument = (doc: any) => {
    API_SERVICE.downloadDocument(doc.businessId, doc.businessDocumentId)
      .then(({ data }) => {
        let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
        saveByteArray(
          [bytesData],
          `${data.payload.fileName}.${data.payload.fileExtn}`
        );
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onDelete = (doc: any) => {
    API_SERVICE.deleteDocument(doc.businessId, doc.businessDocumentId)
      .then(({ data }) => {
        const docTmp = documentDetails?.filter(
          (docu: any) => docu.businessDocumentId != doc.businessDocumentId
        );
        setDocumentDetails(docTmp);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const renderBureauRequests = () => {
    return (
      <>
        <label style={{ marginBottom: "15px" }}>Bureau Requests Tracking</label>
        <div className="form-box">
          <Form.Item
            label="Current Month Limit"
            name="bureauCredits"
            className="field-bg"
            rules={[
              { required: true, message: "Bureau Score Limit" },
              {
                pattern: NUMBER_REGEX,
                message: "Please enter valid number",
              },
            ]}
          >
            <InputNumber
              className="custom-input-number"
              placeholder="Bureau Score Limit"
            />
          </Form.Item>
          <label style={{ marginBottom: "15px" }}>
            Bureau Requests Summary
          </label>

          <List
            className="field-bg"
            itemLayout="horizontal"
            dataSource={bureauRequestsSummary}
            renderItem={(item) => {
              return (
                <>
                  <List.Item>
                    <Text style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                      <Space>
                        {item.title}
                        {item.list}
                      </Space>
                    </Text>
                  </List.Item>
                </>
              );
            }}
          />
        </div>
      </>
    );
  };

  let tmpUploadedFiles = [] as any;
  const renderUploadDocument = () => {
    return (
      <>
        <label style={{ marginBottom: "15px" }}>Upload Documents</label>
        <AppUpload
          acceptType={["image", "pdf"]}
          callBack={(response) => {
            tmpUploadedFiles = [...(allFileList ?? []), ...tmpUploadedFiles, response?.data];
            setAllFileList(tmpUploadedFiles);
          }}
          setFileSize={()=>{}}
          uploadedFiles={allFileList}
          setUploadedFile={setAllFileList}
          setIsSelectedLenderDoc={false}
          showUploadList
          multiple
        />
        <Row>
          <label style={{ marginBottom: "15px" }}>Uploaded Document</label>
          <div className="doc-list">
            <ul>
              {documentDetails?.map((doc: any, i: number) => (
                <li key={i}>
                  <div className="doc-details">
                    <FileBorder style={customStyles} />
                    <span>
                      {doc?.fileName}.{doc?.fileExtn}
                      <br />
                      <span className="type">{doc.documentType}</span>
                    </span>
                  </div>
                  <div className="doc-action">
                    <Space>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure?"
                        onConfirm={() => onDelete(doc)}
                        okText="Yes"
                        cancelText="No"
                        className="remove"
                        overlayClassName="deletePopup"
                        okButtonProps={{
                          style: {
                            backgroundColor:
                              appColors?.appPrimaryColor ?? "#273896",
                            border: "none",
                          },
                        }}
                        cancelButtonProps={{
                          style: {
                            borderColor:
                              appColors?.appSecondaryColor ?? "#C71C8E",
                            color: appColors?.appSecondaryColor ?? "#C71C8E",
                          },
                        }}
                      >
                        <span className="remove">
                          <MdDelete />
                        </span>
                      </Popconfirm>
                      <span
                        onClick={() => downloadDocument(doc)}
                        className="remove"
                      >
                        <DownloadBlack />
                      </span>
                    </Space>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Row>
      </>
    );
  };

  return (
    <div className="content-box">
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        name="leadForm"
        onFinish={onFinishForm}
        style={{ position: "relative" }}
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            <label style={{ marginBottom: "15px" }}>User Details</label>
            <div className="form-box">
              <Form.Item
                label="Full Name"
                name="name"
                className="field-bg"
                rules={[{ required: true, message: "Please enter Full Name." }]}
              >
                <Input className="custom-input" placeholder="Please enter Full Name" />
              </Form.Item>

              <Form.Item
                label="Mobile Number"
                name="phoneNumber"
                className="field-bg"
                rules={[
                  { required: true, message: "Please enter Mobile Number." },
                  {
                    pattern: PHONE_NUMBER_REGEX,
                    message: "Please enter valid Mobile Number",
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  placeholder="Please enter Mobile Number"
                />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                className="field-bg"
                rules={[{ required: true, message: "Please enter Email." }]}
              >
                <Input className="custom-input" placeholder="Please enter Email" />
              </Form.Item>

              <Form.Item
                label="Date of Birth(YYYY-MM-DD)"
                name="dob"
                className="field-bg"
                rules={[
                  { required: true, message: "Please select Date of Birth" },
                ]}
              >
                <DatePicker
                  format={dateFormat}
                  // format="YYYY-MM-DD"
                  className="custom-input"
                  placeholder="Please select Date of Birth"
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item
                label="PAN"
                name="pan"
                className="field-bg"
                rules={[
                  { required: true, message: "Please enter PAN Number" },
                  {
                    pattern: PAN_REGAX,
                    message: "Please enter valid PAN Number",
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  // onChange={debounce(checkPanDetails, 1000)}
                  placeholder="Please enter PAN Number"
                />
              </Form.Item>
              {!isAadharChanged && (
                <Form.Item
                  label="Aadhar"
                  name="aadhar"
                  className="field-bg"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Aadhar Number",
                    },
                    {
                      pattern: isAadharChanged
                        ? AADHAR_CARD_NUMBER_REGEX
                        : AADHAR_CARD_NUMBER_REGEX_HIDE,
                      message: "Please enter valid Aadhar Number",
                    },
                  ]}
                >
                  <Input
                    maxLength={14}
                    minLength={14}
                    className="custom-input"
                    placeholder="Please enter Aadhar Number"
                    onChange={() => setIsAadharChanged(true)}
                  />
                </Form.Item>
              )}
              {isAadharChanged && (
                <Form.Item
                  label="Aadhar"
                  name="aadharChange"
                  className="field-bg"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Aadhar Number",
                    },
                    {
                      pattern: AADHAR_CARD_NUMBER_REGEX,
                      message: "Please enter valid Aadhar Number",
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      borderRadius: "11px",
                      padding: "4px",
                      border: "solid 1px #f1f1f1",
                    }}
                    formatter={(value) =>
                      `${value}`.replace(/\d{4}(?=.)/g, "$& ")
                    }
                    maxLength={14}
                    minLength={14}
                    className="custom-input"
                    placeholder="Please enter Aadhar Number"
                    // onChange={() => setIsAadharChanged(true)}
                  />
                </Form.Item>
              )}
              <Form.Item
                label="Select Gender"
                name="gender"
                rules={[{ required: true, message: "Please select gender." }]}
              >
                <Radio.Group
                  className="custom-radio"
                  style={btnRadioStyles}
                  name="gender"
                >
                  <Space direction="horizontal">
                    {genders &&
                      genders.map((gender: any, i: number) => (
                        <Radio.Button key={i} value={gender.key}>
                          {gender.value}
                        </Radio.Button>
                      ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            <label style={{ marginBottom: "15px" }}>Select Roles</label>
            <div className="form-box">
              <Form.Item
                label="Department"
                name="department"
                className="field-bg"
                rules={[
                  {
                    required: true,
                    message: "Please select Department",
                  },
                ]}
              >
                <Select placeholder="Please select Department" className="custom-select">
                  {department &&
                    department.map((province) => (
                      <Select.Option key={province} value={province}>
                        {province}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Location"
                name="branch"
                className="field-bg"
                rules={[
                  { required: true, message: "Please select Location" },
                ]}
              >
                <Select placeholder="Please select Location" className="custom-select">
                  {location &&
                    location.map((loan: any, i: number) => (
                      <Select.Option key={i} value={loan.officeName}>
                        {loan.officeName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Line Manager"
                name="lineManager"
                className="field-bg"
                rules={isPrimaryUser ? [] :[
                  { required: true, message: "Please select Line Manager" },
                ]}
              >
                <AutoComplete
                  className="custom-auto-compleat selectIcon"
                  onSearch={debounce(getManager, 1000)}
                  placeholder="Please select Line Manager"
                  onSelect={(item, opt) => {
                    console.log("opt", opt);
                    setLineManager(opt?.key);
                  }}
                  disabled={isPrimaryUser} 
                >
                  {lineManagers?.map((loan: any) => (
                    <Select.Option key={loan.userId} value={loan.fullName}>
                      {loan.fullName}
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
              <Form.Item
                label="Designation"
                name="designation"
                className="field-bg"
                rules={[
                  {
                    required: true,
                    message: "Please select Designation",
                  },
                ]}
              >
                <Select placeholder="Please select Designation" className="custom-select">
                  {designation &&
                    designation.map((loan: any, i: number) => (
                      <Select.Option key={i} value={loan.designation}>
                        {loan.designation}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <div style={{ display: "flex" }}>
                <Form.Item name="isTelecaller" style={{ marginTop: "27px" }}>
                  <Checkbox
                    name="isTelecaller"
                    checked={isTelecaller}
                    onChange={onTelecaller}
                    className="dynamic-pagination-checkbox"
                    style={customStylesCheckbox}
                  >
                    {" "}
                    Telecaller &nbsp;&nbsp;
                  </Checkbox>
                </Form.Item>
                {isTelecaller ? (
                  <Form.Item
                    name="telecallerId"
                    rules={[
                      {
                        required: isTelecaller,
                        message: "Please enter Telecaller Id",
                      },
                    ]}
                    style={{ marginTop: "23px", width: "67%" }}
                  >
                    <InputNumber
                      className="custom-input"
                      placeholder="Please enter Telecaller Id"
                      style={{ padding: "4px" }}
                    />
                  </Form.Item>
                ) : null}
              </div>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8}>
            {isDocumentUpload ? renderUploadDocument() : renderBureauRequests()}
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col offset={21}>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                disabled={isDisabledContinue}
                className="dynamic-btn-primary"
                style={btnPrimaryStyles}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {isDisabledContinue && <AppLoading />}
      </Form>
    </div>
  );
};

export default EditEmployee;
