import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Modal, Row, Space, Tabs, notification } from "antd";
import Decisioning from "./Decisioning";
import API_SERVICE from "shared/services/api-service";
import {ReactComponent as ArrowLeft} from "../../../../assets/images/arrow_left.svg";
import ProductSteps from "./ProductSteps";
import ProductFooter from "./ProductFooter";
import Document from "./Document/Document";
import ListLeadDetails from "./LenderDisbursed/ListLeadDetails";
import LenderDecision from "./LenderDecision/LenderDecision";
import { RootState } from "shared/redux/store";
import GetBureauNew from "./VAS/GetBureauNew";
import { useSelector, useDispatch } from "react-redux";
import { setLenderRequiredField } from "shared/redux/common-reducer";
import LoginTabs from "./Login/LoginTabs";
import LenderPrivacyPolicy from "./LenderPrivacyPolicy/LenderPrivacyPolicy";

interface ILeadDetailNewTabs {
  setIsLoading: any;
  isLoading: any;
  leadData: any;
  selectedLenderList: any;
  lenderDisbursedHandler: any;
  getRedirectURLLink: any;
  getDownloadLetterForICICI: any;
  loadingUpdate: any;
  getCashETriggerSMSLink: any;
  updateCase: any;
  getJSONSchema: any;
  callbackReqDoc: any;
  callbackAdditionalField: any;
  setShowRequirementDocs: any;
  setSelectedLander: any;
  setAdditionalFields: any;
  setCurrLenderId: any;
  setCurrLeadId: any;
  setShowAdditionalFields: any;
  genrateBureauScore: any;
  setShowApplicantInfo: any;
  isApplicantModal: any;
  setIsApplicantModal: any;
  lenderDecisionHandler: any;
  isShowDocument: any;
  setIsShowDocument: any;
  getLeadById: any;
  setIsLoginSubmit: any;
  isLoginSubmit: any;
  setLeadViewType?: any;
  getLenderAdditonalField: any;
  lenderNextHandler: any;
  loading: any;
  setCallbackReqDoc: any;
  setIsDecisioningTabAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  isDecisioningTabAvailable: boolean;
  getTaskData?:any
  setGetTaskData?:any
  productWorkflowConfig?:any
  setProductWorkflowConfig?:any
}

const LeadDetailNewTabs: React.FC<ILeadDetailNewTabs> = (
  props: ILeadDetailNewTabs
) => {
  const {
    setIsLoading,
    isLoading,
    leadData,
    selectedLenderList,
    lenderDisbursedHandler,
    getRedirectURLLink,
    getDownloadLetterForICICI,
    loadingUpdate,
    getCashETriggerSMSLink,
    updateCase,
    getJSONSchema,
    callbackReqDoc,
    callbackAdditionalField,
    setShowRequirementDocs,
    setSelectedLander,
    setAdditionalFields,
    setCurrLenderId,
    setCurrLeadId,
    setShowAdditionalFields,
    genrateBureauScore,
    setShowApplicantInfo,
    isApplicantModal,
    setIsApplicantModal,
    lenderDecisionHandler,
    isShowDocument,
    setIsShowDocument,
    getLeadById,
    setIsLoginSubmit,
    isLoginSubmit,
    setLeadViewType,
    getLenderAdditonalField,
    lenderNextHandler,
    loading,
    setCallbackReqDoc,
    setIsDecisioningTabAvailable,
    isDecisioningTabAvailable,
    getTaskData,
    setGetTaskData,
    productWorkflowConfig,
    setProductWorkflowConfig
  } = props;
  const [currentStage, setCurrentStage] = useState("");
  const [currentSteps, setCurrentSteps] = useState("");
  const [isProductFooter, setIsProductFooter] = useState(false);
  const [disposition, setDisposition] = useState([] as any);
  const [dispositionList, setDispositionList] = useState([] as any);
  const [selectedStageDetail, setSelectedStageDetail] = useState({} as any);
  const [showVasContinue, setShowVasContinue] = useState(false);
  const [vasData, setVasData] = useState([] as any);
  const [showDecisioning, setShowDecisioning] = useState(false);
  const [isShowDisbursed, setIsShowDisbursed] = useState(false);
  const [isCurrentDocument, setIsCurrentDocument] = useState(false);
  const [isStepReadOnly, setIsStepReadOnly] = useState(false);
  const [isListDecisioning, setIsListDecisioning] = useState(false);
  const [isLoginListDecisioning, setIsLoginListDecisioning] = useState(false);
  const [isGetBureauStatus, setIsGetBureauStatus] = useState(false);
  const [showConflict, setShowConflict] = useState(false);
  const [conflictMessage, setConflictMessage] = useState("");
  const [selectedJourneyId, setSelectedJourneyId] = useState([] as any);
  const [descisioningDetail, setListDescisioningDetail] = useState([] as any);
  const [selectedLenderExecutionDataList, setSelectedLenderExecutionDataList] = useState([] as any);
  const [decisioningnTabkey, setDecisioningTabkey] =
  useState(leadData?.journeyConfigResponseList?.at(0)?.journeyId);
  let selectedStageTmp: any = selectedStageDetail;
  let leadFields: any = useSelector((state: RootState) => state.common);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  let mandatoryFields = leadFields?.lenderRequiredFields
    .map((item) => item)
    .join(",");
  const dispatch = useDispatch();

  useEffect(() => {
    fetchProductWorkflowById(leadData?.masterProductId);
    }, []);

  useEffect(() => {
    if (
      isLoginSubmit &&
      getTaskData?.productFormMetadata?.stageKey == "loginStage"
    ) {
      submitTask({ taskId: getTaskData?.taskId });
    }
    setIsLoginSubmit(false);
  }, [isLoginSubmit]);

  const fetchProductWorkflowById = (id: string) => {
    setIsLoading(true);
    API_SERVICE.fetchProductWorkflowById(id)
      .then(({ data }) => {
        setProductWorkflowConfig(data?.payload?.productWorkflowConfig ?? {});
        setDisposition(data?.payload?.dispositions ?? []);
        const deciStage = data?.payload?.productWorkflowConfig?.stages?.find(
          (stage: any) => stage?.key == "decisioningStage"
        );
        setIsDecisioningTabAvailable((deciStage?.runType?.isEnabled ?? "FALSE") != "FALSE")
        getNextTask(
          {
            processInstanceId: leadData?.processInstanceId,
            clientType: "WEB",
          },
          data?.payload?.productWorkflowConfig,
          data?.payload?.dispositions,
          false
        );
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
        setIsLoading(false);
      });
  };

  const getNextTask = (
    payload: any,
    productWorkflow: any = productWorkflowConfig,
    dispositionData: any = disposition,
    isCallGetLeadDetails: boolean = true,
  ) => {
    setIsLoading(true);
    API_SERVICE.productGetNextTask(payload)
      .then(({ data }) => {
        setShowConflict(false);
        if(isCallGetLeadDetails) {
          getLeadById();
        }
        if (data?.payload?.productFormMetadata?.stageKey != "prospectStage") {
          dispatch(setLenderRequiredField([]));
        }
        if (isShowDocument) {
          setCurrentStage("documentStage");
        } else {
          setShowVasContinue(
            data?.payload?.taskDefinitionKey == "vas_user_task"
          );
          stageOnChange(
            data?.payload?.productFormMetadata?.stageKey,
            productWorkflow,
            dispositionData,
            data?.payload ?? {}
          );
          setCurrentStage(data?.payload?.productFormMetadata?.stageKey);
          stepOnChange(
            data?.payload?.productFormMetadata?.stepKey,
            data?.payload ?? {}
          );
        }
        setCurrentSteps(data?.payload?.productFormMetadata?.stepKey);
        setGetTaskData(data?.payload ?? {});
        setIsCurrentDocument(
          data?.payload?.productFormMetadata?.stageKey == "documentStage"
        );
        // setIsFinBox(
        //   data?.payload?.productFormMetadata?.actionId ==
        //     "BANK_STATEMENT_ANALYSIS"
        // );
        setShowDecisioning(
          data?.payload?.taskDefinitionKey == "decisioning_output_modal"
        );
        setIsShowDisbursed(false);
      })
      .catch((e: any) => {
        if (e?.response?.status == 409) {
          setShowConflict(true);
          setConflictMessage(e?.response?.data?.payload?.errorMessage);
        } else {
          notification.error({ message: API_SERVICE?.handleErrors(e) });
          setShowConflict(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitTask = (payload: any) => {
    setIsLoading(true);
    API_SERVICE.productSubmitTask(payload)
      .then(() => {
        setShowDecisioning(false);
        setSelectedJourneyId([]);
        getNextTask({
          processInstanceId: leadData?.processInstanceId,
          clientType: "WEB",
        });
      })
      .catch((e: any) => {
        if (e?.response?.status == 409) {
          setShowConflict(true);
          setConflictMessage(e?.response?.data?.payload?.errorMessage);
        } else {
          notification.error({ message: API_SERVICE?.handleErrors(e) });
          setShowConflict(false);
        }
        setIsLoading(false);
      });
  };

  const stageOnChange = (
    key: any = currentStage,
    data: any,
    dispositionData: any = disposition,
    tmpData: any = getTaskData
  ) => {
    setIsProductFooter(false);
    selectedStageTmp = data?.stages?.find((item: any) => {
      return item?.key == key;
    });
    const tmpVasData = selectedStageTmp?.vas?.filter(record => record.isActive)
    if ((selectedStageTmp?.vas?.length ?? 0) > 0) {
      setVasData(tmpVasData ?? []);
      setShowVasContinue(
        key == tmpData?.productFormMetadata?.stageKey &&
        tmpData?.taskDefinitionKey == "vas_user_task"
      );
      setIsGetBureauStatus(true);
    } else {
      setVasData([]);
    }
    if ((selectedStageTmp?.steps?.length ?? 0) > 0) {
      stepOnChange(currentSteps, tmpData);
    }
    setSelectedStageDetail(selectedStageTmp);
    setProductWorkflowConfig(data);
    setCurrentStage(key);
    setIsListDecisioning(key == "decisioningStage");
    setIsLoginListDecisioning(key == "loginStage");
    if ((tmpData?.productFormMetadata?.stepKey?.length ?? 0) > 0) {
      setCurrentSteps(tmpData?.productFormMetadata?.stepKey);
    } else {
      if ((selectedStageTmp?.steps?.length ?? 0) > 0) {
        for (let index = 0; index < selectedStageTmp?.steps?.length; index++) {
          const stepkey = selectedStageTmp?.steps[index];
          if ((!stepkey?.journeyBuilderConfiguration?.disableConfiguration) && (stepkey?.runType?.isEnabled != "FALSE")) {
            setCurrentSteps(stepkey?.key);
            setIsStepReadOnly(
              stepkey?.key != tmpData?.productFormMetadata?.stepKey
            );
            break;
          }
        }
      }
    }
    if (
      selectedStageTmp?.explicitTriggerMode == "TAB_TRIGGERED" &&
      key !== tmpData?.productFormMetadata?.stageKey
    ) {
      getNextTask({
        processInstanceId: leadData?.processInstanceId,
        gotoStep: key,
      });
    }
    const displayDispositionList = dispositionData?.find((item: any) => {
      return item?.stageKey == key;
    });
    setDispositionList(displayDispositionList?.disposition1And2 ?? []);
    setIsShowDocument(false);
  };

  const stepOnChange = (key: any, tmpData: any = getTaskData) => {
    setIsProductFooter(false);
    setCurrentSteps(key);
    setIsStepReadOnly(
      key !== tmpData?.productFormMetadata?.stepKey ||
      tmpData?.taskDefinitionKey == "vas_user_task"
    );
    setShowVasContinue(
      key == tmpData?.productFormMetadata?.stepKey &&
      tmpData?.taskDefinitionKey == "vas_user_task"
    );
    const selectedStepTmp = selectedStageTmp?.steps?.find((item: any) => {
      return item?.key == key;
    });
    const tmpVasData = selectedStepTmp?.vas?.filter(record => record.isActive)
    if ((selectedStageTmp?.steps?.length ?? 0) > 0) {
      setVasData(tmpVasData ?? []);
      setIsGetBureauStatus(true);
    }
    if (
      selectedStepTmp?.explicitTriggerMode == "TAB_TRIGGERED" &&
      key !== tmpData?.productFormMetadata?.stepKey
    ) {
      getNextTask({
        processInstanceId: leadData?.processInstanceId,
        gotoStep: key,
      });
    }
    setIsShowDocument(false);
  };

  const tabStyle: any = {'--borderColor': appColors?.appPrimaryColor ?? ""};
  
  const renderSteps = (item: any) => {
    return (
      <>
        <Tabs
          onChange={(key) => stepOnChange(key)}
          activeKey={currentSteps}
          className="lender-sub-tabs"
          style={tabStyle}
        >
          {item?.steps
            ?.filter((item: any) => item?.runType?.isEnabled != "FALSE")
            ?.map((step: any) => {
              return (
                <Tabs.TabPane
                  key={step?.key}
                  tab={step?.name}
                  //disabled
                  style={{ paddingInline: "10px" }}
                >
                  <ProductSteps
                    getTaskData={getTaskData}
                    submitTask={submitTask}
                    leadData={leadData}
                    getJSONSchema={getJSONSchema}
                    formJsonSchema={JSON.stringify(step?.form?.definition)}
                    isStepReadOnly={isStepReadOnly}
                    currentSteps={currentSteps}
                    getNextTask={getNextTask}
                    setGetTaskData={setGetTaskData}
                    isDecisioningTabAvailable={isDecisioningTabAvailable}
                  />
                </Tabs.TabPane>
              );
            })}
        </Tabs>
      </>
    );
  };

  const renderDecisioningLoop = () => {
    const onSubmitLoop = () => {
      const payload = {
        formData: JSON?.stringify({ selectedJourneys: [...selectedJourneyId], ...JSON?.parse(getTaskData?.formData) }),
        taskId: getTaskData?.taskId,
      }
      submitTask(payload);
    }
    return (
      <Modal
        width={300}
        title="Select Decisioning Loop"
        visible={getTaskData?.taskDefinitionKey == "select_decisioning_journey"}
        closable={false}
        maskClosable={false}
        footer={false}
        centered
      >
        <Form>
          {leadData?.journeyConfigResponseList?.map((item: any, i: number) => {
            return (
              <Form.Item name={`check${i}`}>
                <Checkbox checked={selectedJourneyId?.some(
                  (id: any) => id == item?.journeyId
                )}
                  onChange={((e) => {
                    let tmp = [...selectedJourneyId];
                    if (
                      selectedJourneyId?.includes(item?.journeyId
                      )
                    ) {
                      tmp = selectedJourneyId?.filter(
                        (id: any) => id != item?.journeyId
                      );
                    } else {
                      tmp.push(item?.journeyId);
                    }
                    setSelectedJourneyId(tmp);
                  })}>{item?.journeyName}</Checkbox>
              </Form.Item>
            )
          })}
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" style={{ width: "100%" }} disabled={selectedJourneyId?.length == 0}
              onClick={onSubmitLoop}
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const renderDecisioningOutput = () => (
    <Modal
      title="Decisioning"
      width={700}
      visible={showDecisioning}
      okText="OK"
      closable={false}
      maskClosable={false}
      footer={false}
    >
      <div className="">
        <ListLeadDetails
          leadData={leadData}
          showDecisioning={showDecisioning}
          setLeadViewType={setLeadViewType}
          hideCancel={true}
        />
      </div>
      <div className="" style={{ display: "flex", justifyContent: "right" }}>
        <Button
          style={{ width: "80px", marginTop: "15px" }}
          size="large"
          type="primary"
          onClick={() => submitTask({ taskId: getTaskData?.taskId })}
        >
          OK
        </Button>
      </div>
    </Modal>
  );

  const renderConfictMessage = () => (
    <Modal
      centered
      width={400}
      // onCancel={() => setIsFilterVisible(false)}
      closable={false}
      footer={null}
      visible={showConflict}
    >
      <div style={{ padding: "15px", textAlign: "center" }}>
        <p style={{ fontSize: "18px" }}>{conflictMessage}</p>
        <Button
          type="primary"
          onClick={() =>
            getNextTask({
              processInstanceId: leadData?.processInstanceId,
              clientType: "WEB",
            })
          }
          style={{
            width: "120px",
          }}
        >
          OK
        </Button>
      </div>
    </Modal>
  );

  return (
    <>
      <div style={{ display: "flex" }}>
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            {mandatoryFields != "" ? (
              <>
                <div className="empty-lead-field-div">
                  <span>Below fields are mandatory to go forward</span>
                  <p>{mandatoryFields?.replaceAll(",", ", ")}</p>
                </div>
              </>
            ) : (
              ""
            )}
          </Col>
          <Col span={24}>
            <Tabs
              className="lender-main-tabs"
              style={{"--bgColor": appColors?.appPrimaryColor ?? ""} as any}
              onChange={(key) =>
                stageOnChange(key, productWorkflowConfig, disposition)
              }
              tabBarExtraContent={
                currentStage == "lenderDecisionStage" && isShowDisbursed ? (
                  <Button
                    onClick={() => setIsShowDisbursed(false)}
                    className="back-btn"
                    style={{'--btnColor': appColors?.appPrimaryColor ?? ""} as any}
                    icon={<ArrowLeft width={20} />}
                  >
                    Back
                  </Button>
              
                ) : (
                  ""
                )
              }
              activeKey={isShowDocument ? "documentStage" : currentStage}
              //style={{ color: "black" }}
            >
              {productWorkflowConfig?.stages
                ?.filter((item: any) => item?.runType?.isEnabled != "FALSE")
                ?.map((tab: any) => {
                  return (
                    <Tabs.TabPane key={tab?.key} tab={tab?.name}>
                      {(tab?.steps?.length ?? 0) > 0 ? (
                        renderSteps(tab)
                      ) : tab?.journeyBuilderConfiguration?.showDocumentList ? (
                        <div style={{ paddingInline: "15px" }}>
                          <Document
                            setIsLoading={setIsLoading}
                            leadData={leadData}
                            getTaskData={getTaskData}
                            isCurrentDocument={isCurrentDocument}
                            submitTask={submitTask}
                            setCurrentStage={setCurrentStage}
                          />
                        </div>
                      ) : tab?.key == "decisioningStage" ? (
                        <Decisioning
                          setIsLoading={setIsLoading}
                          leadData={leadData}
                          getTaskData={getTaskData}
                          submitTask={submitTask}
                          setIsListDecisioning={setIsListDecisioning}
                          isListDecisioning={isListDecisioning}
                          descisioningDetail={descisioningDetail}
                          setListDescisioningDetail={setListDescisioningDetail}
                          selectedLenderExecutionDataList={selectedLenderExecutionDataList}
                          setSelectedLenderExecutionDataList={setSelectedLenderExecutionDataList}       
                          decisioningnTabkey = {decisioningnTabkey}
                          setDecisioningTabkey = {setDecisioningTabkey}
                          getNextTask={getNextTask}
                        />
                      ) :
                        tab?.key == "loginStage" ? (
                          <LoginTabs
                            setIsLoading={setIsLoading}
                            leadData={leadData}
                            updateCase={updateCase}
                            getRedirectURLLink={getRedirectURLLink}
                            getCashETriggerSMSLink={getCashETriggerSMSLink}
                            callbackReqDoc={callbackReqDoc}
                            callbackAdditionalField={callbackAdditionalField}
                            setShowRequirementDocs={setShowRequirementDocs}
                            setSelectedLander={setSelectedLander}
                            setAdditionalFields={setAdditionalFields}
                            setCurrLenderId={setCurrLenderId}
                            setCurrLeadId={setCurrLeadId}
                            setShowAdditionalFields={setShowAdditionalFields}
                            getTaskData={getTaskData}
                            submitTask={submitTask}
                            setIsLoginListDecisioning={setIsLoginListDecisioning}
                            isLoginListDecisioning={isLoginListDecisioning}
                            getLenderAdditonalField={getLenderAdditonalField}
                            lenderNextHandler={lenderNextHandler}
                            loading={loading}
                            getNextTask={getNextTask}
                            setCallbackReqDoc={setCallbackReqDoc}
                          />
                           
                        ) : tab?.key == "lenderDecisionStage" ?                        
                        tenantInfo?.businessProductType === "INSURANCE" 
                        ? <LenderPrivacyPolicy  
                            leadData={leadData}
                            setIsLoading={setIsLoading}
                            getLeadById={getLeadById}
                          />
                        : (
                          <LenderDecision
                            setIsLoading={setIsLoading}
                            leadData={leadData}
                            selectedLenderList={selectedLenderList}
                            lenderDisbursedHandler={lenderDisbursedHandler}
                            lenderDecisionHandler={lenderDecisionHandler}
                            getRedirectURLLink={getRedirectURLLink}
                            getDownloadLetterForICICI={getDownloadLetterForICICI}
                            loadingUpdate={loadingUpdate}
                            getCashETriggerSMSLink={getCashETriggerSMSLink}
                            isShowDisbursed={isShowDisbursed}
                            setIsShowDisbursed={setIsShowDisbursed}
                          />
                        ) : (
                          ""
                        )}
                      {(vasData?.length ?? 0) > 0 && (
                        <GetBureauNew
                          setIsLoading={setIsLoading}
                          leadData={leadData}
                          vasData={vasData}
                          setShowApplicantInfo={setShowApplicantInfo}
                          setIsGetBureauStatus={setIsGetBureauStatus}
                          isGetBureauStatus={isGetBureauStatus}
                          showVasContinue={showVasContinue}
                          setShowVasContinue={setShowVasContinue}
                          currentStage={currentStage}
                          currentSteps={currentSteps}
                          getTaskData={getTaskData}
                          submitTask={submitTask}
                          descisioningDetail={descisioningDetail}
                          setListDescisioningDetail={setListDescisioningDetail}
                          selectedLenderExecutionDataList={selectedLenderExecutionDataList}
                          decisioningnTabkey = {decisioningnTabkey}
                          setDecisioningTabkey = {setDecisioningTabkey}
                          productWorkflowConfig={productWorkflowConfig}
                          />
                       )}
                    </Tabs.TabPane>
                  );
                })}
            </Tabs>
          </Col>
          <Col span={24} className="border-top-3">
            {isProductFooter ? (
              <div style={{ display: "flex", padding: "10px" }}>
                <ProductFooter
                  dispositionList={dispositionList}
                  setIsProductFooter={setIsProductFooter}
                  caseId={leadData?.caseDetails?.caseId}
                  selectedStageDetail={selectedStageDetail}
                  caseStatus={leadData?.caseDetails?.caseStatus}
                  getLeadById={getLeadById}
                />
              </div>
            ) : (
              <div
                className="add-comment"
                onClick={() => setIsProductFooter(true)}
              >
                <p style={{ padding: "10px" }}>Add Comment here...</p>
              </div>
            )}
          </Col>
        </Row>
      </div>
      {showDecisioning && renderDecisioningOutput()}
      {getTaskData?.taskDefinitionKey == "select_decisioning_journey" && !isLoading ? renderDecisioningLoop() : ""}
      {showConflict && renderConfictMessage()}
    </>
  );
};
export default LeadDetailNewTabs;
