import React, { useEffect, useState } from "react";
import { Typography, DatePicker, Button, Space, notification, Select, Form, Card, Row, Divider, Col } from "antd";
import API_SERVICE from "shared/services/api-service";
import type { Moment } from 'moment';
import { getUser, saveByteArray } from "shared/services/Utility";
import { setScreenHeader } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import moment from "moment";
import { RangePickerProps } from "antd/es/date-picker";
import "../../index.css"

const { RangePicker } = DatePicker;

type Props = {};
type RangeValue = [Moment | null, Moment | null] | null;

const Reports: React.FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);
  const [selectedReportType, setSelectedReportType] = useState(null as any);
  const [reportTypes, setReportTypes] = useState([] as any);
  const [reportForm] = Form.useForm() as any;
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  const isBroker = localStorage.getItem("USER_TYPE") == "BROKER";
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({ screenTitle: "Reports" }));
    
    //getAllTypes();
  }, []);
  useEffect(() => {
     
    getAllTypes();
  }, [tenantInfo]);

  const disabledDate: RangePickerProps["disabledDate"] = (current: Moment) => {
    if (!dates) {
      return current && current > moment().endOf('day');
    }
    if (selectedReportType == "USER_CONNECTOR_ACTIVITY" || selectedReportType == "USER_CONNECTOR_CASE_ACTIVITY") {
      const tooLate = dates[0] && current.diff(dates[0], 'days') > 90;
      const tooEarly = dates[1] && dates[1].diff(current, 'days') > 90;
      if (dates[0] && moment().diff(dates[0], 'days') < 90){
        return current && current > moment().endOf('day');
      }
        return !!tooEarly || !!tooLate;
    }
    return current && current > moment().endOf('day');
  };

  const getAllTypes = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {

        let filteredReportTypes = data.find(
          (item: any) => item.propertyName == "REPORT_TYPE"
        );
        console.log("HI",tenantInfo)

        if(tenantInfo?.businessProductType === "INSURANCE") {
          const reports = filteredReportTypes.keyAndDisplayValueList?.filter(
            (item: any) => {
              return !(
                item?.key == "BUREAU_AUDIT" ||
                item?.key == "BUREAU_CREDITS_AUDIT" ||
                item?.key == "DISBURSEMENT" ||
                item?.key == "PAYOUT"
              );
            }
          );  
          setReportTypes(reports);
        } else {
          const reports = filteredReportTypes.keyAndDisplayValueList?.filter(
            (item: any) => {
              return !["POLICIES", "BROKER_INSURANCE"].includes(item?.key);
            }
          );  
          setReportTypes(reports);
        }

      }).catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onFinish = (values: any) => {
    let startDate = "";
    let endDate = "";
    let reportType = isBroker ? "BROKER_INSURANCE"  : values.reportType
    if (values && values?.dateRange) {
      startDate = values.dateRange[0].format("YYYY-MM-DD");
      endDate = values.dateRange[1].format("YYYY-MM-DD");
    }
    setLoading(true);
    API_SERVICE.generateReportByReportType(startDate, endDate, reportType)
      .then(({ data }) => {
        saveByteArray([data], (reportType.toLowerCase() + "_report.xlsx"));
        setLoading(false);
      })
      .catch(async (e: any) => {
        const data = JSON.parse(await e.response.data.text());
        const message = data?.payload?.errorMessage ?? e;
        notification.error({ message: API_SERVICE.handleErrors(message) });
        setLoading(false);
      });
  }

  const btnPrimaryStyles: any = { '--btnColor': appColors?.appPrimaryColor ?? "", width: "150px" };

  return (
    <>
      <Card className="custom-app-card content-box">
        <Form layout="vertical" form={reportForm}
          initialValues={{}} onFinish={onFinish} autoComplete="off" name="reportForm">
          {/* <Form.Item label="Select Report Type" rules={[{ required: true, message: "Please select report type" }]}> */}
          {!isBroker &&
            <Row gutter={[24, 24]}>
            <Col>
              <Form.Item label="Select Report Type"
                rules={[{ required: true, message: "Please select report type" }]}
                name="reportType" style={{ marginTop: "10px", width: "100%" }}>
                <Select placeholder="Select Report Type" style={{ width: "250px" }} onChange={(e) => {
                  setSelectedReportType(e);
                  reportForm.setFieldsValue({
                    dateRange: null
                  })
                }}>
                  {/* <Select.Option value="">Select Report Type</Select.Option> */}
                  {reportTypes && reportTypes
                    .sort(function (a: any, b: any) {
                      if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
                      if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
                      return 0;
                    }).map((option: any, i: number) => {
                      return (
                        <Select.Option key={i} value={option.key}>{option.value}</Select.Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          }
         
          {("CONNECTOR" !== selectedReportType) ?
            <Row gutter={[24, 24]}>
              <Col>
                <Form.Item label="Select Date Range"
                  rules={[{ required: ("CONNECTOR" !== selectedReportType), message: "Please select date range" }]}
                  name="dateRange" style={{ marginTop: "10px", width: "100%" }}>
                  <RangePicker
                    value={dates || value}
                    disabledDate={disabledDate}
                    onCalendarChange={val => setDates(val)}
                    onChange={val => setValue(val)}
                    onOpenChange={(open: boolean) => {
                      if (open) {
                        reportForm.setFieldsValue({
                          dateRange: null
                        })
                      }
                    }}
                    style={{ width: "250px" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            :
            null
          }
          <Row gutter={[24, 24]} style={{ rowGap: "0px" }}>
            <Divider />
            <Col>
              <Form.Item>
                {/* <Button size="large" loading={loading} type="primary" onClick={actionDownload} style={{ width: "150px" }}>Download</Button> */}
                <Button size="large" loading={loading} type="primary" htmlType="submit" style={btnPrimaryStyles} className="dynamic-btn-primary">Download</Button>
              </Form.Item>
            </Col>
            {/* <Col >
              <Form.Item>
                <Button type="primary" htmlType="submit">Update</Button>
              </Form.Item>
            </Col> */}
          </Row>

        </Form>
      </Card>
      <div className="content-box">
        {/* <Text>Select Report Type</Text>
        <div>
          <Form.Item rules={[{ required: true, message: "Please select report type" }]}>
            <Select
              //defaultValue={reportTypes[0]?.key}
              placeholder="Select Report Type"
              style={{ width: 200, marginTop: "10px", marginBottom: "10px" }}
              onChange={(e) => setSelectReportValue(e)}
            >
              {reportTypes && reportTypes
                .sort(function (a: any, b: any) {
                  if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
                  if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
                  return 0;
                }).map((option, i) => {
                  return (
                    <Select.Option key={i} value={option.key}>{option.value}</Select.Option>
                  )
                })}
            </Select>
          </Form.Item>
        </div>
        <Text>Select Date Range</Text>
        <Space style={{ display: "grid" }}>
          <RangePicker
            style={{ width: "500px" }}
            onChange={onChange}
            disabledDate={disabledDate}
          />
          <Button
            size="large"
            loading={loading}
            type="primary"
            onClick={actionDownload}
            style={{ width: "150px" }}
          >
            Download
          </Button>
        </Space> */}

        {/* <div><br/>
        <iframe width="100%" height="540" src="https://analytics.zoho.in/open-view/218305000000061452"></iframe>
        </div> */}
      </div>
    </>
  );
};

export default Reports;