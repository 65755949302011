import { CheckOutlined, CloseOutlined, CloudDownloadOutlined, FileAddOutlined, FileOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, Input, message, Modal, notification, Radio, Row, Select, Space, Tooltip } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { FaPen } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import "./connectorKycStyle.scss"
import { base64ToArrayBuffer, saveByteArray } from "shared/services/Utility";
import editIcon from "../../../assets/images/editIcon.svg";
import {ReactComponent as EditIcon} from "../../../assets/images/editIcon.svg";
import cloudDown from "../../../assets/images/cloudDown.svg"
import fileUploadIcon from "../../../assets/images/fileUploadIcon.svg";
import {ReactComponent as FileBorderIcon} from "../../../assets/images/file-border.svg";
import {ReactComponent as AddFileGray} from "../../../assets/images/upload-file-gray.svg";
import "../index.scss";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";


const ConnectorKycDetails: React.FunctionComponent<{ connector: any, disableValue: any, setConnector: any }> = (props) => {

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [genders, setGenders] = useState([]);
    const [viewModel, setViewModel] = useState(false);
    const [addressProofData, setAddressProofData] = useState(false);
    const [uploadedDocumentName, setUploadedDocumentName] = useState(null as any);
    const [documentName, setDocumentName] = useState(uploadedDocumentName ? uploadedDocumentName : null as any);
    const [selectedFileList, setSelectedFileList] = useState(null as any);
    const [KycDetailsForm] = Form.useForm() as any;
    const [showAddressPdf, setShowAddressPdf] = useState(false);
    const [KycStatusForm] = Form.useForm() as any;
    const [disablefield, setDisableField] = useState(false);
    const [addressType, setAddressType] = useState([]);
    const [onBoardingStatus, setOnBoardingStatus] = useState([]);
    const [kycStatus, setKycStatus] = useState(null as any);
    const [rejectionReasons, setRejectionReasons] = useState([] as any);
    const [selectedRejectionReason, setSelectedRejectionReason] = useState(null as any);
    const [showRejectedReason, setShowRejectedReason] = useState(false);
    const [addressDocumentType, setAddressDocumentType] = useState("");
    const [documentData, setDocumentData] = useState(null as any);
    const [docType, setDocType] = useState(null as any);
    const [kycDetails, setKycDetails] = useState({} as any);
    const [uploadedFileName, setUploadedFileName] = useState(null as any);
    const [allowedAttechmentTypes] = useState(["application/pdf", "image/jpeg", "image/jpg", "image/png"]);
    const {appColors}: any = useSelector((state: RootState) => state.common);

    useEffect(() => {
        if (props?.disableValue === true) {
            setDisableField(true);
        }
        if (props?.connector?.kycDetails?.addressProofDoc === null || props?.connector?.kycDetails?.addressProofDoc === undefined || props?.connector?.kycDetails?.addressProofDoc?.documentName === null) {
            setAddressProofData(true);
        }
        else {
            setUploadedDocumentName(props?.connector?.kycDetails?.addressProofDoc?.documentName);
        }
        getAllTypes();
    }, [uploadedFileName]);


    useEffect(() => {
        setDocumentName(props?.connector?.kycDetails?.addressProofDoc?.documentName);
        if (props?.connector?.kycDetails) {
            setKycDetails(props?.connector?.kycDetails);
            KycDetailsForm.setFieldsValue({
                pan: props?.connector?.kycDetails?.pan,
                panName: props?.connector?.kycDetails?.panName,
                pinCode: props?.connector?.kycDetails?.currentAddress?.pinCode,
                address: props?.connector?.kycDetails?.currentAddress?.address,
                city: props?.connector?.kycDetails?.currentAddress?.city,
                state: props?.connector?.kycDetails?.currentAddress?.state,
                doc: props?.connector?.kycDetails?.addressProofDoc?.documentName,
                documentType: props?.connector?.kycDetails?.addressProofDoc?.documentType
            });

            if (props?.connector?.kycDetails?.status === "APPROVED") {
                setKycStatus("APPROVED");
                setSelectedRejectionReason(null);
                setShowRejectedReason(false);
            } else if (props?.connector?.kycDetails?.status === "REJECTED") {
                setKycStatus("REJECTED");
                setSelectedRejectionReason(props?.connector?.kycDetails?.rejectionReason);
                setShowRejectedReason(true);
            } else {
                setKycStatus("APPROVED");
                setSelectedRejectionReason(null);
                setShowRejectedReason(false);
            }
        }
    }, [props.connector]);

    const getAllTypes = () => {
        API_SERVICE.getTypes()
            .then(({ data }) => {

                let filteredRejectionReason = data.find(
                    (item: any) => item.propertyName == "REJECTION_REASON"
                );
                setRejectionReasons(filteredRejectionReason.keyAndDisplayValueList);

                let filteredGenderType = data.find(
                    (item: any) => item.propertyName == "GENDER"
                );
                setGenders(filteredGenderType.keyAndDisplayValueList);

                let filteredAddressType = data.find(
                    (item: any) => item.propertyName == "ADDRESS_TYPE"
                );
                setAddressType(filteredAddressType.keyAndDisplayValueList);

                let filteredOnBoardingStatus = data.find(
                    (item: any) => item.propertyName == "ONBOARDING_STATUS"
                );
                setOnBoardingStatus(filteredOnBoardingStatus.keyAndDisplayValueList);

            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    };

    const buildStatusPayload = (status, rejectedReason) => {

        const buildStatusPayload = {
            accounts: null,
            basicDetails: null,
            kycDetails: {
                rejectionReason: kycStatus === "REJECTED" ? selectedRejectionReason : null,
                status: kycStatus ? kycStatus : kycDetails.status,
            },
            otherDetails: null,
        };
        return buildStatusPayload;
    }

    const buildPayload = (formData: any, documenetLinkDetails: any) => {
        const payload = {
            accounts: null,
            // basicDetails: props?.connector?.basicDetails,
            kycDetails: {
                addressProofDoc: {
                    businessId: documenetLinkDetails.businessId ? documenetLinkDetails?.businessId : props?.connector?.kycDetails?.addressProofDoc?.businessId,
                    documentName: documenetLinkDetails.documentName ? documenetLinkDetails?.documentName : props?.connector?.kycDetails?.addressProofDoc?.documentName,
                    documentSetId: documenetLinkDetails.documentSetId ? documenetLinkDetails?.documentSetId : props?.connector?.kycDetails?.addressProofDoc?.documentSetId,
                    businessDocumentId: documenetLinkDetails.businessDocumentId ? documenetLinkDetails.businessDocumentId : props?.connector?.kycDetails?.addressProofDoc?.businessDocumentId,
                    documentType: addressDocumentType// ? addressDocumentType : props?.connector?.kycDetails?.addressProofDoc?.documentType,
                },
                /*createdBy: null,
                createdTime: null,*/
                currentAddress: {
                    address: formData?.address,
                    area: null,
                    city: formData?.city,
                    country: null,
                    geoCode: null,
                    pinCode: formData?.pinCode,
                    state: formData?.state,
                },
                /*lastUpdateBy: null,
                lastUpdatedTime: null,*/
                pan: formData?.pan,
                panName: formData?.panName,
            },
            otherDetails: null,
        };
        // payload.basicDetails.name = formData?.panName;
        // payload.basicDetails.gender = ("Male" === payload.basicDetails.gender ? "M" : ("Female" === payload.basicDetails.gender ? "F" : "O"))

        return payload;
    };

    const onSelectFile = (selectedFiles) => {
        setSelectedFileList(selectedFiles);
    };

    const onChange = (fileList) => {
        if (fileList.fileList?.length > 0) {
            setSelectedFileList(fileList)
        } else {
            onSelectFile(null);
        }
    };

    const onFinishForm = (values: any) => {
        if (kycDetails?.currentAddress?.address !== values?.address ||
            values?.addressDraggerrfile && values?.addressDraggerrfile?.file?.name != kycDetails?.addressProofDoc?.documentName ||
            kycDetails?.currentAddress?.city !== values?.city ||
            kycDetails?.addressProofDoc?.documentName !== values?.doc ||
            kycDetails?.addressProofDoc?.documentType !== values?.documentType ||
            kycDetails?.pan !== values?.pan ||
            kycDetails?.panName !== values?.panName ||
            kycDetails?.currentAddress?.pinCode !== values?.pinCode ||
            kycDetails?.currentAddress?.state !== values?.state) {

            if (selectedFileList) {
                let formData = new FormData();
                formData.append(selectedFileList.file.name, selectedFileList.file);

                if (allowedAttechmentTypes.indexOf(selectedFileList.file.type) === -1) {
                    notification.error({ message: "Please attach only image(jpg/jpeg/png) or pdf file." });
                    return;
                }
                API_SERVICE.fileUpload(formData).then(({ data }) => {
                    let documentURL = data;
                    const params = {
                        "docFinancialYear": 0,
                        "documentURL": documentURL,
                        "documentName": selectedFileList.file.name,
                        "documentType": selectedFileList.file.type,
                    };
                    API_SERVICE.addDocument(params, props?.connector?.basicDetails?.documentSetId).then(({ data }) => {
                        if (data) {
                            setAddressProofData(false);
                            let documenetLinkDetails = {
                                "businessId": data?.payload?.businessId,
                                "documentName": data?.payload?.documentName,
                                "documentSetId": data?.payload?.documentSetId,
                                "businessDocumentId": data?.payload?.businessDocumentId,
                                //"documentType": data?.payload?.documentType
                            };

                            const payload = buildPayload(values, documenetLinkDetails);

                            API_SERVICE.updateConnector(id, payload)
                                .then(({ data }) => {
                                    if (data) {
                                        props.setConnector(data?.payload);
                                        notification.success({ message: "Connector Updated Successfully \n You will need to verify/reverify the attached address document" });
                                        history.push(`/connectors/connector-edit/${id}`);
                                    }
                                })
                                .catch((e: any) => {
                                    notification.error({ message: API_SERVICE.handleErrors(e) });
                                });

                        }
                    }).catch((e: any) => {
                        notification.error({ message: API_SERVICE.handleErrors(e) });
                    });


                }).catch((e: any) => {
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                });
            } else {
                let documenetLinkDetails = {
                    "businessId": null,
                    "documentName": null,
                    "documentSetId": null,
                    "businessDocumentId": null,
                    "documentType": null,
                };
                const payload = buildPayload(values, documenetLinkDetails);
                API_SERVICE.updateConnector(id, payload)
                    .then(({ data }) => {
                        if (data) {
                            props.setConnector(data?.payload);
                            notification.success({ message: "Connector Updated Successfully \n You will need to verify/reverify the attached address document" });
                            // history.push("/connectors");
                        }
                    })
                    .catch((e: any) => {
                        notification.error({ message: API_SERVICE.handleErrors(e) });
                    });
            }

        }
        else {
            notification.error({ message: " No Changes Found" })
        }
    };

    const checkPanDetails = (e: any) => {
        e.persist();
        if (e.target.value) {
            const { value: nextValue } = e.target;
            const params = {
                panNumber: nextValue,
            };
            API_SERVICE.checkPanDetails(params).then(({ data }) => {
                KycDetailsForm.setFieldsValue({
                    panName: data?.payload?.fullName?.trim(),
                });
            });
        }
    };

    const checkPincode = (e: any) => {
        const { value: nextValue } = e.target;
        const params = {
            pin_code: nextValue,
        };
        API_SERVICE.checkPincode(params).then(({ data }) => {
            if (data) {
                KycDetailsForm.setFieldsValue({
                    city: data.payload.city,
                    state: data.payload.state,
                });
            }
        });
    };

    const UserID = (ev: any) => {
        const params = {
            name: ev,
        };
        API_SERVICE.searchUser(params).then(({ data }) => {
            if (data) {
            }
        });
    };

    let { id } = useParams() as any;

    if (!id) {
        history.goBack();
    }

    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const onRejectedReason = (value: any) => {
        setSelectedRejectionReason(value);
    }

    const onStatusChange = (e) => {
        setKycStatus(e.target.value);
        if (e.target.value === "APPROVED") {
            setShowRejectedReason(false);
            setSelectedRejectionReason(null);
        } else if (e.target.value === "REJECTED") {
            setShowRejectedReason(true);
        }
    };

    const onUpdateStatusOk = (e) => {
        const payload = buildStatusPayload(e?.kycStatus, e?.kycRejectedReason ? e?.kycRejectedReason : null);
        API_SERVICE.updateConnectorStatus(id, payload)
            .then(({ data }) => {
                if (data) {
                    props.setConnector(data?.payload);
                    notification.success({ message: "Connector status Updated Successfully" });
                    setViewModel(false);
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    };

    const downloadDocument = (doc: any) => {
        if (doc && doc.businessId && doc.businessDocumentId) {
            API_SERVICE.downloadDocument(doc.businessId, doc.businessDocumentId).then(({ data }) => {
                let bytesData = base64ToArrayBuffer(data?.payload?.documentDetails)
                saveByteArray([bytesData], data?.payload?.documentName)
            }).catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
        }
    };

    const showDocumentImage = (doc: any) => {
        if (doc && doc.businessId && doc.businessDocumentId) {
            API_SERVICE.downloadDocument(doc.businessId, doc.businessDocumentId).then(({ data }) => {
                if (data?.payload?.fileExtn === "pdf") {
                    setDocumentData(data?.payload?.documentDetails);
                    setDocType("application/pdf");
                    setShowAddressPdf(true);
                } else {
                    setDocumentData(data?.payload?.documentDetails);
                    setDocType(data?.payload?.fileExtn);
                }

            }).catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
        }
    };

    useEffect(() => {
        KycStatusForm.setFieldValue({
            kycStatus: "APPROVED",
        });
    }, [viewModel]);

    const onClickEdit = (doc: any) => {
        setViewModel(true);

        showDocumentImage(props?.connector?.kycDetails?.addressProofDoc);
    }

    const onCancelKyc = () => {
        setViewModel(false);
    };

    const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
    const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

    return (
        <Card className="custom-app-card">
            <Form
                layout="vertical"
                form={KycDetailsForm}
                initialValues={{
                    phoneCode: "+91",
                    profession: "Builder",
                    gender: "M",
                }}
                onFinish={onFinishForm}
                autoComplete="off"
                name="leadForm"
                // aria-readonly={disablefield}
                disabled={disablefield}
            >
                <Row gutter={[16, 16]}>
                    <Col span={10}>
                        <Divider orientation="left" orientationMargin="0">
                            PAN Details
                        </Divider>
                        <Form.Item className="field-bg" label="PAN" name="pan"
                        // rules={[
                        //     { required: false, message: "Please enter pan number" },
                        //     {
                        //         pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                        //         message: "Please enter valid pan number",
                        //     },
                        // ]}
                        >
                            {((undefined !== kycDetails?.pan && null !== kycDetails?.pan)) ?
                                (kycDetails?.panVerified ?
                                    <label className="label-verified"><CheckOutlined />Verified</label>
                                    :
                                    <label className="label-verficiation-pending">Verification Pending</label>
                                )
                                :
                                null
                            }
                            <Form.Item
                                style={{ marginBottom: "3px", marginTop: "5px" }}
                                name="pan"
                                rules={[
                                    { required: false, message: "Please enter PAN number" },
                                    {
                                        pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                                        message: "Please enter valid pan number.",
                                    }
                                ]}
                            >
                                <Input

                                    className="custom-input"
                                    onInput={toInputUppercase}
                                    onChange={debounce(checkPanDetails, 1000)}
                                    placeholder="Please enter PAN number"
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="Name as per PAN Card"
                            name="panName"
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Name "
                                readOnly={true}
                            />
                        </Form.Item>
                        <Divider orientation="left" orientationMargin="0">
                            Current Address
                        </Divider>
                        <Form.Item
                            className="field-bg"
                            label="Address Line 1"
                            name="address"
                            rules={[{ required: false, message: "Please enter Address Line 1" }]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Address Line 1"
                            />
                        </Form.Item>
                        <Form.Item
                            className="field-bg"
                            label="Pin Code"
                            name="pinCode"
                        >
                            <Input
                                className="custom-input"
                                onChange={debounce(checkPincode, 1000)}
                                placeholder="Please enter Pin Code"
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="City"
                            name="city"
                            rules={[{ required: false, message: "Please enter City" }]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter City"
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="State"
                            name="state"
                            rules={[{ required: false, message: "Please enter State" }]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter State"
                            />
                        </Form.Item>

                    </Col>
                    <Divider className="custome-devider-hozntl" type="vertical" />
                    <Col span={10}>
                        <Divider orientation="left" orientationMargin="0">
                            Address Proof
                        </Divider>


                        <Form.Item className="field-bg" name="addressDraggerrfile"
                        >
                            <Dragger
                                accept=".pdf, .png, .jpg, .jpeg"
                                name="file"
                                maxCount={1}
                                beforeUpload={() => {
                                    return false;
                                }}
                                onChange={onChange}
                            >
                                <p className="ant-upload-drag-icon">
                                    <Row style={{ display: "flex", justifyContent: "center" }}>
                                        <Col><AddFileGray /></Col>
                                        <Col span={13}><h3 style={{ fontSize: "15px" }}>Upload Another Address Proof</h3></Col>
                                    </Row>
                                </p>
                            </Dragger>
                        </Form.Item>
                        <Form.Item
                            rules={[{ required: (selectedFileList), message: "Please select document type" }]}
                            className="field-bg" name="documentType" style={{ marginTop: "10px", width: "100%" }}>
                            <Select placeholder="Document Type" onChange={(e) => { setAddressDocumentType(e) }}>
                                <Select.Option value="">Select Document Type</Select.Option>
                                {addressType?.sort(function (a: any, b: any) {
                                    if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
                                    if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
                                    return 0;
                                })?.map((docType: any, i: number) => (
                                    <Select.Option value={docType.key} key={i}>{docType.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Divider orientation="left" orientationMargin="0">
                            Uploaded Files
                        </Divider>
                        {undefined !== documentName && null !== documentName ?
                            <Form.Item className="field-bg" label="Address Proof">
                                {((undefined !== kycDetails?.status && null !== kycDetails?.status)) ?
                                    ("APPROVED" === kycDetails?.status ?
                                        <label className="label-verified"><CheckOutlined />Verified</label>
                                        :
                                        ("REJECTED" === kycDetails?.status) ?
                                            <label className="label-verified-rejected"><CloseOutlined />Verification Rejected</label>
                                            :
                                            <label className="label-verficiation-pending">Verification Pending</label>
                                    )
                                    :
                                    null
                                }
                                <Row gutter={[16, 16]}>
                                    <Col span={3} style={{ display: "flex", justifyContent: "start" }}>
                                        <Space className="action-button border-file-icon" size="middle">
                                            <FileBorderIcon style={{'--fileIconColor': appColors?.appPrimaryColor ?? ""} as any}/>
                                        </Space>
                                    </Col>
                                    <Col span={15}>
                                        <Form.Item
                                            style={{ overflow: "hidden" }}
                                            name="doc"
                                        >
                                            <Tooltip title={documentName}>
                                                <label style={{}} className="display-label">{documentName}</label>
                                            </Tooltip>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Space className="action-button border-edit-icon" size="middle" style={{ marginRight: "10px" }}>
                                            <EditIcon onClick={onClickEdit} style={{'--borderColor': appColors?.appPrimaryColor ?? ""}as any}/>
                                        </Space>
                                        <Space className="action-button" size="middle">
                                            <img src={cloudDown} alt="cloudDown" onClick={() => { downloadDocument(props?.connector?.kycDetails?.addressProofDoc) }} />
                                        </Space>
                                    </Col>
                                </Row>
                            </Form.Item>
                            :
                            null
                        }
                    </Col>
                </Row>
                <Row gutter={[24, 24]} style={{ display: "flex", justifyContent: "end" }}>
                    <Divider />
                    <Col >
                        <Form.Item>
                            <Button
                                className="dynamic-btn-default"
                                style={btnDefaultBorderStyle}
                                size="large"
                                loading={loading}
                                htmlType="submit"
                                onClick={() => {
                                    history.push("/connectors");
                                }}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col >
                        <Form.Item>
                            <Button
                                className="dynamic-btn-primary"
                                style={btnPrimaryStyles}
                                size="large"
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                            >
                                SAVE
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            {viewModel &&
                <Modal
                    destroyOnClose={true}
                    forceRender={true}
                    width={900}
                    visible={viewModel}
                    okText="Update"
                    closable={false}
                    maskClosable={false}
                    keyboard={false}
                    footer={false}
                >

                    <div className="cardmain">
                        <div className="cardinner">
                            {documentData ?
                                (
                                    showAddressPdf ? (
                                        <iframe src={"data:" + docType + ";base64," + documentData} className="iframe-pdf"></iframe>
                                    ) : (
                                        <img src={"data:" + docType + ";base64," + documentData} alt="icons" className="img-address" />
                                    )
                                )
                                :
                                null
                            }
                        </div>
                    </div>
                    <Form layout="vertical"
                        form={KycStatusForm}
                        preserve={false}
                        onFinish={onUpdateStatusOk}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={10}>
                                <div className="form-box">
                                    <Form.Item label="Status" name="kycStatus"
                                        rules={[
                                            { required: false, message: "Please select loan type." },
                                        ]}>
                                        <Radio.Group onChange={onStatusChange} defaultValue={kycStatus} className="dynamic-radio" style={{'--borderColor': appColors?.appPrimaryColor ?? "" } as any}>
                                            <Space direction="vertical">
                                                {onBoardingStatus && onBoardingStatus.map((loan: any, i: number) => (
                                                    <Radio key={i} value={loan.key}>
                                                        {loan.value}
                                                    </Radio>
                                                ))}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                        {showRejectedReason &&
                            <Row gutter={[16, 16]}>
                                <Col span={10}>
                                    <Form.Item name="kycRejectedReason"
                                        rules={[
                                            { required: (!selectedRejectionReason), message: "Please Select Rejected Reason." },
                                        ]}>
                                        <Select placeholder="Rejected Reason" onSelect={onRejectedReason} defaultValue={selectedRejectionReason}>
                                            {rejectionReasons.map((rejectionReason: any, i: number) => (
                                                <Select.Option value={rejectionReason.key} key={i}>{rejectionReason.value}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        <Divider />
                        <div style={{ display: "flex", justifyContent: "end", marginBottom: "10px", marginTop: "-10px" }}>
                            <Button style={{'--borderColor': appColors?.appSecondaryColor ?? "",marginRight: "10px" }as any} type="default" onClick={onCancelKyc} className="dynamic-btn-default">Cancel</Button>
                            <Button type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>Update</Button>
                        </div>
                    </Form>

                </Modal>
            }

        </Card >

    );
};

export default ConnectorKycDetails;