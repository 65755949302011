import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home/Home";
import Contact from "./Contact/Contact";
import Solutions from "./Solutions";
import Features from "./Features";

export type pageNames = "CONTACT" | "HOME" | "SOLUTIONS" | "FEATURES";

interface ILendingPage {}

const LendingPage: React.FC<ILendingPage> = () => {
  const [page, setPage] = useState<pageNames>("HOME");

  const renderPage = () => {
    if (page == "CONTACT") {
      return <Contact />;
    } else if (page == "SOLUTIONS") {
      return <Solutions />;
    } else if (page == "FEATURES") {
      return <Features />;
    }
    return <Home />;
  };

  return (
    <div>
      <Header page={page} setPage={setPage} />
      {/* {page == "CONTACT" ? <Contact /> : <Home />} */}
      {renderPage()}
      <Footer />
    </div>
  );
};
export default LendingPage;
