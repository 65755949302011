import React, { useEffect, useState } from "react";
import { ReactComponent as IconGoDigital } from "../../../../assets/images/lending_page/iconGoDigital.svg";
import { ReactComponent as IconCRM } from "../../../../assets/images/lending_page/iconCRM.svg";
import { ReactComponent as IconDataAnalytics } from "../../../../assets/images/lending_page/iconDataAnalytics.svg";
import { ReactComponent as ImgGoDigital } from "../../../../assets/images/lending_page/go_digital.svg";
import { ReactComponent as ImgCRM } from "../../../../assets/images/lending_page/crm.svg";
import { ReactComponent as ImgDataAnalytics } from "../../../../assets/images/lending_page/data_analytics.svg";
import "./Customer360.scss";
import { Segmented } from "antd";

interface Props {}

const Customer360: React.FC<Props> = () => {
  const [selectedItem, setSelectedItems] = useState(0);
  const [timer, setTimer] = useState(0 as any);
  const [selectedTitle, setSelectedTitle] = useState(0);
  const [timerTitle, setTimerTitle] = useState(0 as any);

  const titleList = [
    "Loan Distribution",
    "Insurance Distribution",
    "Wealth Distribution",
    "Liabilities Distribution ",
  ];

  const items = [
    {
      name: "Go Digital",
      desc: "Manage & track loans from submission to disbursement.",
      icon: <IconGoDigital />,
      image: <ImgGoDigital />,
    },
    {
      name: "CRM",
      desc: "Manage & track loans from submission to disbursement.",
      icon: <IconCRM />,
      image: <ImgCRM />,
    },
    {
      name: "Data Analytics",
      desc: "Manage & track loans from submission to disbursement.",
      icon: <IconDataAnalytics />,
      image: <ImgDataAnalytics />,
    },
  ];

  useEffect(() => {
    if (timerTitle) clearTimeout(timerTitle);

    setTimerTitle(
      setTimeout(() => {
        if (selectedTitle == titleList.length - 1) {
          setSelectedTitle(0);
        } else {
          setSelectedTitle(selectedTitle + 1);
        }
      }, 2000)
    );
    return function () {
      if (timerTitle) clearTimeout(timerTitle);
    };
  }, [selectedTitle]);

  useEffect(() => {
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        if (selectedItem == items.length - 1) {
          setSelectedItems(0);
        } else {
          setSelectedItems(selectedItem + 1);
        }
      }, 5000)
    );
    return function () {
      if (timer) clearTimeout(timer);
    };
  }, [selectedItem]);

  return (
    <div>
      <div className="cust-main-title">
        <span>
          Your All-in-One CRM for{" "}
          <span className="span2">{titleList.at(selectedTitle)}</span>
        </span>
      </div>
      <div className="cust-main-subtitle">
        Go fully digital, become a fintech
      </div>
      <div className="cust-tab-main">
        <Segmented
          value={"Customer 360"}
          style={{ marginBottom: 8 }}
          options={["Try now", "Customer 360"]}
        />
      </div>

      <div className="cust-items-title">
        <div className="cust-items">
          {items.map((feature: any, index: number) => {
            return (
              <div
                className={`cust-item ${
                  selectedItem == index ? " cust-item-selected" : ""
                } `}
                onClick={() => setSelectedItems(index)}
              >
                <div className={`cust-item-icon `}>{feature.icon}</div>
                {feature.name}
              </div>
            );
          })}
        </div>
      </div>
      <div className="cust-item-image">
        {items.at(selectedItem)?.desc}
        {items.at(selectedItem)?.image}
      </div>
    </div>
  );
};
export default Customer360;
