import { Button, Card, Col, Divider, List, notification, Row, Space, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./Customer360.scss";
import IconBluePhone from "../../../../assets/images/BluePhone.svg";
import { DownOutlined, InfoCircleOutlined, UpOutlined } from "@ant-design/icons";
import API_SERVICE from "shared/services/api-service";
import moment from "moment";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Text } = Typography;

interface ICustomer360 {caseId:any; customer360Data:any; isPAPQ:any}

const Customer360: React.FunctionComponent<ICustomer360> = (
  props: ICustomer360
) => {
    const {caseId, customer360Data, isPAPQ} = props
    const [isShowFullDetails, setIshowFullDetails] = useState(true);
    const [loading, setLoading] = useState(false);
    const [customer360data, setCustomer360Data] = useState(null as any)
    const [isPreQualified, setIsPreQualified] = useState(false)
    const { isMobile }: any = useSelector((state: RootState) => state.common);
    
    useEffect(() => {
        if(customer360Data === null){
            getLeadById();
        }
        else{
            setCustomer360Data(customer360Data)
            setIsPreQualified(isPAPQ)
        }                
      }, [caseId]);
    
      const getLeadById = () => {
        setLoading(true);
        API_SERVICE.getLeadsById(caseId)
          .then(({ data }) => {
            if (data?.payload) {
             setCustomer360Data(data?.payload?.dsaCaseAdditionalAttributes?.customer360)
             setIsPreQualified(data?.payload?.showPAPQSpecificSection)
            }})
          
          .catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
          })
          .finally(() => {
            setLoading(false);            
          });
      };
    
    const renderUsertDetails =() =>{
        const risk = customer360data?.risk
        const propensity = customer360data?.propensity ?? ""
        return(            
            <Col span={12}>
                <Card className={isPreQualified?"card-PQaction-content":"card-action-content"}>
                    <div className="left-text-div-content">
                        <Text className="left-text-content">Name</Text>
                        <Text className="left-text-content1" >{customer360data?.applicantName}</Text>
                    </div>
                    <Divider style={{ margin: "10px" }} />
                    <div className="left-text-div-content">
                        <Text className="left-text-content"> Mobile No </Text>
                        <Text className="left-text-content1">{customer360data?.mobileNumber}
                            <img src={IconBluePhone} style={{ paddingLeft: "10px" }} />
                        </Text>
                    </div>
                    <Divider style={{ margin: "10px" }} />
                    <div className="left-text-div-content">
                        <Text className="left-text-content"> State</Text>
                        <Text className="left-text-content1">{customer360data?.state}</Text>
                    </div>
                    <Divider style={{ margin: "10px" }} />
                    <div className="left-text-div-content">
                        <Text className="left-text-content">Risk</Text>
                        <Text className="left-text-content1" style={{
                            color:risk==="HIGH"? "rgba(225, 33, 33, 1)": 
                            risk === "MEDIUM"? "rgba(255, 191, 0, 1)" :
                            "rgba(0, 129, 51, 1)"
                            }}>{risk}</Text>
                    </div>
                    <Divider style={{ margin: "10px" }} />
                    <div className="left-text-div-content">
                        <Text className="left-text-content1">Propensity 
                        <Tooltip title="Probability of the customer to take the offer" >
                            <InfoCircleOutlined style={{color:"grey", marginLeft:'5px'}}/>
                        </Tooltip>
                        </Text>
                        <Text className="left-text-content1" 
                        style={{ 
                            color: propensity.includes("P10")? renderPropensityColor(propensity.substr(0,3)) : renderPropensityColor(propensity.substr(0,2))
                        }}>
                            {propensity}</Text>
                    </div>
                </Card>
            </Col>
        )
    }

    const renderPropensityColor= (value:any)=>{
         return(
                (value ===  "P1" || value === "P2" || value === "P3") ? "rgba(0, 129, 51, 1)" :
                (value === "P4"|| value === "P5"|| value === "P6")? "rgba(31, 175, 56, 1)" :
                (value === "P7"|| value === "P8") ? "rgba(255, 191, 0, 1)":
                (value === "P9"|| value === "P10") ? "rgba(225, 33, 33, 1)" : "rgba(0,0,0,1)"
        )
       
    }

    const renderActionDetails=()=>{
        const preQualified = customer360Data?.preQualified ?? {};
        return(
           <Col span={12}>
                <Card bordered={false} className={isPreQualified?"card-PQaction-content":"card-action-content"}>
                    <Text className="right-text-content" style={{ fontSize: "18px" }}>Call To Action</Text>
                    <Divider style={{ margin: "10px" }} />
                    <Text className="right-text-content" style={{ fontSize: "25px" }}>{(isPreQualified)?"Pre Qualified":customer360data?.callToAction}</Text>
                    <Divider style={{ margin: "10px" }} />
                    {isPreQualified ? (
                        <>
                            <div className="left-text-div-content">
                                <Text className="right-text-content" style={{ fontSize: "14px" }}>LenderName</Text>
                                <Text className="right-text-content1">{customer360data?.preQualified?.preQualifiedLenderName}</Text>
                            </div>
                            <Divider style={{ margin: "10px" }} />
                            <div className="left-text-div-content">
                                <Text className="right-text-content" style={{ fontSize: "14px" }}>Amount</Text>
                                <Text className="right-text-content1">{customer360data?.preQualified?.preQualifiedAmount}</Text>
                            </div>
                            <Divider style={{ margin: "10px" }} />
                            <div className="left-text-div-content">
                                <Text className="right-text-content" style={{ fontSize: "14px" }}>ROI</Text>
                                {customer360data?.preQualified?.preQualifiedRateOfInterest && (
                                    <Text className="right-text-content1">{customer360data?.preQualified?.preQualifiedRateOfInterest}%</Text>
                                )}                                
                            </div>
                            <Divider style={{ margin: "10px" }} />
                            <div className="left-text-div-content">
                                <Text className="right-text-content" style={{ fontSize: "14px" }}>Tenure</Text>
                                {customer360data?.preQualified?.preQualifiedTenure && (
                                    <Text className="right-text-content1">{customer360data?.preQualified?.preQualifiedTenure} Years</Text>    
                                )}                                
                            </div>
                            <Divider style={{ margin: "10px" }} />
                            <div className="left-text-div-content">
                                <Text className="right-text-content" style={{ fontSize: "14px" }}>Validity</Text>
                                {customer360data?.preQualified?.preQualifiedValidity && (
                                    <Text className="right-text-content1">{moment(customer360data?.preQualified?.preQualifiedValidity).locale("en").format("MMM DD, YYYY")}</Text>
                                )}                                
                            </div>
                            <Divider style={{ margin: "10px" }} />
                            <div className="left-text-div-content">
                                <Text className="right-text-content" style={{ fontSize: "14px" }}>Product Type</Text>
                                {customer360data?.loanType && (
                                    <Text className="right-text-content1">{customer360data?.loanType}</Text>
                                )}                                
                            </div>
                      </>
                    ) : (
                        <>
                            <div className="left-text-div-content">
                                    <Text className="right-text-content" style={{ fontSize: "14px" }}>Product Type</Text>
                                    <Text className="right-text-content1">{customer360data?.loanType}</Text>
                            </div>
                                <Divider style={{ margin: "10px" }} /><div className="left-text-div-content">
                                <Text className="right-text-content" style={{ fontSize: "14px" }}>Offer Amount</Text>
                                <Text className="right-text-content1">{customer360data?.loanAmount}</Text>
                            </div>
                        </>
                    )
                    }
                    
                </Card>
            </Col>
        )
    }

const renderCustomerLoanDetails = () =>{
    return(
        <div style={{marginTop:"10px"}}>            
            <div className="table-content-grid">
                <div style={{  textAlign: 'center',flex: 1 }}>
                    <p className="table-title-content">Active Loan Count</p>
                    <p className="table-value-content">{customer360data?.activeLoanCount}</p>
                </div>
                <div style={{ textAlign: 'center', flex: 1 }}>
                    <p className="table-title-content">Active Loan Disbursed Amt</p>
                    <p className="table-value-content">{customer360data?.activeLoanDisbursalAmount}</p>
                </div>
                <div style={{ textAlign: 'center', flex: 1 }}>
                    <p className="table-title-content">Active Loan Current Balance</p>
                    <p className="table-value-content">{customer360data?.existingLoanAmount}</p>
                </div>
                <div style={{ textAlign: 'center', flex: 1 }}>
                    <p style={{ margin: 0, fontSize: '14px', color: 'rgba(101,101,117,1)', height:'50px' }}>Active Loan Run Down
                        <Tooltip title="Percentage of Loan already repaid" >
                            <InfoCircleOutlined style={{color:"grey", marginLeft:'5px'}}/>
                        </Tooltip>
                    </p>
                    {customer360data?.activeLoanRunDown &&
                        <p className="table-value-content">{customer360data?.activeLoanRunDown}%</p>
                    }                            
                </div>
                <div style={{ textAlign: 'center', flex: 1 }}>
                    <p className="table-title-content">Last Active Loan Disbursed Date</p>
                    {(customer360data?.latestActiveLoanDisbursementDate) && (
                        <p className="table-value-content">{moment(customer360data?.latestActiveLoanDisbursementDate).format('DD-MM-YYYY')}</p>
                    )}                            
                </div>
            </div> 
            <div style={{ display: "flex", justifyContent: "space-between", marginTop:"20px" }}>
                <p className="p-content">Cross sell opportunities</p>
                <div style={{ float: "right" }}>
                    <Button
                        type="text"
                        size="small"
                        className="details-icons"
                        onClick={() => setIshowFullDetails(!isShowFullDetails)}
                        >
                            {isShowFullDetails ? <UpOutlined /> : <DownOutlined />}
                    </Button>
                </div>
            </div>
            {isShowFullDetails && (
                <>
                
                    <div className="table-content-grid">
                       <div style={{  textAlign: 'center',flex: 1 }}>
                            <p className="table-title-content"> Credit Card Outstanding</p>
                            <p className="table-value-content">{customer360data?.creditCardOutstanding}</p>
                        </div>
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <p className="table-title-content">Active Gold Loan</p>
                            <p className="table-value-content">{customer360data?.activeGoldLoan}</p>
                        </div>
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <p className="table-title-content">Active Home Loan</p>
                            <p className="table-value-content">{customer360data?.activeHomeLoan}</p>
                        </div>
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <p className="table-title-content">Personal Loan From NBFC</p>
                            <p className="table-value-content">{customer360data?.personalLoanFromNbfc}</p>
                        </div>
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <p className="table-title-content">Last Personal Loan Disbursment Date</p>
                            {customer360data?.latestPersonalLoanDisbursementDate && (
                                <p className="table-value-content">{moment(customer360data?.latestPersonalLoanDisbursementDate).format('DD-MM-YYYY')}</p>
                            )}
                            
                        </div> 
                        
                    </div>
                    <div className="table-content-grid" style={{marginTop:"20px"}}>
                       <div style={{  textAlign: 'center',flex: 1 }}>
                            <p className="table-title-content1"> Product</p>
                            <p className="table-value-content1">Disbursed Amount</p>
                            <p className="table-value-content">Balance</p>
                        </div>
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <p className="table-title-content1">Credit Card</p>
                            <p className="table-value-content1">{customer360data?.totalCreditCardDisbursed}</p>
                            <p className="table-value-content">{customer360data?.totalCreditCardBalance}</p>
                        </div>
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <p className="table-title-content1">Home Loan</p>
                            <p className="table-value-content1">{customer360data?.totalHomeLoanDisbursed}</p>
                            <p className="table-value-content">{customer360data?.totalHomeLoanBalance}</p>
                        </div>
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <p className="table-title-content1">Business Loan</p>
                            <p className="table-value-content1">{customer360data?.totalBusinessLoanDisbursed}</p>
                            <p className="table-value-content">{customer360data?.totalBusinessLoanBalance}</p>
                        </div>
                        <div style={{ textAlign: 'center', flex: 1 }}>
                            <p className="table-title-content1">Loan Against Property</p>
                            <p className="table-value-content1">{customer360data?.totalLoanAgainstPropertyDisbursed}</p>
                            <p className="table-value-content">{customer360data?.totalLoanAgainstPropertyBalance}</p>
                        </div>                         
                    </div>
                 </>
            )}
            
        </div> 

    )
}

  const renderKeyValue = (title: string, value: string = "", isHideBottom: boolean = false, isValueBg: boolean = false) => {
    return (
      <div className={`keyValue ${isHideBottom ? " hide-bottom" : ""}`}>
        <span className="item">{title}</span>
        <span className={`item span-value ${isValueBg ? " bg" : ""}`}>{value}</span>
      </div>
    )
  }

  const renderMobileView = () => {
    const risk = customer360data?.risk
    const propensity = customer360data?.propensity ?? ""
    return <>
        <div className="details-box" style={{marginInline:"0px"}}>
            <div style={{ fontSize: "16px" }}>Customer Details</div>
            {renderKeyValue("Name", customer360data?.applicantName, false, false)}
            <div className={`keyValue`}>
                <span className="item">{"Mobile No"}</span>
                <span className={`item span-value`}>{customer360data?.mobileNumber}<img src={IconBluePhone} style={{ paddingLeft: "10px" }} /></span>
            </div>
            {renderKeyValue("Sate", customer360data?.state, false, false)}
            <div className={`keyValue`}>
                <span className="item">{"Risk"}</span>
                <span 
                    className={`item span-value`} 
                    style={{ color:risk==="HIGH"
                                ? "rgba(225, 33, 33, 1)"
                                : risk === "MEDIUM"
                                    ? "rgba(255, 191, 0, 1)" 
                                    : "rgba(0, 129, 51, 1)"
                            }}>
                        {risk}
                    </span>
            </div>
            <div className={`keyValue hide-bottom`}>
                <span className="item">{"Propensity"}
                    <Tooltip title="Probability of the customer to take the offer" >
                        <InfoCircleOutlined style={{color:"grey", marginLeft:'5px'}}/>
                    </Tooltip>
                </span>
                <span 
                    className={`item span-value`} 
                    style={{ color: propensity.includes("P10") 
                                ? renderPropensityColor(propensity.substr(0,3)) 
                                : renderPropensityColor(propensity.substr(0,2))
                            }}>
                    {propensity}
                </span>
            </div>
        </div>
        <div className="details-box" style={{marginInline:"0px"}}>
            <div style={{ fontSize: "16px" }}>Call to Action</div>
            <div className="keyValue" style={{ fontSize: '20px', fontWeight:'700' }}>
                {isPreQualified  ? "Pre Qualified ": customer360data?.callToAction}
            </div>
            {isPreQualified ? (
                <>
                    {renderKeyValue("Lender Name", customer360data?.preQualified?.preQualifiedLenderName, false, true)}
                    {renderKeyValue("Amount", customer360data?.preQualified?.preQualifiedAmount, false, true)}
                    {renderKeyValue("ROI", (customer360data?.preQualified?.preQualifiedRateOfInterest)?`${customer360data?.preQualified?.preQualifiedRateOfInterest}%`:"", false, true)}
                    {renderKeyValue("Tenure", (customer360data?.preQualified?.preQualifiedTenure)?`${customer360data?.preQualified?.preQualifiedTenure} Years`:"", false, true)}
                    {renderKeyValue("Validity", (customer360data?.preQualified?.preQualifiedValidity)? moment(customer360data?.preQualified?.preQualifiedValidity).locale("en").format("MMM DD, YYYY"):"", false, true)}
                    {renderKeyValue("Product Type", (customer360data?.loanType), true, true)}
                </>
            ): 
                <>
                    {renderKeyValue("Product Type", customer360data?.loanType, false, true)}
                    {renderKeyValue("Offer Amount", customer360data?.loanAmount, true, true)}
                </>
            } 
        </div>
        <div className="details-box" style={{marginInline:"0px"}}>
            {renderKeyValue("Active Loan Count", customer360data?.activeLoanCount, false, false)}
            {renderKeyValue("Active Loan Disbursed Amt", customer360data?.activeLoanDisbursalAmount, false, false)}
            {renderKeyValue("Active Loan Current Balance", customer360data?.existingLoanAmount, false, false)}
            <div className={`keyValue`}>
                <span className="item">{"Active Loan Run Down"}
                    <Tooltip title="Percentage of Loan already repaid" >
                        <InfoCircleOutlined style={{color:"grey", marginLeft:'5px'}}/>
                    </Tooltip>
                </span>
                <span className={`item span-value`}>{customer360data?.activeLoanRunDown ? `${customer360data?.activeLoanRunDown}%` : ""}</span>
            </div>
            {renderKeyValue("Last Active Loan Disbursement Date", customer360data?.latestActiveLoanDisbursementDate ? moment(customer360data?.latestActiveLoanDisbursementDate).format('DD-MM-YYYY') : "", true, false)}         
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop:"20px" }}>
                <p className="p-content">Cross sell opportunities</p>
                <div style={{ float: "right" }}>
                    <Button
                        type="text"
                        size="small"
                        className="details-icons"
                        onClick={() => setIshowFullDetails(!isShowFullDetails)}
                        >
                            {isShowFullDetails ? <UpOutlined /> : <DownOutlined />}
                    </Button>
                </div>
            </div>
            {isShowFullDetails && 
                <>
                    <div className="details-box" style={{marginInline:"0px"}}>
                        {renderKeyValue("Credit Card Outstanding", customer360data?.creditCardOutstanding, false, false)}
                        {renderKeyValue("Active Gold Loan", customer360data?.activeGoldLoan, false, false)}
                        {renderKeyValue("Active Home Loan", customer360data?.activeHomeLoan, false, false)}
                        {renderKeyValue("Personal Loan from NBFC", customer360data?.personalLoanFromNbfc, false, false)}
                        {renderKeyValue("Last Active Loan Disbursement Date", customer360data?.latestPersonalLoanDisbursementDate ? moment(customer360data?.latestPersonalLoanDisbursementDate).format('DD-MM-YYYY') : "", true, false)}         
                    </div>
                    <div className="details-box" style={{marginInline:"0px"}}>
                        {renderKeyValue("Product", "Disbursed Amount", false, false)}
                        {renderKeyValue("Credit Card", customer360data?.totalCreditCardDisbursed, false, false)}
                        {renderKeyValue("Home Loan", customer360data?.totalHomeLoanDisbursed, false, false)}
                        {renderKeyValue("Business Loan", customer360data?.totalBusinessLoanDisbursed, false, false)}
                        {renderKeyValue("Loan Against Property", customer360data?.totalLoanAgainstPropertyDisbursed, true, false)}         
                    </div>
                    <div className="details-box" style={{marginInline:"0px"}}>
                        {renderKeyValue("Product", "Balance", false, false)}
                        {renderKeyValue("Credit Card", customer360data?.totalCreditCardBalance, false, false)}
                        {renderKeyValue("Home Loan", customer360data?.totalHomeLoanBalance, false, false)}
                        {renderKeyValue("Business Loan", customer360data?.totalBusinessLoanBalance, false, false)}
                        {renderKeyValue("Loan Against Property", customer360data?.totalLoanAgainstPropertyBalance, true, false)}         
                    </div>
                </>
            }
    </>
  }

  return (
    <>
        {isMobile ? renderMobileView() 
        : 
        <>
        <div className="customer-content-box">
            <div className={isPreQualified?"left-content-box":"left-content-box1"}>
                <Row gutter={[16, 16]}>
                    {renderUsertDetails()}
                    {renderActionDetails()}
                </Row>
            </div>           
        </div>
        <div className="customer-loan-content-box">
            {renderCustomerLoanDetails()}
        </div>
        </>
        }
        {loading &&
            <AppLoading />
        }
    </>
    )
};

export default Customer360;
