import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Space,
  Card,
  Form,
  Col,
  Select,
  List,
  Typography,
  Divider,
} from "antd";
import "./index.scss";
import API_SERVICE from "shared/services/api-service";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { setConnectorId, setDiallerAssignmentFilter, setFilterData, setFilterListDetails, setIsAssignToMe, setIsShowFilter, setLeadPageNo, setLoanType, setLocation, setManagerHierarchy, setRestFilterData, setSelectedFilterListDetails } from "shared/redux/lead-reducer";
const { Option } = Select;
const { Title, Text } = Typography;

const LeadFilter: React.FunctionComponent<any> = (props: any) => {
  const {
    //actionApplyFilter,
    //setIsFilterVisible,
    //setSelectedFilterData,
    //selectedFilterData,
    //filterListData,
    //setFilterListData,
  } = props;

  const [count, setCount] = useState(0);
  const [loanTypes, setLoanTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [disabledDepartment, setDisabledDepartment] = useState<string[]>([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const lead: any = useSelector((state: RootState) => state.lead);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  let userDetails = localStorage.getItem("user") as any;
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  useEffect(() => {
    form.setFieldsValue({
      REGIONAL_HEAD: lead.selectedFilterListDetails["REGIONAL_HEAD"],
      UNIT_MANAGER: lead.selectedFilterListDetails["UNIT_MANAGER"],
      SALES_MANAGER: lead.selectedFilterListDetails["SALES_MANAGER"],
      TEAM_LEADER: lead.selectedFilterListDetails["TEAM_LEADER"],
      SALES_EXECUTIVE: lead.selectedFilterListDetails["SALES_EXECUTIVE"],
      CONNECTOR: lead.selectedFilterListDetails["CONNECTOR"],
      SUB_CONNECTOR: lead.selectedFilterListDetails["SUB_CONNECTOR"],
      DIALLER_ASSIGNMENT_FILTER: lead.diallerAssignmentFilter ?? undefined,
      LOCATION: lead.location,
      LOAN_TYPE: lead.loanType,
      ASSIGNED_TO_ME: (lead.isAssignToMe ? ("" + lead.isAssignToMe) : undefined)
    });
    if (Object.keys(lead.filterListDetails).length === 0) {
      getLists(userDetails?.userDetails?.userId, "");
    }
    getUsersPermissionList();
    generateDisabledDepartment();
  }, [])

  const getDepartmentName = (name, isKey) => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      const hierarchyList = enumValues?.find(
        (item: any) => item.propertyName === "HIERARCHY"
      );
      const tmpDepartment = hierarchyList?.keyAndDisplayValueList?.find(
        (item: any) => isKey ? item.key === name : item.value === name
      );
      return (isKey ? tmpDepartment?.value : tmpDepartment?.key) ?? name;
    }
    return name;
  }

  const getDiallerAssignmentOptions = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      const diallerAssignmentOptions = enumValues?.find(
        (item: any) => item.propertyName === "DIALLER_ASSIGNMENT_FILTER"
      )?.keyAndDisplayValueList
      return (
        <>
          {
            diallerAssignmentOptions?.map((item: any, i) => <Option key={`${item.key}-${i}`} value={item.key}>{item.value}</Option>)
          }
        </>
      )
    }
    return <></>
  }

  const generateDisabledDepartment = () => {
    const tmp = [""];
    let name = getDepartmentName(userDetails?.userDetails?.designation, false);
    tmp.push("REGIONAL_HEAD")
    if (name === "REGIONAL_HEAD") {
      setDisabledDepartment(tmp);
      return;
    }
    tmp.push("UNIT_MANAGER")
    if (name === "UNIT_MANAGER" || name === "Unit Head") {
      setDisabledDepartment(tmp);
      return;
    }
    tmp.push("SALES_MANAGER")
    if (name === "SALES_MANAGER") {
      setDisabledDepartment(tmp);
      return;
    }
    tmp.push("TEAM_LEADER")
    if (name === "TEAM_LEADER") {
      setDisabledDepartment(tmp);
      return;
    }
    tmp.push("SALES_EXECUTIVE")
    if (name === "SALES_EXECUTIVE") {
      setDisabledDepartment(tmp);
      return;
    }
  }

  const getUsersPermissionList = () => {
    API_SERVICE.getUsersPermission(userDetails?.userDetails?.userId).then(({ data }: any) => {
      if (data.payload) {
        setLoanTypes(data.payload.loanProducts.filter((type) => type.selected));
        setLocations(data.payload.locations.filter((type) => type.selected));
      }
    });
  };

  const getLists = (userId, name) => {
    API_SERVICE.getHeirarchyUsers(userId).then(({ data }: any) => {
      if (data.payload) {
        const position = getHierarchyPosition(name);
        if (position <= 6) {
          dispatch(setFilterListDetails({ "key": "SUB_CONNECTOR", "value": (data.payload["Sub Connector"] ?? []) }));
          //let temp = Object.assign(filterListData, { "SUB_CONNECTOR": data.payload["Sub Connector"] ?? [] });
          //setFilterListData(temp);
        }
        if (position <= 5) {
          dispatch(setFilterListDetails({ "key": "CONNECTOR", "value": (data.payload["Connector"] ?? []) }));
          //let temp = Object.assign(filterListData, { "CONNECTOR": data.payload["Connector"] ?? [] });
          //setFilterListData(temp);
        }
        if (position <= 4) {
          dispatch(setFilterListDetails({ "key": "SALES_EXECUTIVE", "value": (data.payload["Sales Executive"] ?? []) }));
          //let temp = Object.assign(filterListData, { "SALES_EXECUTIVE": data.payload["Sales Executive"] ?? [] });
          //setFilterListData(temp);
        }
        if (position <= 3) {
          dispatch(setFilterListDetails({ "key": "TEAM_LEADER", "value": (data.payload["Team Leader"] ?? []) }));
          //let temp = Object.assign(filterListData, { "TEAM_LEADER": data.payload["Team Leader"] ?? [] });
          //setFilterListData(temp);
        }
        if (position <= 2) {
          dispatch(setFilterListDetails({ "key": "SALES_MANAGER", "value": (data.payload["Sales Manager"] ?? []) }));
          //let temp = Object.assign(filterListData, { "SALES_MANAGER": data.payload["Sales Manager"] ?? [] });
          //setFilterListData(temp);
        }
        if (position <= 1) {
          dispatch(setFilterListDetails({ "key": "UNIT_MANAGER", "value": (data.payload["Unit Manager"] ?? []) }));
          //let temp = Object.assign(filterListData, { "UNIT_MANAGER": data.payload["Unit Manager"] ?? [] });
          //setFilterListData(temp);
        }
        if (position <= 0) {
          dispatch(setFilterListDetails({ "key": "REGIONAL_HEAD", "value": (data.payload["Regional Head"] ?? []) }));
          //let temp = Object.assign(filterListData, { "REGIONAL_HEAD": data.payload["Regional Head"] ?? [] });
          //setFilterListData(temp);
        }
        setCount(count + 1);
      }
    });
  };

  const getSubConnector = (connector_id: any = null) => {
    API_SERVICE.getSubConnectors(connector_id)
      .then(({ data }) => {
        const tmpData = data.payload?.filter(
          (item: any) => item?.active
        );
        /*let temp = Object.assign(filterListData, { "SUB_CONNECTOR": tmpData ?? [] });
        setFilterListData(temp);*/
        dispatch(setFilterListDetails({ "key": "SUB_CONNECTOR", "value": (data.payload["Sub Connector"] ?? []) }));
        setCount(count + 1);
      })
  };

  const clearFilter = () => {
    form.resetFields();
    dispatch(setRestFilterData());
    getLists(userDetails?.userDetails?.userId, "");
  };

  const onFinish = () => {
    let param = { "loanProductIds": lead?.loanType?[lead.loanType]:[], "location": lead.location };
    if (lead.isAssignToMe) {
      param["managerHierarchy"] = { designation: "ASSIGNED_TO_ME", managerId: userDetails?.userDetails?.userId }
    } else {
      param["managerHierarchy"] = lead.managerHierarchy
    }

    if (lead.diallerAssignmentFilter) {
      param["diallerAssignmentFilter"] = lead.diallerAssignmentFilter;
    }

    let selectedSubOrConnectorId = form.getFieldValue("SUB_CONNECTOR") ? form.getFieldValue("SUB_CONNECTOR") : form.getFieldValue("CONNECTOR");
    if (selectedSubOrConnectorId) {
      dispatch(setConnectorId(selectedSubOrConnectorId));
      param["connectorId"] = selectedSubOrConnectorId.split("-")[1];
    }
    dispatch(setFilterData(param));
    dispatch(setLeadPageNo(1))
  }

  const selectAssignToMe = (value: any) => {
    /*let temp = Object.assign(selectedFilterData, { "ASSIGNED_TO_ME": value });
    setSelectedFilterData(temp);*/
    if ("true" === value)
      dispatch(setIsAssignToMe(true));
    else
      dispatch(setIsAssignToMe(false));
  }

  const handleDialerAssignmentSelect = (value: any) => {
    dispatch(setDiallerAssignmentFilter(value));
  }

  const onSelect = (e, listFieldName) => {
    //getFilterData(name, e);
    const position = getHierarchyPosition(listFieldName);

    if (listFieldName === "CONNECTOR") {
      getSubConnector(e);
    } else if (listFieldName !== "SUB_CONNECTOR") {
      //const selectManager = {designation: listFieldName,managerId: e};
      /*let temp = Object.assign(selectedFilterData, { "managerHierarchy": selectManager });
      setSelectedFilterData(temp);*/
      dispatch(setManagerHierarchy({ designation: listFieldName, managerId: e }));
      getLists(e, listFieldName);
    }
    const tmp = [""];

    if (position <= 7) {
      tmp.push("ASSIGNED_TO_ME");
    }
    if (position <= 6) {
      tmp.push("SUB_CONNECTOR");

    }
    if (position <= 5) {
      tmp.push("CONNECTOR");
    }
    if (position <= 4) {
      tmp.push("SALES_EXECUTIVE");

    }
    if (position <= 3) {
      tmp.push("TEAM_LEADER");
    }
    if (position <= 2) {
      tmp.push("SALES_MANAGER");
    }
    if (position <= 1) {
      tmp.push("UNIT_MANAGER");
    }
    form.resetFields(tmp);

    tmp.map((item) => {
      //delete selectedFilterData[item]
    })
  }

  const getHierarchyPosition = (name): number => {
    if (name === "REGIONAL_HEAD") {
      return 1;
    } else if (name === "UNIT_MANAGER") {
      return 2;
    } else if (name === "SALES_MANAGER") {
      return 3;
    } else if (name === "TEAM_LEADER") {
      return 4;
    } else if (name === "SALES_EXECUTIVE") {
      return 5;
    } else if (name === "CONNECTOR") {
      return 6;
    } else if (name === "SUB_CONNECTOR") {
      return 7;
    }
    return 0;
  }

  const renderSelect = (name, placeHolder) => {
    //let listTemp = filterListData[`${name}`]
    let listTemp = lead.filterListDetails[name]

    return (
      <Form.Item name={name} >
        <Select
          className="custom-select"
          placeholder={placeHolder}
          showSearch
          disabled={disabledDepartment.includes(name)}
          onSelect={(e: any) => {
            dispatch(setSelectedFilterListDetails({ key: name, value: e }));
            let id = e.split("-");
            onSelect(id.at(-1), name);
          }}
        >
          {listTemp/*?.sort(function (a: any, b: any) {
            if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
            if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
            return 0;
          })*/?.map((user: any) => (
            <>
              <Select.Option key={user?.userId} value={`${user?.fullName}-${user?.userId}`}>
                {user?.fullName}
              </Select.Option>
            </>
          ))}
        </Select>
      </Form.Item>
    );
  };

  const listHierarchy = [
    <List.Item key="REGIONAL_HEAD">
      {renderSelect("REGIONAL_HEAD", "Regional Head")}
    </List.Item>,
    <List.Item key="UNIT_MANAGER">
      {renderSelect("UNIT_MANAGER", "Unit Manager")}
    </List.Item>,
    <List.Item key="SALES_MANAGER">
      {renderSelect("SALES_MANAGER", "Sales Manager")}
    </List.Item>,
    <List.Item key="TEAM_LEADER">
      {renderSelect("TEAM_LEADER", "Team Leader")}
    </List.Item>,
    <List.Item key="SALES_EXECUTIVE">
      {renderSelect("SALES_EXECUTIVE", "Sales Executive")}
    </List.Item>,
    <List.Item key="CONNECTOR">
      {renderSelect("CONNECTOR", "Connector")}
    </List.Item>,
    <List.Item key="SUB_CONNECTOR">
      {renderSelect("SUB_CONNECTOR", "Sub Connector")}
    </List.Item>,
    <List.Item key="ASSIGNED_TO_ME">
      <Form.Item name="ASSIGNED_TO_ME">
        <Select className="custom-select" placeholder="Assigned To Me" /*onSelect={selectAssignToMe}*/ onSelect={selectAssignToMe}>
          <Option value="true">Yes</Option>
          <Option value="false">No</Option>
        </Select>
      </Form.Item>
    </List.Item>,
    <List.Item key="DIALLER_ASSIGNMENT_FILTER">
      <Form.Item name="DIALLER_ASSIGNMENT_FILTER">
        <Select className="custom-select" placeholder="Dialler Campaign" onSelect={handleDialerAssignmentSelect}>
          {getDiallerAssignmentOptions()}
        </Select>

      </Form.Item>
    </List.Item>,
  ];

  const listOther = [
    {
      title: "Geography",
      list: (
        <List.Item key="LOCATION">
          <Form.Item name="LOCATION">
            <Select
              className="custom-select"
              placeholder="Location"
              showSearch
              onSelect={(e) => {
                dispatch(setLocation(e));
                /*let temp = Object.assign(selectedFilterData, { "Location": e });
                setSelectedFilterData(temp);*/
              }}
            >
              {locations?.sort(function (a: any, b: any) {
                if (a.officeName.toLowerCase() < b.officeName.toLowerCase()) return -1;
                if (a.officeName.toLowerCase() > b.officeName.toLowerCase()) return 1;
                return 0;
              })?.map((location: any) => (
                <Option key={location?.officeName} value={location?.officeName}>
                  {location?.officeName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </List.Item>
      ),
    },
    {
      title: "Loan Type",
      list: (
        <List.Item key="LOAN_TYPE">
          <Form.Item name="LOAN_TYPE">
            <Select
              className="custom-select"
              placeholder="Loan Type"
              showSearch
              onSelect={(e) => {
                dispatch(setLoanType(e));
                /*let temp = Object.assign(selectedFilterData, { "unitedManager": e });
                setSelectedFilterData(temp);*/
              }}
            >
              {loanTypes?.
                sort(function (a: any, b: any) {
                  if (a.loanProduct.toLowerCase() < b.loanProduct.toLowerCase()) return -1;
                  if (a.loanProduct.toLowerCase() > b.loanProduct.toLowerCase()) return 1;
                  return 0;
                })?.
                map((loan: any) => (
                  <Option key={loan?.loanProduct} value={loan?.loanProductId}>
                    {loan?.loanProduct}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </List.Item>
      ),
    },
  ];

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <div>
      <Card style={{ width: "100%", marginBottom: "30px" }}>
        <Title level={5} style={{ marginLeft: "5px" }}>
          <Row>
            <Col span={12}> Filter By:</Col>
            <Col span={12} style={{ display: "flex", justifyContent: "end" }}><CloseOutlined onClick={() => {

              //setIsFilterVisible(false)
              dispatch(setIsShowFilter(false));
              // dispatch(setRestFilterData());
            }} /></Col>
          </Row>
        </Title>
        <Title level={5} style={{ display: "flex", justifyContent: "end" }}></Title>
        <Divider />
        <Form form={form} size="large" layout="vertical" autoComplete="off" onFinish={onFinish}>
          <Text style={{ marginLeft: "5px" }}>Hierarchy</Text>
          <List
            style={{ marginTop: "8px" }}
            grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }}
            dataSource={listHierarchy}
            renderItem={(item) => item}
          />

          <List
            style={{ marginTop: "20px" }}
            grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }}
            dataSource={listOther}
            renderItem={(item) => {
              return (
                <>
                  <Text style={{ marginLeft: "10px" }}>{item.title}</Text>
                  <div style={{ marginTop: "8px" }}>{item.list}</div>
                </>
              );
            }}
          />
          <Divider />
          <div style={{ textAlign: "right" }}>
            <Form.Item>
              <Space>
                <Button
                 className="dynamic-btn-default"
                 style={btnDefaultBorderStyle}
                  size="large"
                  htmlType="button"
                  onClick={clearFilter}
                >
                  Clear
                </Button>
                <Button size="large" type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                  Apply Filter
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default LeadFilter;
