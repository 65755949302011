import React, { useEffect, useState } from "react";
import { ReactComponent as IconBRE } from "../../../../assets/images/lending_page/features/iconBRE.svg";
import { ReactComponent as IconBureauAnalysis } from "../../../../assets/images/lending_page/features/iconBureauAnalysis.svg";
import { ReactComponent as IconCustomer360 } from "../../../../assets/images/lending_page/features/iconCustomer360.svg";
import { ReactComponent as IconPayouts } from "../../../../assets/images/lending_page/features/iconPayouts.svg";
import { ReactComponent as IconPushApi } from "../../../../assets/images/lending_page/features/iconPushApi.svg";
import { ReactComponent as IconStatementAnalyzer } from "../../../../assets/images/lending_page/features/iconStatementAnalyzer.svg";
import { ReactComponent as IconWhatsAppDocs } from "../../../../assets/images/lending_page/features/iconWhatsAppDocs.svg";
import { ReactComponent as ImgWhatsAppDocs } from "../../../../assets/images/lending_page/WhatsApp_Docs.svg";
import { ReactComponent as ImgBRE } from "../../../../assets/images/lending_page/BRE.svg";
import { ReactComponent as ImgBureauAnalysis } from "../../../../assets/images/lending_page/Bureau_Analysis.svg";
import { ReactComponent as Imgcustomer360 } from "../../../../assets/images/lending_page/customer360.svg";
import { ReactComponent as ImgPayouts } from "../../../../assets/images/lending_page/Payouts.svg";
import { ReactComponent as ImgPushAPI } from "../../../../assets/images/lending_page/push_api.svg";
import { ReactComponent as ImgStatementAnalyzer } from "../../../../assets/images/lending_page/Statement_Analyzer.svg";
import "./CRMSoftwareFeatures.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface Props {}

const CRMSoftwareFeatures: React.FC<Props> = () => {
  const [selectedFeature, setSelectedFeature] = useState(0);
  const [timer, setTimer] = useState(0 as any);

  const { isMobile }: any = useSelector((state: RootState) => state.common);

  const features = [
    {
      name: "Customer 360",
      desc: "One view of  customer",
      icon: <IconCustomer360 />,
      image: <Imgcustomer360 />,
    },
    {
      name: "Push Api",
      desc: "One window to connect to all BFSI",
      icon: <IconPushApi />,
      image: <ImgPushAPI />,
    },
    {
      name: "Bureau Analysis",
      desc: "One window to customer credit history",
      icon: <IconBureauAnalysis />,
      image: <ImgBureauAnalysis />,
    },
    {
      name: "WhatsApp Docs",
      desc: "One window to all conversation",
      icon: <IconWhatsAppDocs />,
      image: <ImgWhatsAppDocs />,
    },
    {
      name: "Statement Analyzer",
      desc: "One window to customer financials",
      icon: <IconStatementAnalyzer />,
      image: <ImgStatementAnalyzer />,
    },
    {
      name: "BRE",
      desc: "One window to all decisioning",
      icon: <IconBRE />,
      image: <ImgBRE />,
    },
    {
      name: "Payouts",
      desc: "One window to all payout needs",
      icon: <IconPayouts />,
      image: <ImgPayouts />,
    },
  ];

  useEffect(() => {
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        if (selectedFeature == features.length - 1) {
          setSelectedFeature(0);
        } else {
          setSelectedFeature(selectedFeature + 1);
        }
      }, 5000)
    );
    return function () {
      if (timer) clearTimeout(timer);
    };
  }, [selectedFeature]);

  const renderFeaturesMobile = () => {
    return (
      <div className="features-mobile">
        {features?.map((feature: any, index: number) => (
          <div
            className={`feature-mobile`}
            onClick={() => setSelectedFeature(index)}
          >
            {feature.icon}
            <div className="name-desc">
              <span className="name">{feature.name}</span>
              <span className="desc">{feature.desc}</span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderFeaturesWeb = () => {
    return (
      <>
        <div className="features-icons">
          <ul>
            {features?.map((feature: any, index: number) => (
              <li>
                <div
                  className={`feature ${
                    selectedFeature == index ? " feature-selected" : ""
                  } `}
                  onClick={() => setSelectedFeature(index)}
                >
                  <div className={`feature-icon `}>{feature.icon}</div>
                  {feature.name}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="feature-image">
          {features.at(selectedFeature)?.image}
        </div>
      </>
    );
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <div className="bg-shadow"></div>
      <div style={{ position: "relative", display: "grid", gap: "20px" }}>
        <div className="crmSoftwereTitle">
          <span className="one">One</span>
          <span className="flo">Flo</span>
          <span>-CRM Software Features</span>
        </div>
        {isMobile ? renderFeaturesMobile() : renderFeaturesWeb()}
      </div>
    </div>
  );
};
export default CRMSoftwareFeatures;
