import "./index.scss";

import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import moment from "moment";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import {
  base64ToArrayBuffer,
  saveByteArray,
  hasUserRole,
} from "shared/services/Utility";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import {ReactComponent as FileBorder} from "../../assets/images/file-border.svg";
import {ReactComponent as IconEyeBorder} from "../../assets/images/eye_border.svg";
import {ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";
import {ReactComponent as IconDeleteBorder} from "../../assets/images/delete_border.svg";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";


type Props = {};

const Notices: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const [allNotices, setAllNotices] = useState<"" | any>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [apiCount, setApiCount] = useState(0);
  const [pages, setPages] = useState();
  const [searchValue, setSearchValue] = useState(null as any);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState();
  const [form] = Form.useForm();
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Notices"}));
    getAllNotices();
  }, []);

  const columns = [
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
    },
    {
      title: "Attachment",
      key: "attachment",
      dataIndex: ["documentDetails", "documentName"],
      render: (title: any, record: any) => {
        const customFileStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};
        const document = (record?.documentDetails?.length ?? 0) > 0 ? record.documentDetails[0] : {};
        return <Space className="action-button" size="middle">
          <Button
            className="custome-btn-icon"
            style={customFileStyles}
            size="small"
            type="text"
            icon={<FileBorder />}
            onClick={(event) => downloadDocument(event, record)}
          />
          <span>{document?.documentName}</span>
        </Space>
      },
    },
    {
      title: "Created",
      dataIndex: "creationDate",
      key: "date",
      render: (creationDate: any, record: any) => (
        <span>
          {moment(new Date(creationDate))
            .locale("en")
            .format("DD/MM/YYYY, hh:mm A")}
        </span>
      ),
    },
    {
      title: "Status",
      key: "active",
      render: (_: any, record: any) => (
        <Switch
          style={customStyles}
          checked={record.active}
          disabled={userRolePermission["ReadOnly"]}
          onChange={(event) => onChangeStatus(event, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Button
            className="custome-btn-icon"
            style={customStyles}
            size="small"
            type="text"
            icon={<IconEyeBorder />}
            onClick={() => goToLeadDetails1(record)}
          />
          <Button
            className="custome-btn-icon-secondary"
            style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
            size="small"
            type="text"
            icon={<IconEditBorder />}
            disabled={userRolePermission["ReadOnly"]}
            hidden={
              !(
                userRolePermission["UpdateLead"] ||
                userRolePermission["UpdateOperations"] ||
                userRolePermission["Admin"]
              )
            }
            onClick={() => goToLeadDetails(record)}
          />
          <Popconfirm
          style={{whiteSpace:'nowrap'}}
            title="Are you sure to delete?"
            onConfirm={(event) => handleDelete(event, record)}
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
          ><Button
                style={{backgroundColor:"transparent", border:'none'}}
                size="small"
                type="text"        
                disabled={userRolePermission["ReadOnly"]}
                hidden={
                  !(
                    userRolePermission["UpdateLead"] ||
                    userRolePermission["UpdateOperations"] ||
                    userRolePermission["Admin"]
                  )
                }
                onClick={() => handleDelete}
                icon={<IconDeleteBorder width={24} height={24}/>}
              />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const downloadDocument = (doc: any, item: any) => {
    if((item?.documentDetails?.length ?? 0) == 0) {
      return;
    }

    API_SERVICE.downloadDocument(
      item.documentDetails[0].businessId,
      item.documentDetails[0].businessDocumentId
    )
      .then(({ data }) => {
        let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
        saveByteArray([bytesData], data.payload.documentName);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleDelete = (event: any, item: any) => {
    const id = item.busCommId;
    setLoading(true);
    API_SERVICE.getAPIForDeleteDataInList(id)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          let page = null as any;
                    if((totalCount/10) === parseFloat((currentPage-1)+"."+1)){
                        setCurrentPage(1);
                        page = 1;
                    }else{
                        setCurrentPage(currentPage);
                        page = currentPage;
                    }
          getAllNotices(page,pageSize,searchValue);
        }
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const goToLeadDetails = (item: any) => {
    history.push(`/notices/edit-notice/${item.busCommId}`);
  };

  const goToLeadDetails1 = (item: any) => {
    // history.push(`/lead/details/${item.caseDetails.caseId}`)
    history.push(`/notices/view-notice/${item.busCommId}`);
  };

  const getAllNotices = (pageNo: number = 1,pageSize: any = null, searchVal: any = null) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, searchParam: searchVal };
    API_SERVICE.getNotices(params)
      .then(({ data }: any) => {
        if (data.payload.content.length > 0) {
          mapLeadsData(data);
        } else {
          notification.error({ message: "No Notice found." });
          mapLeadsData({});
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
    if (allNotices.length !== 0) {
    }
  };

  const mapLeadsData = (data: any) => {
    setTotalCount(data?.payload?.totalElements ?? 0);
    setAllNotices(data?.payload?.content ?? []);
    setApiCount(1);
  };

  const onChangeStatus = (event: any, item: any) => {
    item.status = event;
    const id = item.busCommId;
    setLoading(true);
    API_SERVICE.updateOffers(
      {
        active: item.status,
        type: "NOTICE",
        title: item.title,
        description: item.description,
      },
      id
    )
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          getAllNotices(currentPage,pageSize,searchValue);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const addOfferHandler = () => {
    history.push("/notices/add-notice");
  };

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearchValue(event.target.value);
    getAllNotices(1,pageSize, event.target.value);
  };

  const handlePaginationChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setTotal( pagination.total);
    getAllNotices(pagination.current, pagination.pageSize,searchValue);
    setPages(pagination.current);
  };

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};

  return (
    <>
      <div className="content-box">
        <Form form={form}>
        <Row>
          <Col className="filter-bar" span={20} xs={{ span: 12 }}>
          <Form.Item name="searchBox">
            <Input
              size="large"
              className="searchBox"
              onChange={debounce(handleSearch, 1000)}
              placeholder="Search"
              allowClear
              prefix={<FaSearch />}
            />
            </Form.Item>
          </Col>

          <Col span={4} xs={{ span: 12 }} className="filter-bar filter-bar-right">
          <Button
              type="primary"
              className="refresh-button"
              onClick={() => {
                form.setFieldsValue({
                  searchBox: ""
                })
                setSearchValue("");
                getAllNotices(currentPage, pageSize);
              }}
              shape="round"
              //icon={<ReloadOutlined />}
              size={"middle"}
            ><img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}}/></Button>
            <Button
              type="primary"
              className="add-button dynamic-btn-primary"
              style={btnPrimaryStyles}
              onClick={addOfferHandler}
              shape="round"
              icon={<FaPlus />}
              size={"middle"}
              disabled={!(userRolePermission["Admin"] ||  userRolePermission["CreateOperations"])}
              hidden={
                !(
                  userRolePermission["CreateLead"] ||
                  userRolePermission["CreateOperations"] ||
                  userRolePermission["Admin"]
                )
              }
            >
              Add Notices
            </Button>
          </Col>
        </Row>
        </Form>
        <Row>
          <Col span={24}>
            <Table
              className="dynamic-pagination-checkbox"
              style={customStyles}
              loading={loading}
              columns={columns}
              dataSource={allNotices}
              size="small"
              onChange={handlePaginationChange}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount, // total count returned from backend
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Notices;
