import React, { useEffect, useState } from "react";
import { ReactComponent as IconAddLead } from "../../../assets/images/Add-Lead.svg";
import { ReactComponent as IconDownloadExcel } from "../../../assets/images/Download-Excel-Blue.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import API_SERVICE from "shared/services/api-service";
import { getUser, getUserDetails } from "shared/services/Utility";
import AppLoading from "../AppLoading/AppLoading";
import { notification } from "antd";
import "./AdminBroker.scss";

type Props = {};

const AdminBroker: React.FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const isAdmin = getUserDetails().role == "ROLE_ADMIN";

  useEffect(() => {
    dispatch(setScreenHeader({ screenTitle: "" }));
    if (!isAdmin) {
      handleAddLead();
    }
  }, []);

  const handleAddLead = () => {
    setLoading(true);
    const params = {
      tenantId: getUser()?.businessId,
    };
    API_SERVICE.getInsuranceOnlineProducts(params)
      .then(({ data }: any) => {
        if ((data?.payload?.length ?? 0) > 0) {
          history.push({
            pathname: `/lead/add-lead`,
            state: { products: data?.payload },
          });
        } else {
          notification.error({
            message: API_SERVICE.handleErrors("No data Found"),
          });
        }
      })
      .catch((e: any) =>
        notification.error({ message: API_SERVICE.handleErrors(e) })
      )
      .finally(() => setLoading(false));
  };

  if (!isAdmin) {
    return <>{loading && <AppLoading />}</>;
  }

  const setIcon = (Icon: any, strokeFill: boolean = false) => {
    return (
      <Icon
        height={50}
        width={50}
        className={strokeFill ? "stroke-fill" : ""}
      />
    );
  };

  return (
    <>
      <div className="container">
        <div className="dashboard-broker-body">
          <div className="dashboard-broker-menus">
            <div className="row">
              <div className="col-md-4 divDivder">
                <button
                  className="dashboard-borker-button"
                  onClick={handleAddLead}
                >
                  {setIcon(IconAddLead)}
                  <p className="col-p-text">Add Lead</p>
                </button>
              </div>
              <div className="col-md-4 divDivder">
                <button
                  className="dashboard-borker-button"
                  onClick={() => history.push("/employees")}
                >
                  {setIcon(IconAddLead)}
                  <p className="col-p-text">Manage User</p>
                </button>
              </div>
              <div className="col-md-4 divDivderLast">
                <button
                  className="dashboard-borker-button"
                  onClick={() => history.push("/report")}
                >
                  {setIcon(IconDownloadExcel)}
                  <p className="col-p-textLast"> Download Report </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <AppLoading />}
    </>
  );
};

export default AdminBroker;
