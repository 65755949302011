import React from "react";
import notificationImage from "../../../../assets/images/FooterCards/notification.svg";
import inAppCommunicationImage from "../../../../assets/images/FooterCards/inAppComm.svg";
import attendanceImage from "../../../../assets/images/FooterCards/attendance.svg";
import digitalVisitingCardsImage from "../../../../assets/images/FooterCards/digitalVisitingCard.svg";
import tickIcon from "../../../../assets/images/FooterCards/tickIcon.svg";
import "./BoostProductionFetures.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";



const BoostProductionFetures = () => {

  const { isMobile }: any = useSelector((state: RootState) => state.common);

  const cardData = [
    {
      title: "Attendance",
      items: [
        "Check In / Check out",
        "Location Tracking",
        "Attendance reporting",
      ],
      image: attendanceImage,
    },
    {
      title: "Digital Visiting Cards",
      items: ["Professional and modern", "Share effortlessly", "Update easily"],
      image: digitalVisitingCardsImage,
    },
    {
      title: "Notifications",
      items: [
        "Real-time alerts",
        "Personalized preferences",
        "Improved communication",
        "Reminders to action",
      ],
      image: notificationImage,
    },
    {
      title: "In App Communication",
      items: [
        "Efficient communication",
        "Targeted messaging",
        "Tracking and analytics",
      ],
      image: inAppCommunicationImage,
    },
  ];

  return (
    <div className="cards-container">
      {cardData.map((bpf, index) => (
        <div className="bpf" key={index}>
          {!isMobile && (<img src={bpf.image} alt={bpf.title} className="bpf-image" />)}
          <h2 className="bpf-title">{bpf.title}</h2>
          <ul className="bpf-items">
            {bpf.items.map((item, idx) => (
              <li key={idx}>
                <i className="check-icon">
                  <img src={tickIcon} alt="✓" />
                </i>{" "}
                {item}
              </li>
            ))}
          </ul>
          {isMobile && (<img src={bpf.image} alt={bpf.title} className="bpf-image" />)}
        </div>
      ))}
    </div>
  );
};

export default BoostProductionFetures;
