import { notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { setScreenHeader } from "shared/redux/common-reducer";
import API_SERVICE from "shared/services/api-service";

interface IProductFooter {}

const LeadDetailsEncrypted: React.FC<IProductFooter> = (
  props: IProductFooter
) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Lead",
        backScreenPath: "/lead",
        screenTitle: "View Lead",
      })
    );
    getDiallerDecryptURL();
  }, []);

  const getDiallerDecryptURL = () => {
    setLoading(true);
    const origin = window.location.href.split("ld/");
    let payload = { encryptedUrl: origin?.at(-1) };
    API_SERVICE.getDiallerDecryptURL(payload)
      .then(({ data }) => {
        if (data?.payload?.url) {
          let url = data?.payload?.url;
          // url = url.replace(
          //   "https://oi-dev2-dcweb.oneflotest.in",
          //   "http://localhost:3011"
          // );
          window.location.replace(url);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        history.replace("/dashboard")
      })
      .finally(() => setLoading(false));
  };

  return <>{loading && <AppLoading />}</>;
};

export default LeadDetailsEncrypted;
