import React, { useEffect, useState } from "react";
import {
  Typography,
  Input,
  Radio,
  Row,
  Col,
  Form,
  Button,
  Space,
  notification,
  Divider,
  Select,
  AutoComplete,
  Card,
} from "antd";

import "./index.scss";
import API_SERVICE from "shared/services/api-service";
import debounce from "lodash/debounce";
import { Link, useHistory } from "react-router-dom";
import LoaderOverlay from "shared/LoaderOverlay";
import { GSTIN_REGEX, PHONE_NUMBER_REGEX, CONNECTOR_NAME_REGEX} from "shared/constants/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { TextArea } = Input;
const { Search } = Input;
const { Title } = Typography;
type Props = {};

const AddConnectors: React.FunctionComponent<Props> = () => {
  const [leadForm] = Form.useForm() as any;
  const history = useHistory();
  const [userDetailData, setUserDetailData] = useState(null as any);
  const [loading, setLoading] = useState(false);
  const [genders, setGenders] = useState([]);
  const [assignToData, setAssignToData] = useState([] as any);

  const [addConnectorForm] = Form.useForm() as any;
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const [isLoading, setIsLoading] = useState(false);

  const professions = [
    { value: "Builder", key: "Builder" },
    { value: "CA/ Tax C", key: "CA/ Tax C" },
    { value: "Ex Banker", key: "Ex Banker" },
    { value: "L Freelancer", key: "L Freelancer" },
    { value: "Ins Agent", key: "Ins Agent" },
    { value: "RE Broker", key: "RE Broker" },
    { value: "Other", key: "Other" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Connector",
        backScreenPath: "/connectors",
        screenTitle: "Add Connector",
      })
    );

    const userDetails = localStorage.getItem("user") as any;
    if (userDetails) {
      setUserDetailData(JSON.parse(userDetails));
    }
    getAllTypes();
  }, []);

  const getAllTypes = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        let filteredGenderType = data.find(
          (item: any) => item.propertyName == "GENDER"
        );
        setGenders(filteredGenderType.keyAndDisplayValueList);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const buildPayload = (formData: any) => {
    let userData = localStorage.getItem("user") as any;
    let assignTo = assignToData.find(
      (item) => item.fullName == formData.assignedTo
    );

    if (userData) {
      userData = JSON.parse(userData);
    }
    const payload = {
      address: {
        address: formData.address1,
        area: userData.userDetails.address.country,
        city: formData.city,
        country: userData.userDetails.address.country,
        geoCode: formData.geoCode,
        pinCode: formData.pincode,
        state: formData.state,
      },
      gstIn:formData.gstIn,
      comment: formData.comment,
      companyName: formData.companyName,
      email: formData.email,
      driverLicenseNo: null,
      // assignedTo: assignTo?.id || userData.userDetails.userId,
      assignedTo: assignTo?.userId
        ? assignTo.userId
        : userDetailData?.userDetails.userId,
      gender: formData.gender,
      managerId: null,
      primaryRole: null,
      name: formData.contactName,
      connectorContactBrandName:formData.connectorContactBrandName,
      pan: formData.pan,
      // phoneNumber: formData.phoneCode + formData.phoneNumber,
      phoneNumber: formData.phoneNumber,
      profession: formData.profession,
      referralId: null,
      userId: null,
    };
    return payload;
  };

  const onFinishForm = (values: any) => {
    const payload = buildPayload(values);
    if (isLoading === true) return;
    setIsLoading(true);

    API_SERVICE.addConnector(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/connectors");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }).finally(() => {
        setIsLoading(false)
      })
  };

  const checkPanDetails = (e: any) => {
    e.persist();
    const { value: nextValue } = e.target;
    const params = {
      panNumber: nextValue,
    };
    API_SERVICE.checkPanDetails(params).then(({ data }) => {
      addConnectorForm.setFieldsValue({
        contactName: data?.payload?.fullName?.trim(),
      });
    });
  };

  const checkPincode = (e: any) => {
    const { value: nextValue } = e.target;
    const params = {
      pin_code: nextValue,
    };
    API_SERVICE.checkPincode(params).then(({ data }) => {
      if (data) {
        addConnectorForm.setFieldsValue({
          city: data.payload.city,
          state: data.payload.state,
        });
      }
    });
  };

  const searchAssignTo = (ev: any) => {
    const params = {
      name: ev,
    };
    API_SERVICE.getUsersWithRole(params).then(({ data }) => {
      if (data) {
        setAssignToData(data.payload);
      }
    });
  };

  const prefixSelector = (
    <Form.Item name="phoneCode" noStyle>
      <Select defaultValue="+91"

      >
        <Select.Option value="+91">91</Select.Option>
      </Select>
    </Form.Item>
  );

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  if (!userDetailData) return <></>;

  const btnRadioStyles: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <>

      <div className="content-box">
        
        <Card className="custom-app-card">
          <Form
            layout="vertical"
            form={addConnectorForm}
            initialValues={{
              phoneCode: "+91",
              profession: "Builder",
              gender: "M",
              assignedTo: userDetailData?.userDetails?.fullName,
            }}
            onFinish={onFinishForm}
            autoComplete="off"
            name="leadForm"
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Select Profession"
                  name="profession"
                  rules={[
                    { required: false, message: "Please select profession." },
                  ]}
                >
                  <Radio.Group
                    className="custom-radio profession-btns"
                    style={btnRadioStyles}
                    name="profession"
                  >
                    <Space direction="horizontal">
                      {professions &&
                        professions.map((loan: any, i: number) => (
                          <Radio.Button key={i} value={loan.key}>
                            {loan.value}
                          </Radio.Button>
                        ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Select Gender"
                  name="gender"
                  rules={[{ required: false, message: "Please select gender." }]}
                >
                  <Radio.Group
                    className="custom-radio profession-btns"
                    style={btnRadioStyles}
                    name="gender"
                  >
                    <Space direction="horizontal">
                      {genders &&
                        genders.map((gender: any, i: number) => (
                          <Radio.Button key={i} value={gender.key}>
                            {gender.value}
                          </Radio.Button>
                        ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>

                <Divider orientation="left" orientationMargin="0">
                  Basic Details
                </Divider>
                <Form.Item
                  label="Connector Name"
                  name="contactName"
                  rules={[
                    { required: true, message: "Please enter Connector Name as per PAN card" },
                    {
                      pattern: CONNECTOR_NAME_REGEX,
                      message: "Plesae enter valid name"
                    },
                  ]}
                >
                  <Input
                    className="custom-input"
                    placeholder="Please enter Connector Name as per PAN card"
                  />
                </Form.Item>
                <Form.Item
                  label="Contact/Brand Name"
                  name="connectorContactBrandName"
                >
                  <Input className="custom-input"
                   placeholder="Please enter Contact/Brand Name"
                  />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: false, message: "Please enter Email" },
                    { type: "email", message: "Plesae enter valid Email" },
                  ]}
                >
                  <Input
                    className="custom-input"
                    placeholder="Please enter Email"
                  />
                </Form.Item>

                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    { required: true, message: "Please enter mobile number" },
                    {
                      pattern: PHONE_NUMBER_REGEX,
                      message: "Please enter valid number",
                    },
                  ]}
                >
                  <Input
                    style={{borderRadius:'10px', height:'38px'}}
                    prefix={"+91"}
                    maxLength={10}
                    placeholder="Please enter Phone Number"
                  />
                </Form.Item>

                <Divider orientation="left" orientationMargin="0">
                  Additional Details
                </Divider>
                <Form.Item
                  label="PAN"
                  name="pan"
                  rules={[
                    { required: false, message: "Please enter PAN Number" },
                    {
                      pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                      message: "Please enter valid PAN Number",
                    },
                  ]}
                >
                  <Input
                    className="custom-input"
                    onInput={toInputUppercase}
                    onChange={debounce(checkPanDetails, 1000)}
                    placeholder="Please enter valid PAN Number"
                  />
                </Form.Item>

                <Form.Item
                  label="Company Name"
                  name="companyName"
                  rules={[
                    { required: false, message: "Please enter Company Name" },
                    {
                      pattern: /^[A-Za-z\\s ]+$/,
                      message: "Plesae enter valid Company Name",
                    },
                  ]}
                >
                  <Input
                    className="custom-input"
                    placeholder="Please enter Company Name"
                  />
                </Form.Item>
              </Col>
              <Divider className="custome-devider-hozntl" type="vertical" />
              <Col span={11}>
                <Divider orientation="left" orientationMargin="0">
                  GSTIN
                </Divider>
                <Form.Item
                  label="GSTIN"
                  name="gstIn"
                  rules={[
                    { required: false, message: "Please enter GSTIN" },
                    {
                      pattern: GSTIN_REGEX,
                      message: "Invaid GSTIN provided, please enter again",
                    },
                  ]}
                >
                  <Input
                    className="custom-input"
                    placeholder="Please enter GSTIN"
                  />
                </Form.Item>

                <Divider orientation="left" orientationMargin="0">
                  Current Address
                </Divider>
                <Form.Item
                  label="Address Line 1"
                  name="address1"
                  rules={[{ required: false, message: "Please enter Address" }]}
                >
                  <Input
                    className="custom-input"
                    placeholder="Please enter Address Line 1"
                  />
                </Form.Item>
                <Form.Item
                  label="Pin Code"
                  name="pincode"
                  rules={[
                    { required: false, message: "Please enter Pin Code" },
                    {
                      pattern: /^[1-9][0-9]{5}$/,
                      message: "Please enter valid Pin Code",
                    },
                  ]}
                >
                  <Input
                    className="custom-input"
                    onChange={debounce(checkPincode, 1000)}
                    placeholder="Please enter Pin Code"
                  />
                </Form.Item>

                <Form.Item
                  label="City"
                  name="city"
                  rules={[{ required: false, message: "Please enter City" }]}
                >
                  <Input
                    className="custom-input"
                    placeholder="Please enter City"
                  />
                </Form.Item>

                <Form.Item
                  label="State"
                  name="state"
                  rules={[{ required: false, message: "Please enter State" }]}
                >
                  <Input
                    className="custom-input"
                    placeholder="Please enter State"
                  />
                </Form.Item>

                <Form.Item
                  label="Comment"
                  name="comment"
                  rules={[{ required: false, message: "Please enter comment" }]}
                >
                  <TextArea
                    className="textarea-comment"
                    rows={3}
                    placeholder="Please enter comment"
                    maxLength={500}
                    showCount
                  />
                </Form.Item>

                <Form.Item
                  className="field-bg"
                  label="Assign To"
                  name="assignedTo"
                  rules={[{ required: false, message: "please Assign To" }]}
                >
                  <AutoComplete
                    className="custom-auto-compleat"
                    onSearch={debounce(searchAssignTo, 1000)}
                  >
                    {assignToData.map((item: any) => (
                      <Select.Option key={item.id} value={item.fullName}>
                        <Row gutter={[16, 16]} className="assign-list">
                          <Col className="left" span={16}>
                            <span className="name">{item.fullName}</span><br />
                            <span className="city">{item?.address?.city && item?.address?.city}</span>
                          </Col>
                          <Col className="right" span={8}>
                            <span className="mob">{item?.phoneNumber && item?.phoneNumber}</span>
                          </Col>
                        </Row>
                      </Select.Option>
                    ))}
                  </AutoComplete>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Divider />
              <Col offset={21}>
                <Form.Item>
                  <Button
                    className="dynamic-btn-primary"
                    style={btnPrimaryStyles}
                    size="large"
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      {isLoading &&
        <div>
          <LoaderOverlay size="large" loading={isLoading} />
        </div>
      }
    </>
  );
};

export default AddConnectors;
