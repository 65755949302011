import React from "react";
import "./BoostProduction.scss";

const BoostProduction = () => {
  return (
    <div className="boost-wrapper">
      <h1 className="boost-title">Boost Productivity, Boost Profits</h1>
      <div className="boost-container">
        <div className="boost-content">
          <div className="boost-left">
            <div className="boost-left-container">
              <span className="one-platform">One Platform For</span>
              <h2 className="banner-text">
                <br />
                <span className="highlight-blue">All Your</span>
                <br />
                <span className="highlight">Distribution</span>
                <br />
                <span className="highlight-blue">Needs.</span>
              </h2>
              <button className="boost-button">Try OneFlo for Free →</button>
            </div>
          </div>
          <div className="boost-right">
            <ul>
              <li><div className="dot" />Submit cases to multiple lenders in one click</li>
              <li><div className="dot" />Fetch bureau analysis instantly</li>
              <li><div className="dot" />Analyze Bank Statements in real time</li>
              <li><div className="dot" />Check Eligibility for 125+ lenders</li>
              <li><div className="dot" />Collect Documents via WhatsApp and E-mail</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoostProduction;
