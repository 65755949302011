import { Row, Typography, Button, Col, Tooltip, Collapse, notification, List, Form, InputNumber, Select, DatePicker, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { numberWithCommas } from "shared/services/Utility";
import DisbursedGroup from "./DisbursedGroup";
import "./index.scss";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { ReactComponent as IconAddRound } from "../../../../../assets/images/add_round.svg";
import AddDisbursed from "./AddDisbursed";

const { Title } = Typography;
const { Panel } = Collapse;

const Disbursed: React.FunctionComponent<any> = ({
  setIsLoading,
  lender,
  lenderIndex,
  onActionSubmit,
  leadData,
  getRedirectURLLink,
  loadingUpdate,
  getDownloadLetterForICICI,
}) => {
  const [accountTypes, setAccountTypes] = useState([] as any);
  const [finalLender, setFinalLander] = useState({} as any);
  const [totalDisbursedAmount, setTotalDisbursedAmount] = useState(0);
  const { appColors, isMobile }: any = useSelector((state: RootState) => state.common);
  const [isAddCheck, setIsAddCheck] = useState(false);
  const [isAddClick, setIsAddClick]: any = useState();
  const [addBtnErr, setAddBtnErr] = useState("");

  useEffect(() => {
    setEnumValues();
  }, []);
  useEffect(() => {
    // lender?.disbursalLenderInfoDetails?.map((amount) => {
    //   amount.lenderAmount.isDisabled = amount.lenderAmount.disbursedAmount > 0;
    // });
    setIsAddClick((lender?.disbursalLenderInfoDetails?.length ?? 0) == 0)
    setFinalLander(lender);
    calcTotalDisbursedAmount(lender?.disbursalLenderInfoDetails);
    return () => {
      if (lender?.disbursalLenderInfoDetails?.length > 0) {
        setIsAddClick(true);
      } else {
        setIsAddClick(false);
        setIsAddCheck(false);
      }
    }
  }, [lender]);

  const setEnumValues = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let accountType = enumValues.find(
        (item: any) => item.propertyName == "ACCOUNT_TYPE"
      );
      if (accountType) {
        setAccountTypes(accountType.keyAndDisplayValueList);
      }
    }
  };

  const isAddEneble = (item) => {
    let amountDisbursed = item.disbursalLenderInfoDetails.map((item) =>
      Number(item.lenderAmount.disbursedAmount)
    );
    let amountInsurance = item.disbursalLenderInfoDetails.map((item) =>
      Number(item.lenderAmount.insuranceAmount)
    );
    const totalDisbursed = amountDisbursed.reduce((a, b) => a + b, 0);
    // const totalInsurance = amountInsurance.reduce((a, b) => a + b, 0);
    const total = totalDisbursed;
    return total < item.sanctionedAmount;
  };

  const setAddButton = (isHidden, lender, lenderIndex, index) => {
    const customStylesBtnPrimary: any = { '--btnColor': appColors?.appPrimaryColor ?? "", borderRadius: "8px", marginTop: "5px" };

    return (
      <Tooltip
        title={isAddEneble(lender) ? "" : "You disbursed all sanctioned amount"}
      >
        <Button
          className={isMobile ? 'mobile-view-icon-btn' : 'dynamic-btn-primary'}
          style={customStylesBtnPrimary}
          type="primary"
          size="large"
          hidden={isHidden}
          icon={isMobile ? <IconAddRound /> : <FaPlus style={{ marginRight: "5px" }} />}
          onClick={(event) => {
            event.stopPropagation();
            if (!isAddEneble(lender)) {
              return;
            }
            if (isAddCheck) {
              return notification.error({ message: addBtnErr })
            }
            setIsAddClick(true);
            const lenderInfo = {
              disbursedId: null,
              lenderAmount: {
                disbursedAmount: null,
                insuranceAmount: 0,
                payoutPercentage:
                  leadData?.caseDetails?.connectorDetails?.payoutType == "FIXED"
                    ? leadData?.caseDetails?.connectorDetails?.payoutPercentage
                    : leadData?.sourceOfCase == "CONNECTOR"
                      ? leadData?.caseDetails?.loanDetails?.expectedInterestRate
                      : null,
              },
            };
            const tmpLender = { ...finalLender };
            const disburselLenderInfo = [
              ...tmpLender.disbursalLenderInfoDetails,
            ];
            disburselLenderInfo.unshift(lenderInfo);
            tmpLender.disbursalLenderInfoDetails = disburselLenderInfo;

            if ((tmpLender?.payoutDetails?.length ?? 0) > 0) {
              const tmpPayoutDetails = tmpLender.payoutDetails?.map(
                (payoutDetail) => {
                  const tmpPayoutDetail = payoutDetail?.map((detail) => {
                    const tmpDetail = { ...detail };
                    tmpDetail.disbursalGroup = `${index + 1}`;
                    tmpDetail.accountType = "";
                    tmpDetail.paymentType = null;
                    tmpDetail.payoutAmount = "";
                    tmpDetail.payoutId = null;
                    return tmpDetail;
                  });
                  return tmpPayoutDetail;
                }
              );
              const payoutDetails = [...tmpLender.payoutDetails];
              if (tmpPayoutDetails[0]) {
                payoutDetails.push(tmpPayoutDetails[0]);
              }
              tmpLender.payoutDetails = payoutDetails;
            }
            setFinalLander(tmpLender);
          }}
        >
          {isMobile ? '' : 'Add Disbursal'}
        </Button>
      </Tooltip>
    );
  };

  const calcTotalDisbursedAmount = (disbursalLenderInfoDetails) => {
    let amountDisbursed = disbursalLenderInfoDetails?.map((item) =>
      Number(item.lenderAmount.disbursedAmount)
    );
    let amountInsurance = disbursalLenderInfoDetails?.map((item) =>
      Number(item.lenderAmount.insuranceAmount)
    );
    const totalDisbursed = amountDisbursed?.reduce((a, b) => a + b, 0);
    // const totalInsurance = amountInsurance?.reduce((a, b) => a + b, 0);
    const total = totalDisbursed;
    setTotalDisbursedAmount(isNaN(total) ? 0 : total);
  };

  const renderHeader = (isHidden?: any, index?: number) => (
    <>
      {/* {!isHidden && ( */}
      <Row className="disbursed-lenders-header">
        <Col span={5} className="disbursed-heading">
          <Title level={5}>Loan Details</Title>
          {/* <Title level={5}>{lender?.lenderName}</Title>
          {lender?.submissionType?.includes("API") &&
            (lender?.lenderApplicationId?.length ?? 0) > 0 && (
              <span style={{ fontSize: "11px", float: "left" }}>
                {`Lender loan ID: ${lender.lenderApplicationId}`}{" "}
                <img
                  src={copy}
                  alt=""
                  width={11}
                  onClick={() =>
                    navigator.clipboard.writeText(lender.lenderApplicationId)
                  }
                />
              </span>
            )} */}
        </Col>
        <Col span={5} xs={6} lg={5} className="disbursed-heading">
          Sanctioned Amount
          <Title level={5}>
            {lender?.sanctionedAmount &&
              numberWithCommas(lender?.sanctionedAmount)}
          </Title>
        </Col>
        <Col span={5} xs={6} lg={5} className="disbursed-heading">
          Disbursed Amount
          <Title level={5}>
            {totalDisbursedAmount && numberWithCommas(totalDisbursedAmount)}
          </Title>
        </Col>
        <Col span={4} className="disbursed-heading">
          Interest Rate
          <Title level={5}>{lender?.interestRate}%</Title>
        </Col>
        {["SANCTIONED", "DISBURSED"].includes(lender?.status) && (
          <Col xl={5} lg={6} md={6} sm={7}>
            {setAddButton(isHidden, lender, lenderIndex, index)}
          </Col>
        )}
      </Row>
      {/* )} */}
    </>
  );


  const renderAddDisbursal = () => (
    <AddDisbursed
      setIsLoading={setIsLoading}
      finalLender={finalLender}
      setIsAddClick={setIsAddClick}
      calcTotalDisbursedAmount={calcTotalDisbursedAmount}
      leadData={leadData}
      onActionSubmit={(newLander, comment) => {
        lender = { ...newLander };
        onActionSubmit(newLander, comment);
      }}
      setFinalLander={setFinalLander}
      setAddBtnErr={setAddBtnErr}
      setIsAddCheck={setIsAddCheck}
    />
  )

  return (
    <div style={{ marginTop: "16px" }}>
      <Collapse
        className="disbursed-collapse"
        collapsible="header"
        expandIconPosition="end"
        activeKey={"index"}
      >
        <Panel showArrow={false} key={"index"} header={renderHeader(false, 1)}>
          {(isAddClick) ? renderAddDisbursal() : (
            <>
              {((lender?.status == "SANCTIONED" || lender?.status == "DISBURSED") && finalLender.disbursalLenderInfoDetails?.length > 0) ? (
                <>
                  {lender?.disbursalLenderInfoDetails?.map((payment, index) => {
                    const isHidden = index !== 0;
                    return (
                      <DisbursedGroup
                        setIsLoading={setIsLoading}
                        setIsAddClick={setIsAddClick}
                        lender={finalLender}
                        setIsAddDisabled={setIsAddCheck}
                        setAddBtnErr={setAddBtnErr}
                        leadData={leadData}
                        lenderIndex={lenderIndex}
                        getRedirectURLLink={getRedirectURLLink}
                        payment={payment}
                        index={index}
                        payoutDetailIndex={index}
                        payoutType
                        loadingUpdate={loadingUpdate}
                        getDownloadLetterForICICI={getDownloadLetterForICICI}
                        onAmountChange={() => {
                          calcTotalDisbursedAmount(
                            finalLender?.disbursalLenderInfoDetails
                          );
                        }}
                        onActionSubmit={(newLander, comment) => {
                          lender = { ...newLander };
                          onActionSubmit(newLander, comment);
                        }}
                      />
                    );
                  })}
                </>
              ) : ('')}
            </>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default Disbursed;
