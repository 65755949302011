import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Typography,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect, useState } from "react";
import {ReactComponent as FileBorder} from "../../assets/images/file-border.svg";
import {ReactComponent as AddFileGray} from "../../assets/images/upload-file-gray.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import type { UploadProps } from "antd/es/upload/interface";
import { MdDelete } from "react-icons/md";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { Title } = Typography;

const EditNotice: React.FC = () => {
  const history = useHistory();

  const [fileList, setFileList] = useState([] as any);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [uploadedFile, setUploadedFile] = useState(null as any);
  const [leadData, setLeadData] = useState(null as any);
  const [deleteImage, setImage1] = useState(false);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const uploadProps: UploadProps = {
    listType: "text",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    // beforeUpload: (file) => {
    //   setFileList([...fileList, file]);
    //   return false;
    // },
    fileList,
  };

  const beforeUpload = (file) => {
    if (file.size / 1024 / 1024 > 10.0) {
      notification.error({
        message: "Please upload .pdf file with size upto 10 MBs only",
      });
      setFileList(null);
      return;
    }
    let uploadExt = ["application/pdf"];
    if (!uploadExt.includes(file.type)) {
      // notification.error({message: 'Please upload .pdf only'});
      setFileList(null);
      return;
    } else {
      setFileList([file]);
      return false;
    }
  };

  let { id } = useParams() as any;

  if (!id) {
    history.goBack();
  }

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Notices",
        backScreenPath: "/notices",
        screenTitle: "Edit Notice",
      })
    );
    getLeadById();
  }, []);

  const [form] = Form.useForm();
  const getLeadById = () => {
    // setLoading(true)
    API_SERVICE.getBusinessid(id)
      .then(({ data }) => {
        if (data?.payload) {
          setLeadData(data.payload);
          setImage1(true);
          if (data.payload) {
            form.setFieldsValue({
              title: data.payload.title,
              description: data.payload.description,
            });
            // setDesc(data.payload.description.toString())
            // setTitle(data.payload.title)
          }

          if (data?.payload?.caseDetails?.caseHistoryHolder) {
            // setLeadHistory(data.payload.caseDetails.caseHistoryHolder)
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const uploadDocumentHandler = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    API_SERVICE.fileUpload(formData)
      .then(({ data }) => {
        if (data) {
          setImageUrl(data);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onUpload = useCallback((acceptedFiles: any) => {
    let uploadExt = ["application/pdf"];
    if (!uploadExt.includes(acceptedFiles?.file?.type)) {
      notification.error({ message: "Please upload pdf only" });
      setFileList(null);
      return;
    }
    setUploadedFile(acceptedFiles.file);
    uploadDocumentHandler(acceptedFiles.file);
  }, []);

  const createPayload = (formData: any) => {
    let userData = localStorage.getItem("user") as any;
    if (userData) {
      userData = JSON.parse(userData);
    }
    const data = {
      type: "NOTICE",
      title: formData.title,
      description: formData.description,
      active: leadData?.active,
    };
    return data;
  };
  const createPayload1 = (formData: any) => {
    let userData = localStorage.getItem("user") as any;
    if (userData) {
      userData = JSON.parse(userData);
    }
    const data = {
      type: "NOTICE",
      title: formData.title,
      description: formData.description,
      active: leadData?.active,
      documentDetail: [
        {
          documentName: imageUrl ? uploadedFile.name : null,
          documentURL: imageUrl ? imageUrl : null,
        },
      ],
    };
    return data;
  };

  const onFinish = (values: any) => {
    if (!deleteImage && fileList && fileList.length <= 0) {
      notification.error({ message: "Please upload pdf" });
      return;
    }
    const payload = imageUrl ? createPayload1(values) : createPayload(values);
    console.log("payload",payload);
    API_SERVICE.updateOffers(payload, id)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/notices");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onDelete = (doc: any) => {
    if ((doc?.length ?? 0) == 0) {
      return;
    }

    API_SERVICE.deleteDocument(doc[0].businessId, doc[0].businessDocumentId)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setImage1(false);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const document =
    (leadData?.documentDetails?.length ?? 0) > 0
      ? leadData.documentDetails[0]
      : {};

      const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
      const customStyles: any = {'--fileIconColor': appColors?.appPrimaryColor ?? ""};

  return (
    <div className="content-box">
      <Row>
        <Col span={10} style={{ marginTop: "2%" }}>
          <label>
            <span style={{ color: "red" }}>*</span> Please upload (.pdf only)
          </label>
          <Form.Item name="uploadPdf">
            <Upload
              className="upload-wrapper"
              listType="picture"
              accept=".pdf"
              onChange={onUpload}
              {...uploadProps}
              maxCount={1}
              beforeUpload={beforeUpload}
              disabled={deleteImage}
            >
              <AddFileGray />
              Upload Attachement
            </Upload>
          </Form.Item>
          {deleteImage ? (
            <div className="upload-document-wrapper">
              <Row>
                <div className="doc-list">
                  <ul>
                    <li>
                      <div className="doc-details">
                        <FileBorder style={customStyles}/>
                        <span>
                          {document?.documentName}
                          <br />
                          <span className="type">{document?.documentType}</span>
                        </span>
                      </div>
                      <div className="doc-action">
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to delete?"
                          onConfirm={() => onDelete(leadData.documentDetails)}
                          // onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                          className="remove"
                          okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
                          cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
                        >
                          <MdDelete />
                          Remove
                        </Popconfirm>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <Button type="text" className='upload-btn'  icon={<FaFileMedical />}>
                onClick={() => setUploadDocument(true)}
                    Upload New document
                </Button> */}
              </Row>
            </div>
          ) : null}
        </Col>
      </Row>
      <Form
        layout="vertical"
        initialValues={{
          phoneCode: "+91",
          profession: "Builder",
          gender: "M",
        }}
        form={form}
        autoComplete="off"
        name="leadForm"
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter Title",
                },
              ]}
            >
              <Input
                className="custom-input"
                placeholder="Please enter Title"
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter Description",
                },
              ]}
            >
              <TextArea
                className="custom-textarea"
                rows={8}
                placeholder="Please enter Description"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* <Divider/> */}
          <Col>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditNotice;
