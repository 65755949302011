import React from "react";
import { ReactComponent as ImgGoDigital } from "../../../assets/images/lending_page/pageSolution/goDigital.svg";
import { ReactComponent as ImgCRM } from "../../../assets/images/lending_page/pageSolution/crm.svg";
import { ReactComponent as ImgDataAnalytic } from "../../../assets/images/lending_page/pageSolution/dataAnalytic.svg";
import FeatureItem from "./FeatureItem";
import "./Footer.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const Solutions: React.FC = () => {
  const { isMobile }: any = useSelector((state: RootState) => state.common);

  const solutionList = [
    {
      title: "Go Digital",
      subTitle: "",
      desc: [
        "D2C journey on your website/app",
        "APIs integrated with D2C journey",
        "One tool to run campaigns across all platforms",
      ],
      image: <ImgGoDigital />,
    },
    {
      title: "CRM",
      subTitle: "One window to connect to all BFSI",
      desc: [
        "50+ Lender APIs, 20+ Insurance APIs & Wealth APIs",
        "Connect APIs in hours, use your code",
        "No API integration cost, pay as you use",
      ],
      image: <ImgCRM />,
    },
    {
      title: "Data Analytics",
      subTitle: "",
      desc: [
        "Segment customers to understand product needs",
        "Create customer cohorts for sharp targeting",
        "Improve customer conversions by upto 500%",
      ],
      image: <ImgDataAnalytic />,
    },
  ];

  return (
    <div className="">
      {solutionList.map((item, idx) => {
        return <FeatureItem feature={item} isImageLeft={isMobile ? false : (idx % 2 != 0)} />;
      })}
    </div>
  );
};

export default Solutions;
