import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, notification, Row, Typography } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import API_SERVICE from "shared/services/api-service";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { Title } = Typography;

const AddDialler: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const numericId = Number(id); 
  const [form] = Form.useForm();
  const { appColors }: any = useSelector((state: RootState) => state.common);

  const [leadData, setLeadData] = useState<any>(null);

  useEffect(() => {
    if (id) {
      dispatch(
        setScreenHeader({
          backScreenTitle: "Dialler Configuration",
          backScreenPath: "/dialler",
          screenTitle: "Edit Campaign",
        })
      );
      fetchDiallerById(id);
    } else {
      dispatch(
        setScreenHeader({
          backScreenTitle: "Dialler Configuration",
          backScreenPath: "/dialler",
          screenTitle: "Add Campaign",
        })
      );
    }
  }, [id]);

  const fetchDiallerById = (id) => {
    // if (!numericId) return;

    API_SERVICE.getDiallerid(id)
      .then(({ data }) => {
        if (data?.payload) {
          setLeadData(data.payload);
          form.setFieldsValue({
            campaignName: data.payload.campaignName,
            endpoint: data.payload.endpoint,
          });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const createPayload = (formData: any) => ({
    campaignName: formData.campaignName,
    endpoint: formData.endpoint,
  });

  const onFinish = (values: any) => {
    const payload = createPayload(values);

    const apiCall = id
      ? API_SERVICE.updateDialler(payload, id)
      : API_SERVICE.addDialler(payload);

    apiCall
      .then(({ data }) => {
        if (data) {
          notification.success({
            message: id ? "Campaign updated successfully" : "New Campaign added successfully",
          });
          history.push("/dialler");
        }
      })
      .catch((e: any) => {
        const errorStatus  = e?.response?.status;
        if(errorStatus===400)
        {
          const showMessage = e?.response?.data?.payload?.campaignName ? e?.response?.data?.payload?.campaignName : e?.response?.data?.payload?.endpoint;
          notification.error({ message: showMessage});

        }else{
          notification.error({ message: API_SERVICE.handleErrors(e) });
        }
      });
  };

  const btnPrimaryStyles: any = { "--btnColor": appColors?.appPrimaryColor ?? "" };

  return (
    <div className="content-box" style={{ padding: "20px" }}>
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <Form.Item
              label="Campaign Name"
              name="campaignName"
              rules={[
                {
                  required: true,
                  message: "Please enter Campaign Name",
                  whitespace:true,
                },
              ]}
            >
              <Input
                className="custom-input"
                placeholder="Please enter Campaign Name"
              />
            </Form.Item>

            <Form.Item
              label="Endpoint"
              name="endpoint"
              rules={[
                {
                  required: true,
                  message: "Please enter Endpoint",
                  whitespace:true,
                },
              ]}
            >
              <Input
                className="custom-input"
                placeholder="Please enter Endpoint"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10} style={{ marginTop: "1%" }}>
            <Form.Item>
              {!id && (
                <Button
                  size="large"
                  htmlType="button"
                  onClick={() => form.resetFields()}
                  style={{ marginRight: "3%" }}
                >
                  Clear
                </Button>
              )}
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="dynamic-btn-primary"
                style={btnPrimaryStyles}
              >
                {id ? "Update" : "Submit"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddDialler;