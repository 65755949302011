import React, { useEffect, useState } from "react";
import {
  Input,
  Space,
  Button,
  Row,
  Col,
  Table,
  Modal,
  Form,
  notification,
  Popconfirm,
} from "antd";
import { FaPlus } from "react-icons/fa";
import "./index.scss";
import API_SERVICE from "shared/services/api-service";
import { useHistory } from "react-router-dom";
import { hasUserRole } from "shared/services/Utility";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import {ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";
import {ReactComponent as IconDeleteBorder} from "../../assets/images/delete_border.svg";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

type Props = {};
const userRolePermission = hasUserRole();

const Department: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isFilterVisible1, setIsFilterVisible1] = useState(false);
  // const [allLeads, setAllLeads] = useState([]);
  const [allConnectors, setAllConnectors] = useState([]);
  const [allConnectors1, setAllConnectors1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState([]);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();
  const [addConnectorForm] = Form.useForm() as any;

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Manage Departments"}));
    getAllDepartments();
  }, []);

  const handleDelete = (key: any) => {
    const newData = allConnectors.filter((item) => item !== key);
    API_SERVICE.addDepartment(newData)
      .then(({ data }) => {
        if (data) {
          // notification.success({ message: 'Department Deleted Successfully'});
          notification.success({ message: data.message });
          setIsFilterVisible(false);
          setAllConnectors(data.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleDelete1 = (key: any) => {
    const newData = allConnectors.filter((item) => item !== key);
    setAllConnectors1(newData);
    // API_SERVICE.addDepartment(newData)
    // .then(({ data }) => {
    //   setAllConnectors1(data.payload)
    // })
    // .catch((e: any) => {
    //   notification.error({ message: API_SERVICE.handleErrors(e) });
    // });
  };

  const edit = (record: any) => {
    addConnectorForm.setFieldsValue({
      departmentname: record,
    });
    setValue(record);
    setValue1(record);
    handleDelete1(record);
    setIsFilterVisible1(true);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "",
      key: "productType",
      editable: true,
    },
    {
      title: "Action",
      // key: "action",
      editable: true,
      key: "",
      render: (_: any, record: any) =>
        allConnectors.length >= 1 ? (
          <>
            <Space
              className="action-button"
              size="small"
              style={{ marginLeft: "1%" }}
            >
              <Button
            className="custome-btn-icon-secondary"
            style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
            size="small"
            type="text"
            icon={<IconEditBorder />}
                onClick={() => edit(_)}
                hidden={
                  !(
                    userRolePermission["UpdateUser"] ||
                    userRolePermission["Admin"]
                  )
                }
              />
            </Space>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => handleDelete(_)}
              okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
              cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
            >
              {" "}
              <Button
                style={{ backgroundColor: "transparent", border: "none" }}
                size="small"
                type="text"
                icon={<IconDeleteBorder width={24} height={24} />}
                hidden={
                  !(
                    userRolePermission["UpdateUser"] ||
                    userRolePermission["Admin"]
                  )
                }
              />
            </Popconfirm>
          </>
        ) : null,
    },
  ];

  const getAllDepartments = () => {
    setLoading(true);
    API_SERVICE.getDepartments()
      .then(({ data }) => {
        if (data.payload.length > 0) {
          setAllConnectors(data.payload);
        } else {
          notification.error({ message: "No department found." });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilterModal = () => {
    setIsFilterVisible(true);
  };

  const handleApplyFilter = () => { };

  const onFilterSubmit1 = (event: any) => {
    if (event.departmentname == null) {
      const formData = new FormData();
      formData.append(`slides`, value);
      let newArray;
      newArray = [...allConnectors1, formData.getAll("slides").toString()];
      const payload = newArray;
      API_SERVICE.addDepartment(payload)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data.message });
            setIsFilterVisible1(false);
            setAllConnectors(data.payload);
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    } else {
      const formData = new FormData();
      formData.append(`slides`, event.departmentname.trim());
      let newArray;
      newArray = [...allConnectors1, formData.getAll("slides").toString()];
      const payload = newArray;
      API_SERVICE.addDepartment(payload)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data.message });
            setIsFilterVisible1(false);
            setAllConnectors(data.payload);
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const onFilterSubmit = (event: any) => {
    const formData = new FormData();
    formData.append(`slides`, event.departmentname.trim());
    let newArray;
    newArray = [...allConnectors, formData.getAll("slides").toString()];
    const payload = newArray;
    API_SERVICE.addDepartment(payload)
      .then(({ data }) => {
        if (data) {
          onReset()
          notification.success({ message: data.message });
          setIsFilterVisible(false);
          setAllConnectors(data.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
  };

  const filterCancel = () => {
    setIsFilterVisible(false);
    onReset();
  };

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",width: "170px !important"};

  return (
    <div className="content-box">
      {isFilterVisible && (
        <Modal
          title="Add New Department"
          centered
          width={700}
          onCancel={filterCancel}
          // onCancel={() => setIsFilterVisible(false)}
          footer={null}
          visible={isFilterVisible}
        >
          <AddDepartment
            onSubmitFilter={onFilterSubmit}
            onReset={onReset}
            form={form}
          />
        </Modal>
      )}
      {isFilterVisible1 && (
        <Modal
          title="Edit Department"
          centered
          width={700}
          onCancel={() => setIsFilterVisible1(false)}
          footer={null}
          visible={isFilterVisible1}
        >
          <AddDepartment1 onSubmitFilter1={onFilterSubmit1} value1={value} form1={addConnectorForm} />
        </Modal>
      )}
      <Row>
        <Col className="filter-bar" span={16}>
        </Col>
        <Col span={8} className="filter-bar filter-bar-right">
          <Button
            style={{ marginLeft: "70px" }}
            type="primary"
            className="refresh-button"
            onClick={() => {
              getAllDepartments();
            }}
            shape="round"
            //icon={<ReloadOutlined />}
            size={"middle"}
          ><img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}}/>
          </Button>
          <Button
            //style={{ width: "170px !important" }}
            type="primary"
            className="add-button1 dynamic-btn-primary"
            style={btnPrimaryStyles}
            onClick={handleFilterModal}
            shape="round"
            icon={<FaPlus />}
            size={"middle"}
            disabled={!(userRolePermission["Admin"] ||  userRolePermission["CreateUser"])}
            hidden={
              !(
                userRolePermission["CreateUser"] || userRolePermission["Admin"]
              )
            }
          >
            Add Department
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={allConnectors}
            size="small"
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Department;

export const AddDepartment: React.FunctionComponent<any> = ({
  onSubmitFilter,
  form,
  onReset,
}) => {

  const {appColors}: any = useSelector((state: RootState) => state.common);

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <Form
      form={form}
      name="basic"
      size="large"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmitFilter}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="Name"
            name="departmentname"
            rules={[{ required: true, message: "Please Enter Name" }]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: "right" }}>
          <br />
          <Form.Item>
            <Space>
              <Button htmlType="button" onClick={onReset} className="dynamic-btn-default" style={btnDefaultBorderStyle}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Add
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <br />
    </Form>
  );
};

export const AddDepartment1: React.FunctionComponent<any> = ({
  onSubmitFilter1,
  value1, form1
}) => {
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  return (
    <Form
      name="basic"
      size="large"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmitFilter1}
      form={form1}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="Name"
            name="departmentname"
            rules={[{ required: true, message: "Please Enter Name" }]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: "right" }}>
          <br />
          <Form.Item>
            <Space>
              {/* <Button htmlType="button">Clear</Button> */}
              <Button type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <br />
    </Form>
  );
};

function setName(value: string) {
  throw new Error("Function not implemented.");
}
