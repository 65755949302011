import React, { useEffect, useState } from "react";
import {
  Typography,
  Input,
  Space,
  Button,
  Radio,
  Row,
  Col,
  Table,
  Modal,
  Select,
  Form,
  notification,
  Popconfirm,
} from "antd";
import { FaSearch, FaFilter, FaPlus, FaEye, FaPen } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import type { ColumnsType } from 'antd/es/table';

import "./index.scss";
import API_SERVICE from "shared/services/api-service";
import { useHistory } from "react-router-dom";
import { hasUserRole } from "shared/services/Utility";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import {ReactComponent as IconEyeBorder} from "../../assets/images/eye_border.svg";
import {ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";
import {ReactComponent as IconDeleteBorder} from "../../assets/images/delete_border.svg";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { Option } = Select;
const { Title } = Typography;
type Props = {};

const Location: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();

  const history = useHistory();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [allLeads, setAllLeads] = useState([]);
  const [allConnectors, setAllConnectors] = useState([]);
  const [allConnectors1, setAllConnectors1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFilterVisible1, setIsFilterVisible1] = useState(false);
  const [value, setValue] = useState();
  const [office, setoffice] = useState("");
  const [pin, setpin] = useState("");
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Manage Locations"}));
  }, []);

  const handleDelete1 = (key: any) => {
    const newData = allConnectors.filter((item) => item !== key)
    setAllConnectors1(newData);
  };

  const handleDelete = (key: any) => {
    const newData = allConnectors.filter((item) => item !== key);
    var newData1 = [...allConnectors, newData];
    const payload = newData;
    API_SERVICE.addLocations(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setIsFilterVisible(false);
          setAllConnectors(data.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const edit = (record: any) => {
    addConnectorForm.setFieldsValue({
      officeName: record.officeName,
      pinCode: record.pinCode,
    });
    setValue(record);
    setoffice(record.officeName);
    setpin(record.pinCode);
    // setValue1(record)
    handleDelete1(record);
    setIsFilterVisible1(true);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "officeName",
      key: "productType",
    },
    {
      title: "Pin Code",
      dataIndex: "pinCode",
      key: "productType",
    },
    {
      title: "Action",
      key: "action",
      fixed: 'right',
      align: "center",
      width: 100,
      render: (_: any, record: any) => (
        <Space className="action-button" size="small">
          <Space
            className="action-button"
            size="middle"
            style={{ marginLeft: "1%" }}
          >
            <Button
            className="custome-btn-icon-secondary"
            style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
            size="small"
            type="text"
            icon={<IconEditBorder />}
              onClick={() => edit(_)}
              hidden={
                !(
                  userRolePermission["UpdateUser"] ||
                  userRolePermission["Admin"]
                )
              }
            />
          </Space>
          <Popconfirm title="Are you sure to delete?" onConfirm={() => handleDelete(_)}
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
            >
               <Button
              style={{backgroundColor:"transparent", border:'none'}}
              size="small"
              type="text"
                icon={<IconDeleteBorder width={24} height={24}/>}
                hidden={
                  !(
                    userRolePermission["UpdateUser"] ||
                    userRolePermission["Admin"]
                  )
                }
              // disabled={editingKey !== ''}
              />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getAllLocations();
  }, []);

  const getAllLocations = () => {
    setLoading(true);
    API_SERVICE.getLocations()
      .then(({ data }) => {
        if (data.payload.length > 0) {
          setAllConnectors(data.payload);
        } else {
          notification.error({ message: "No Locations found." });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilterModal = () => {
    setIsFilterVisible(true);
  };

  const filterCancel = () => {
    setIsFilterVisible(false);
    onReset();
  };
 
  const onFilterSubmit = (event: any) => {
    const data = {
      officeName: event?.officeName?.trim(),
      pinCode: event?.pinCode,
    }
    var newData = [...allConnectors, data];
    
    const payload = newData;
    API_SERVICE.addLocations(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setIsFilterVisible(false);
          setAllConnectors(data.payload);
          onReset();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const [addConnectorForm] = Form.useForm() as any;

  const onFilterSubmit1 = (event: any, value: { officeName: any }) => {
    var newData = [
      ...allConnectors1,
      { officeName: event.officeName.trim(), pinCode: event.pinCode },
    ];
    const payload = newData;
    API_SERVICE.addLocations(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setIsFilterVisible1(false);
          setAllConnectors(data.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });

  };

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
  };

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <div className="content-box">
      {isFilterVisible && (
        <Modal
          title="Add New Location"
          centered
          width={700}
          onCancel={filterCancel}
          footer={null}
          visible={isFilterVisible}
        >
          <AddLocations
            onSubmitFilter={onFilterSubmit}
            onReset={onReset}
            form={form}
          />
        </Modal>
      )}
      {isFilterVisible1 && (
        <Modal
          title="Edit Location"
          centered
          width={700}
          onCancel={() => setIsFilterVisible1(false)}
          footer={null}
          visible={isFilterVisible1}
        >
          <AddDepartment1 onSubmitFilter1={onFilterSubmit1} value1={value} form1={addConnectorForm} />
        </Modal>
      )}
      <Row>
        <Col className="filter-bar" span={16}>
        </Col>
        <Col span={8} className="filter-bar filter-bar-right">
          <Button
            style={{marginLeft: "90px"}}
            type="primary"
            className="refresh-button"
            onClick={() => {
              getAllLocations();
            }}
            shape="round"
            //icon={<ReloadOutlined />}
            size={"middle"}
          ><img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}}/>
          </Button>
          <Button
            type="primary"
            className="add-button dynamic-btn-primary"
            style={btnPrimaryStyles}
            onClick={handleFilterModal}
            shape="round"
            icon={<FaPlus />}
            size={"middle"}
            disabled={!(userRolePermission["Admin"] ||  userRolePermission["CreateUser"])}
            hidden={
              !(
                userRolePermission["CreateUser"] || userRolePermission["Admin"]
              )
            }
          >
            Add Location
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            columns={columns}
            scroll={{ x: 'max-content' }}
            dataSource={allConnectors}
            size="small"
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Location;

export const AddLocations: React.FunctionComponent<any> = ({
  onSubmitFilter,
  onReset,
  form,
}) => {
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};

  return (
    <Form
      form={form}
      name="basic"
      size="large"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmitFilter}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="Name"
            name="officeName"
            rules={[
              { required: true, message: "Please enter Name" },
              {
                pattern: /^[A-Za-z\\s ]+$/,
                message: "Plesae enter valid Name",
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Pin Code"
            name="pinCode"
            rules={[{ required: true, message: "Please enter pin code" },
            {
              pattern: /^[1-9][0-9]{5}$/,
              message: "Please enter valid pincode",
            },]}
          >
            <Input placeholder="Pin Code" size="large" />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: "right" }}>
          <br />
          <Form.Item>
            <Space>
              <Button htmlType="button" onClick={onReset} className="dynamic-btn-default" style={btnDefaultBorderStyle}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Add
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <br />
    </Form>
  );
};

export const AddDepartment1: React.FunctionComponent<any> = ({
  onSubmitFilter1,
  value1, form1
}) => {

  const {appColors}: any = useSelector((state: RootState) => state.common);

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <Form
      name="basic"
      size="large"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmitFilter1}
      form={form1}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="Name"
            name="officeName"
            rules={[{ required: true, message: "Please enter  Name" },
            {
              pattern: /^[A-Za-z\\s ]+$/,
              message: "Plesae enter valid  Name",
            },]}
          >
            <Input
              placeholder="Name"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Pin Code"
            name="pinCode"
            rules={[{ required: true, message: "Please enter pin code" },
            {
              pattern: /^[1-9][0-9]{5}$/,
              message: "Please enter valid pincode",
            },]}
          >
            <Input
              placeholder="Pin Code"
              size="large"
            />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: "right" }}>
          <br />
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <br />
    </Form>
  );
};
