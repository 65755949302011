import { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { notification, Card, Typography, Row, Col, Modal } from "antd";
import { useHistory } from "react-router-dom";
import LoginForm from "./LoginForm";

import "./Login.scss";
import { useTranslation } from "react-i18next";

import { AuthConsumer } from "shared/contexts/AuthContext";
import API_SERVICE from "shared/services/api-service";

import Logo from "assets/images/logo.png";
import { getUser } from "shared/services/Utility";

const { Title, Paragraph } = Typography;

interface Props {
  location: any;
}
export default function Login({ location }: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [fields, setFields] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [reCaptchaTokenForConfirmMobile, setReCaptchaTokenForConfirmMobile] = useState("");
    const [showCaptchaForConfirmMobile, setShowCaptchaForConfirmMobile] = useState(false);

  const [redirectLink, setRedirectURLLink] = useState();


  useEffect(() => {
    console.log("Location: ", location)
    const { from } = location.state ?? { from: { pathname: "/dashboard" } };
    setRedirectURLLink(from?.pathname + (from?.search ?? ""))
  }, [location.state]);

  const getAllTypes = () => {
    API_SERVICE.getTypes().then(({ data }) => {
        if(data){
            localStorage.setItem('enumValues', JSON.stringify(data))
        }
    })
}

const getUsersPermissions = () => {
  const user = localStorage.getItem("user");
  API_SERVICE.getUsersPermission(
    JSON.parse(user as string).userDetails.userId
  ).then(({ data }) => {
    if (data) {
      localStorage.setItem(
        "userPermission",
        JSON.stringify(data.payload.roles)
      );
      window.location.reload();
    }
  })
};

  return (
    <AuthConsumer>
      {({ isAuth, updateAPICreds, permissions }) => {
        const tenantDetails = getUser()?.tenantDocumentDetails ?? {};
        const isBroker = tenantDetails?.businessProductType == "INSURANCE" && tenantDetails?.insuranceMode == "ONLINE"
        return isAuth ? (
          <Redirect to={{ pathname: isBroker ? "/dashboard" : redirectLink }} />
        ) : (
          <div className="login">
            <div className="login-wrapper">
              {/* <div className="logo">
                <img src={Logo} alt="Logo" />
              </div> */}
              <Card className={"login-form-wrapper"}>
                <Title level={2}>{"Sign In"}</Title>
                <Paragraph>
                  Please enter your mobile number and password to access your
                  account
                </Paragraph>
                {
                  <div className="form-wrapper">
                    <LoginForm
                        onChange={setFields}
                        fields={fields}
                        onLogin={async (
                          mobileNumber: string,
                          password: string
                        ) => {
                          setLoading(true);
                          try {
                            const { data } = await API_SERVICE.login({
                              mobileNumber,
                              password
                            });
                            if (!data) {
                              throw t("login.unableToLogin");
                            }
                            if (data?.payload?.userDetails?.role == "ROLE_CONNECTOR") {
                              throw `User with: ${mobileNumber} not allowed/permitted to login`;
                            }
                            let redirectURL = data?.payload?.tenantDocumentDetails?.tenantRedirectionUrl ?? "";
                            // if(redirectURL.length > 0) {
                            //   // redirectURL = "https://tenant-d3dcweb.oneflotest.in/login";
                            //   redirectURL = "";
                            // }
                            const dcWebURLs = [
                              "https://uat-dcweb.oneflotest.in", 
                              "https://dev-dcweb.oneflotest.in", 
                              "https://dev1-dcweb.oneflotest.in", 
                              "https://dev2-dcweb.oneflotest.in", 
                              "https://dev3-dcweb.oneflotest.in", 
                              "https://d4-dcweb.oneflotest.in", 
                              "https://demo-dcweb.oneflotest.in",
                              "https://pp.oneflotest.in", 
                              "https://oneflo.in",
                              "http://localhost:3011"
                            ]
                            if(redirectURL.length > 0 && redirectURL != window.location.origin) {
                              if(dcWebURLs.includes(window.location.origin)) {
                                notification.error({message: `User with: ${mobileNumber} not allowed/permitted to login`})
                                setTimeout(() => {
                                  window.location.replace(redirectURL);
                                }, 2000);
                              } else {
                                throw `User with: ${mobileNumber} not allowed/permitted to login`;
                              }
                            } else if(dcWebURLs.includes(window.location.origin) || 
                                      redirectURL == window.location.origin) {
                              updateAPICreds(data);
                              getUsersPermissions();
                              getAllTypes();
                            } else {
                              throw `User with: ${mobileNumber} not allowed/permitted to login`;
                            }
                          } catch (e) {
                            notification.error({
                              message: t(API_SERVICE.handleErrors(e)),
                            });
                            setLoading(false);
                          }

                        } }
                        loading={loading}
                        setLoading={setLoading} 
                        reCaptchaTokenForConfirmMobile={reCaptchaTokenForConfirmMobile}
                         setReCaptchaTokenForConfirmMobile={setReCaptchaTokenForConfirmMobile}
                          showCaptchaForConfirmMobile={showCaptchaForConfirmMobile}
                           setShowCaptchaForConfirmMobile={setShowCaptchaForConfirmMobile}                    />
                  </div>
                }
                {/* <Divider /> */}
                <Row justify={"space-around"}>
                  <Col>
                    <Link to="/forgot-password">
                      {t("genDict.forgotPassword")}
                    </Link>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>
        );
      }}
    </AuthConsumer>
  );
}
