import React, { useEffect, useState } from "react";
import { PHONE_NUMBER_REGEX } from "shared/constants/AppConstants";
import { Row, Col, Form, Button, Input, notification } from "antd";
import API_SERVICE from "shared/services/api-service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import FormItem from "antd/es/form/FormItem";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";
import "./index.scss";
import AppLoading from "../AppLoading/AppLoading";
import { getUserDetails } from "shared/services/Utility";

type Props = {};

const AddEmployeesBroker: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isPrimaryUser, setIsPrimaryUser] = useState(false);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();
  let { id } = useParams() as any;
  const location = useLocation() as any;
  const fullName = location.state?.fullName;
  const type = location.state?.type;
  const [form] = Form.useForm();

  useEffect(() => {
    const title = fullName ?? (id ? "Edit Employee" : "Add New Employee");
    dispatch(
      setScreenHeader({
        backScreenTitle: "Employee",
        backScreenPath: "/employees",
        screenTitle: title,
      })
    );
    if (id) {
      getUserDetail();
    }
  }, []);

  const getUserDetail = () => {
    setLoading(true);
    API_SERVICE.getUser(id)
      .then(({ data }) => {
        if (data) {
          form.setFieldsValue({
            name: data.payload.userDetails.fullName,
            phoneNumber: data.payload.userDetails.phoneNumber,
            email: data.payload.userDetails.emailId,
          });
          setIsPrimaryUser(data.payload.userDetails.isPrimaryUser);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const buildPayload = (formData: any) => {
    const payload = {
      email: formData.email,
      name: formData.name.trim(),
      phoneNumber: formData.phoneNumber,
      lineManager: isPrimaryUser ? null : getUserDetails()?.userId,
    };
    return payload;
  };

  const addEmployee = (values: any) => {
    setLoading(true);
    const payload = buildPayload(values);
    API_SERVICE.addEmployee(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/employees");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      });
  };

  const updateEmployee = (values: any) => {
    setLoading(true);
    const payload = {...buildPayload(values), userId: id };
    API_SERVICE.updateEmployee(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/employees");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      });
  };

  const onFinishForm = (values: any) => {
    if (id) {
      updateEmployee(values);
    } else {
      addEmployee(values);
    }
  };

  const btnPrimaryStyles: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
    width: "100%",
    marginTop: "10px",
  };

  return (
    <div className="add-emp-wrapper content-box">
      <Form
        layout="vertical"
        form={form}
        initialValues={{ remember: true }}
        autoComplete="off"
        name="leadForm"
        onFinish={onFinishForm}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={8} span={8}>
            <label style={{ marginBottom: "15px" }}>User Details</label>
            <div className="form-box">
              <Form.Item
                className="field-bg"
                label="Full Name"
                name="name"
                rules={[{ required: true, message: "Please enter full name." }]}
              >
                <Input
                  className="custom-input"
                  placeholder="Enter name"
                  readOnly={type == "VIEW"}
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Mobile Number"
                name="phoneNumber"
                rules={[
                  { required: true, message: "Please enter mobile number" },
                  {
                    pattern: PHONE_NUMBER_REGEX,
                    message: "Please enter valid mobile number",
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  placeholder="Enter mobile number"
                  readOnly={type == "VIEW"}
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Email ID"
                name="email"
                rules={[
                  { required: true, message: "Please enter email" },
                  { type: "email", message: "Please enter valid email" },
                ]}
              >
                <Input
                  className="custom-input"
                  placeholder="Enter email"
                  readOnly={type == "VIEW"}
                />
              </Form.Item>
              <FormItem>
                <Button
                  size="large"
                  className="button-submit dynamic-btn-primary"
                  style={btnPrimaryStyles}
                  hidden={type == "VIEW"}
                  htmlType="submit"
                  type="primary"
                >
                  {id ? "Save" : "Continue"}
                </Button>
              </FormItem>
            </div>
          </Col>
        </Row>
      </Form>
      {loading && <AppLoading />}
    </div>
  );
};

export default AddEmployeesBroker;
