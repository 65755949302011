import { CheckOutlined, CloseOutlined, CloudDownloadOutlined, FileAddOutlined, FileOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Modal, notification, Radio, Row, Select, Space, Tooltip } from "antd";
import { FaPen } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import Dragger from "antd/lib/upload/Dragger";
import { base64ToArrayBuffer, saveByteArray } from "shared/services/Utility";
import "../../index.scss";
import _ from "lodash";
import editIcon from "../../../../assets/images/editIcon.svg";
import cloudDown from "../../../../assets/images/cloudDown.svg"
import approvedIcon from "../../../../assets/images/apporveIcon.svg"
import {ReactComponent as AddFileGray} from "../../../../assets/images/upload-file-gray.svg";
import fileUploadIcon from "../../../../assets/images/fileUploadIcon.svg";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const PrimaryAccount: React.FunctionComponent<{ primaryAccountDetails: any, disablefieldPayout: any, setConnectorDetails: any }> = (props) => {
    let { id } = useParams() as any;

    const history = useHistory();
    const [primaryAccountDetailsForm] = Form.useForm() as any;
    const [viewModel, setViewModel] = useState(false);
    const [selectedFileList, setSelectedFileList] = useState(null as any);
    const [disableField, setDisableField] = useState(false);
    const [addressProofData, setAddressProofData] = useState(false);
    const [primaryAccountData, setPrimaryAccountData] = useState({} as any);
    const [bankFile, setBankFile] = useState(null as any);
    const [rejectionReasons, setRejectionReasons] = useState([]);
    const [primaryAccountStatus, setPrimaryAccountStatus] = useState(null as any);
    const [selectedRejectionReason, setSelectedRejectionReason] = useState(null as any);
    const [defaultBankRejectedReason, setDefaultbankRejectedReason] = useState(null as any);
    const [showRejectedReason, setShowRejectedReason] = useState(false);
    const [onBoardingStatus, setOnBoardingStatus] = useState([]);
    const [bankDocumentData, setBankDocumentData] = useState(null as any);
    const [bankDocType, setBankDocType] = useState(null as any);
    const [allowedAttechmentTypes] = useState(["application/pdf", "image/jpeg", "image/jpg", "image/png"]);
    const {appColors}: any = useSelector((state: RootState) => state.common);

    useEffect(() => {
        getAllTypes();

        if (props?.primaryAccountDetails?.accounts?.map((item) => {


            if (item.accType === "PRIMARY") {
                setPrimaryAccountData(item);

                if (item?.cancelledChequeDoc?.documentName === null) {
                    setBankFile(null);
                } else {
                    setBankFile(item?.cancelledChequeDoc?.documentName);
                }

                if (item?.status === "APPROVED") {
                    setPrimaryAccountStatus("APPROVED");
                    setSelectedRejectionReason(null);
                    setShowRejectedReason(false);
                } else if (item?.status === "REJECTED") {
                    setPrimaryAccountStatus("REJECTED");
                    setSelectedRejectionReason(item?.rejectionReason);
                    setShowRejectedReason(true);
                } else {
                    setPrimaryAccountStatus("APPROVED");
                    setSelectedRejectionReason(null);
                    setShowRejectedReason(false);
                }
            }
        }))

            props?.primaryAccountDetails?.accounts?.map((accountDetails) => {
                if (accountDetails.accType === "PRIMARY") {
                    if (accountDetails) {
                        primaryAccountDetailsForm.setFieldsValue({
                            upiId: accountDetails?.upiId,
                            bankName: accountDetails?.bankDetails?.bankName,
                            accountHolderName: accountDetails?.bankDetails?.accHolderName,
                            accountNumber: accountDetails?.bankDetails?.accNumber,
                            ifscCode: accountDetails?.bankDetails?.ifscCode,
                            doc: bankFile,
                            //status: primaryAccountStatus,
                            //rejectionReason: accountDetails?.rejectionReason
                        });
                    }
                }
            });
    }, [props.primaryAccountDetails]);

    useEffect(() => {
        if (props?.disablefieldPayout === true) {
            setDisableField(true);
        }
    });

    const getAllTypes = () => {
        API_SERVICE.getTypes()
            .then(({ data }) => {
                let filteredRejectionReason = data.find(
                    (item: any) => item.propertyName == "REJECTION_REASON"
                );
                setRejectionReasons(filteredRejectionReason.keyAndDisplayValueList);

                let filteredOnBoardingStatus = data.find(
                    (item: any) => item.propertyName == "ONBOARDING_STATUS"
                );
                setOnBoardingStatus(filteredOnBoardingStatus.keyAndDisplayValueList);
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    };

    const buildStatusPayload = () => {
        const buildStatusPayload = {
            accounts: [
                {
                    accType: "PRIMARY",
                    rejectionReason: primaryAccountStatus === "REJECTED" ? selectedRejectionReason : null,
                    status: primaryAccountStatus ? primaryAccountStatus : primaryAccountData.status,
                }
            ],
            basicDetails: null,
            kycDetails: null,
            otherDetails: null,
        };
        return buildStatusPayload;
    }

    const buildPayload = (formData: any, documenetLinkDetails: any) => {
        const payload = {
            accounts: [
                {
                    accType: "PRIMARY",
                    bankDetails: {
                        accHolderName: formData.accountHolderName,
                        accNumber: formData.accountNumber,
                        bankName: formData.bankName,
                        ifscCode: formData.ifscCode,
                    },
                    cancelledChequeDoc: {
                        businessId: documenetLinkDetails.businessId ? documenetLinkDetails.businessId : primaryAccountData?.cancelledChequeDoc?.businessId,
                        documentName: documenetLinkDetails.documentName ? documenetLinkDetails.documentName : primaryAccountData?.cancelledChequeDoc?.documentName,
                        documentSetId: documenetLinkDetails.documentSetId ? documenetLinkDetails.documentSetId : primaryAccountData?.cancelledChequeDoc?.documentSetId,
                        businessDocumentId: documenetLinkDetails.businessDocumentId ? documenetLinkDetails.businessDocumentId : primaryAccountData?.cancelledChequeDoc?.businessDocumentId,
                        documentType: documenetLinkDetails.documentType ? documenetLinkDetails.documentType : primaryAccountData?.cancelledChequeDoc?.documentType,
                    },
                    basicDetails: {
                        /*address: {
                            address: null,
                            area: null,
                            city: null,
                            country: null,
                            geoCode: null,
                            pinCode: null,
                        },
                        authorizedSignatory: {
                            active: true,
                            driverLicenseNo: null,
                            email: null,
                            gender: null,
                            managerId: null,
                            name: null,
                            pan: null,
                            phoneNumber: null,
                            primaryRole: null,
                            referralId: null,
                            userId: id,
                        },*/
                        /*businessName: null,
                        businessType: null,
                        connectorId: null,
                        connectorMobileNumber: null,
                        connectorName: null,
                        connectorType: null,
                        dateOfBirth: null,
                        emailId: null,
                        emailVerified: null,
                        employerName: null,
                        //gender: ("Male" === formData.gender ? "M" : ("Female" === formData.gender ? "F" : "O")),
                        gender: null,
                        name: null,
                        phoneNumber: null,
                        phoneVerified: null,
                        profession: null,
                        srmId: null,
                        srmMobileNumber: null,
                        srmName: null,*/
                    },
                    createdBy: null,
                    createdTime: null,
                    kycDetails: {
                        /*addressProofDoc: null,
                        createdBy: null,
                        createdTime: null,
                        currentAddress: {
                            address: null,
                            area: null,
                            city: null,
                            country: null,
                            geoCode: null,
                            pinCode: 0,
                            state: null,
                        },
                        lastUpdateBy: null,
                        lastUpdatedTime: null,*/
                        pan: formData.pan,
                        panName: null,
                        // rejectionReason: null,
                        // status: null,
                    },
                    /*lastUpdateBy: null,
                    lastUpdatedTime: null,*/
                    rejectionReason: selectedRejectionReason,
                    // status: primaryAccountStatus,
                    upiId: formData.upiId,
                }
            ],
            basicDetails: null,
            kycDetails: null,
            otherDetails: null
        };
        return payload;
    };
    const checkPanDetails = (e: any) => {
        e.persist();
        if (e.target.value) {
            const { value: nextValue } = e.target;
            const params = {
                panNumber: nextValue,
            };
            API_SERVICE.checkPanDetails(params).then(({ data }) => {
                primaryAccountDetailsForm.setFieldsValue({
                    panName: data?.payload?.fullName?.trim(),
                });
            });
        }
    };

    const onBankDetailsChange = (fileList) => {

        if (fileList.fileList?.length > 0) {
            setSelectedFileList(fileList);
        } else {
            setSelectedFileList(null);
        }
    };

    const onFinishForm = (values: any) => {
        if (primaryAccountData?.bankDetails?.accHolderName !== values?.accountHolderName ||
            primaryAccountData?.bankDetails?.accNumber !== values?.accountNumber ||
            primaryAccountData?.bankDetails?.bankName !== values?.bankName ||
            values?.bankDetails && values?.bankDetails?.file?.name != primaryAccountData?.cancelledChequeDoc?.documentName ||
            primaryAccountData?.bankDetails?.ifscCode !== values?.ifscCode ||
            primaryAccountData?.upiId !== values?.upiId || selectedFileList
        ) {
            const filterDataObject = [] as any;
            const keys = Object.keys(values);

            keys?.map((data) => {
                let filterData = primaryAccountData[`${data}`]
                filterDataObject.push({
                    key: data,
                    value: filterData,
                });
            });

            const convertedObject = Object.fromEntries(filterDataObject.map(item => [item.key, item.value]));
            let res = _.isEqual(convertedObject, values)

            if (res && null == selectedFileList) {

                notification.error({ message: "No Changes Found" })

            } else {

                if (selectedFileList) {

                    let formData = new FormData();
                    formData.append(selectedFileList.file.name, selectedFileList.file);
                    if (allowedAttechmentTypes.indexOf(selectedFileList.file.type) === -1) {
                        notification.error({ message: "Please attach only image(jpg/jpeg/png) or pdf file." });
                        return;
                    }
                    API_SERVICE.fileUpload(formData).then(({ data }) => {
                        let documentURL = data;
                        const params = {
                            "docFinancialYear": 0,
                            "documentURL": documentURL,
                            "documentName": selectedFileList.file.name,
                            "documentType": selectedFileList.file.type,
                        };

                        API_SERVICE.addDocument(params, props?.primaryAccountDetails?.basicDetails?.documentSetId).then(({ data }) => {
                            if (data) {
                                setAddressProofData(false);

                                let documenetLinkDetails = {
                                    "businessId": data?.payload?.businessId,
                                    "documentName": data?.payload?.documentName,
                                    "documentSetId": data?.payload?.documentSetId,
                                    "businessDocumentId": data?.payload?.businessDocumentId,
                                    "documentType": data?.payload?.documentType
                                };

                                const payload = buildPayload(values, documenetLinkDetails);

                                API_SERVICE.updateConnector(id, payload)
                                    .then(({ data }) => {
                                        if (data) {
                                            props.setConnectorDetails(data?.payload);
                                            notification.success({ message: "Connector Updated Successfully \n You will need to verify/reverify the attached document" });
                                            // history.push("/connectors");
                                        }
                                    })
                                    .catch((e: any) => {
                                        notification.error({ message: API_SERVICE.handleErrors(e) });
                                    });

                            }
                        }).catch((e: any) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        });


                    }).catch((e: any) => {
                        notification.error({ message: API_SERVICE.handleErrors(e) });
                    });
                } else {

                    let documenetLinkDetails = {
                        "businessId": null,
                        "documentName": null,
                        "documentSetId": null,
                        "businessDocumentId": null,
                        "documentType": null,
                    };

                    const payload = buildPayload(values, documenetLinkDetails);

                    API_SERVICE.updateConnector(id, payload)
                        .then(({ data }) => {
                            if (data) {
                                props.setConnectorDetails(data?.payload);
                                notification.success({ message: "Connector Updated Successfully \n You will need to verify/reverify the attached document" });
                                // history.push("/connectors");
                            }
                        })
                        .catch((e: any) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        });
                }
            }
        } else {
            notification.error({ message: "No Changes Found" });
        }
    };

    const onRejectedReason = (value: any) => {
        setSelectedRejectionReason(value);
    }

    const onStatusChange = (e) => {
        setPrimaryAccountStatus(e.target.value);
        if (e.target.value === "APPROVED") {
            setShowRejectedReason(false);
            setSelectedRejectionReason(null);

        } else if (e.target.value === "REJECTED") {
            setShowRejectedReason(true);

        }
    };

    const onBankStatusUpdate = () => {
        const payload = buildStatusPayload();
        API_SERVICE.updateConnectorStatus(id, payload)
            .then(({ data }) => {
                if (data) {
                    props.setConnectorDetails(data?.payload);
                    notification.success({ message: "Connector status Updated Successfully" });
                    setViewModel(false);
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    };

    const downloadDocument = (doc: any) => {
        if (doc && doc.businessId && doc.businessDocumentId) {
            API_SERVICE.downloadDocument(doc.businessId, doc.businessDocumentId).then(({ data }) => {
                let bytesData = base64ToArrayBuffer(data?.payload?.documentDetails)
                saveByteArray([bytesData], data?.payload?.documentName)
            }).catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
        }
    };

    const onClickEdit = () => {

        setViewModel(true);
        showDocumentImage(primaryAccountData?.cancelledChequeDoc);
    };

    const [showAddressPdf, setShowAddressPdf] = useState(false);

    const showDocumentImage = (doc: any) => {
        if (doc && doc.businessId && doc.businessDocumentId) {
            API_SERVICE.downloadDocument(doc.businessId, doc.businessDocumentId).then(({ data }) => {
                if (data?.payload?.fileExtn === "pdf") {
                    setBankDocumentData(data?.payload?.payload?.documentDetails);
                    setBankDocType("application/pdf");
                    setShowAddressPdf(true);
                } else {
                    setBankDocumentData(data?.payload?.documentDetails);
                    setBankDocType(data?.payload?.fileExtn);
                }
            }).catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
        }
    };

    const onCancelPrimaryAccount = () => {
        setViewModel(false);
    }

    const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
    const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

    return (
        <>
            <Form
                layout="vertical"
                form={primaryAccountDetailsForm}
                initialValues={{
                    phoneCode: "+91",
                    profession: "Builder",
                    gender: "M",
                }}
                onFinish={onFinishForm}
                autoComplete="off"
                name="leadForm"
                disabled={disableField}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12} style={{ paddingRight: "0px", marginTop: "10px" }}>
                        <Divider orientation="left" orientationMargin="0"></Divider>
                        <Form.Item className="field-bg" label=" Bank Account Details" name="bankDetails">
                            {((undefined !== primaryAccountData?.status && null !== primaryAccountData?.status)) ?
                                ("APPROVED" === primaryAccountData?.status ?
                                    <label className="label-verified"><CheckOutlined />Verified</label>
                                    :
                                    ("REJECTED" === primaryAccountData?.status) ?
                                        <label className="label-verified-rejected"><CloseOutlined />Verification Rejected</label>
                                        :
                                        <label className="label-verficiation-pending">Verification Pending</label>
                                )
                                :
                                null
                            }
                            <Dragger accept=".pdf, .png, .jpg, .jpeg" name="file" maxCount={1}
                                beforeUpload={() => { return false; }} onChange={onBankDetailsChange}>
                                <p className="ant-upload-drag-icon">
                                    <Row style={{ display: "flex", justifyContent: "center" }}>
                                        <Col ><AddFileGray /></Col>
                                        <Col span={13}><h3 style={{ fontSize: "15px" }}>Upload Cancel Cheque</h3></Col>
                                    </Row>
                                </p>
                            </Dragger>
                        </Form.Item>

                        {undefined !== bankFile && null !== bankFile ?
                            <Form.Item className="field-bg" label="Bank Details" name="bankDetails">

                                <Row gutter={[16, 16]}>
                                    <Col span={2} style={{ display: "flex", justifyContent: "start" }}>
                                        <Space className="action-button" size="middle">
                                            <img src={fileUploadIcon} />
                                        </Space>
                                    </Col>
                                    <Col span={15}>
                                        <Form.Item
                                            style={{ overflow: "hidden" }}
                                            name="doc"
                                        >
                                            <Tooltip title={bankFile}>
                                                <label className="display-label">{bankFile}</label>
                                            </Tooltip>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                            <img src={approvedIcon} alt="cloudDown" />
                                        </Space>
                                        <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                            <img src={editIcon} alt="editIcon" onClick={onClickEdit} />
                                        </Space>
                                        <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                            <img src={cloudDown} alt="cloudDown" onClick={() => { downloadDocument(primaryAccountData?.cancelledChequeDoc) }} />
                                        </Space>
                                    </Col>
                                </Row>
                            </Form.Item>
                            :
                            null
                        }

                        {/* </Col>
                    <Divider className="custome-devider-hozntl" type="vertical" />
                    <Col span={10}> */}
                        <Divider orientation="left" orientationMargin="0">
                            Bank Details
                        </Divider>
                        <Form.Item
                            className="field-bg"
                            label="Bank Name"
                            name="bankName"
                            rules={[{ required: false, message: "Please enter Bank Name" }]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Bank Name"
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="Account Holder Name"
                            name="accountHolderName"
                            rules={[{ required: false, message: "Please enter Account Holder Name." }]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Account Holder Name"
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="Account Number"
                            name="accountNumber"
                            rules={[
                                { required: false, message: "Please enter Account Number." },
                                {
                                    pattern: /^[0-9]{9,18}$/,
                                    message: "Please enter valid account number"
                                }
                            ]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Account Number"
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="IFSC Code"
                            name="ifscCode"
                            rules={[{ required: false, message: "Please enter IFSC Code" }]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter IFSC Code"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[24, 24]} style={{ display: "flex", justifyContent: "end" }}>
                    <Divider />
                    <Col >
                        <Form.Item>
                            <Button
                                className="dynamic-btn-default"
                                style={btnDefaultBorderStyle}
                                size="large"
                                htmlType="submit"
                                onClick={() => {
                                    history.push("/connectors");
                                }}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col >
                        <Form.Item>
                            <Button
                                className="dynamic-btn-primary"
                                style={btnPrimaryStyles}
                                size="large"
                                type="primary"
                                htmlType="submit"
                            >
                                SAVE
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            {
                viewModel &&
                <Modal
                    width={900}
                    visible={viewModel}
                    okText="Update"
                    closable={false}
                    maskClosable={false}
                    keyboard={false}
                    footer={false}
                >
                    <div className="cardmain">
                        <div className="cardinner">
                            {bankDocumentData ?
                                (
                                    showAddressPdf ? (
                                        <iframe src={"data:" + bankDocType + ";base64," + bankDocumentData} className="iframe-pdf"></iframe>
                                    ) : (
                                        <img src={"data:" + bankDocType + ";base64," + bankDocumentData} alt="icons" className="img-address" />
                                    )
                                )
                                :
                                null
                            }
                        </div>
                    </div>
                    <Form layout="vertical" onFinish={onBankStatusUpdate}>
                        <Row gutter={[16, 16]}>
                            <Col span={10}>
                                <div className="form-box">
                                    <Form.Item label="Status"
                                        rules={[
                                            { required: false, message: "Please select status." },
                                        ]}>
                                        <Radio.Group onChange={onStatusChange} defaultValue={primaryAccountStatus}>
                                            <Space direction="vertical">
                                                {onBoardingStatus && onBoardingStatus.map((loan: any, i: number) => (
                                                    <Radio key={i} value={loan.key}>
                                                        {loan.value}
                                                    </Radio>
                                                ))}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                        {showRejectedReason &&
                            <Row gutter={[16, 16]}>
                                <Col span={10}>
                                    <Form.Item name="selectedRejectionReason"
                                        rules={[
                                            { required: (!selectedRejectionReason), message: "Please Select Rejected Reason." },
                                        ]}>
                                        <Select placeholder="Rejected Reason" onSelect={onRejectedReason} defaultValue={selectedRejectionReason}>
                                            {rejectionReasons.map((rejectionReason: any, i: number) => (
                                                <Select.Option value={rejectionReason.key} key={i}>{rejectionReason.value}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        <Divider />
                        <div style={{ display: "flex", justifyContent: "end", marginBottom: "10px", marginTop: "-10px" }}>
                            <Button style={{ marginRight: "10px" }} type="default" onClick={onCancelPrimaryAccount}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Update</Button>
                        </div>
                    </Form>
                </Modal>
            }
        </>
    );
};

export default PrimaryAccount;