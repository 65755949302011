import React from "react";
import { Row, Col, Typography, Space } from "antd";
import { ReactComponent as Icon1Flo } from "../../../assets/images/lending_page/1flo-white.svg";
import { ReactComponent as Email } from "../../../assets/images/lending_page/email-white.svg";
import "./Footer.scss";

const { Title, Text } = Typography;

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <Row gutter={[0, 16]} justify="space-between" className="row">
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="footer-logo">
            <Icon1Flo />
            <p>
              Have questions or need a Demo? Reach out to our team and discover
              how OneFlo can revolutionise your financial distribution
              processes.
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="footer-contact-col">
          <div className="footer-contact">
            <Title level={4}>Contact Us</Title>
            <Space>
              <Email />
              <Text>info@oneinfinity.in</Text>
            </Space>
          </div>
        </Col>
      </Row>

      <hr className="footer-line" />

      {/* Copyright Text */}
      <div className="footer-copyright">
        <Text className="copyright-text">
          © 2025 OneFlo. All Rights Reserved.
        </Text>
      </div>
    </div>
  );
};

export default Footer;