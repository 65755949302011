import "./index.scss";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaEye, FaPen, FaPlus, FaSearch } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import type { ColumnsType } from 'antd/es/table';
import API_SERVICE from "shared/services/api-service";
import { hasUserRole } from "shared/services/Utility";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";
import {ReactComponent as IconEyeBorder} from "../../assets/images/eye_border.svg";
import {ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";
import {ReactComponent as IconDeleteBorder} from "../../assets/images/delete_border.svg";
const { Title } = Typography;
type Props = {};

const Offers: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  // const location = useLocation() as any;
  const [allOffers, setAllOffers] = useState<"" | any>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [apiCount, setApiCount] = useState(0);
  const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState();
  const [searchValue, setSearchValue] = useState(null as any);
  const [form] = Form.useForm();
  const {appColors}: any = useSelector((state: RootState) => state.common);
  // const [activeKey, setActiveKey] = useState("1");

  const dispatch = useDispatch();

  const columns: ColumnsType<any> = [
    {
      title: "Offer",
      key: "offer",
      dataIndex: ["mobileOfferDocumentDetails", "documentDetails"],
      render: (documentUrl: any, record: any) => {
        const document = (record?.webOfferDocumentDetails?.length ?? 0) > 0 ? record.webOfferDocumentDetails[0] : {};
        return <Space className="action-button" size="middle">
          <img
            src={
              "data:image/" +
              document.fileExtn +
              ";base64," +
              document.documentDetails
            }
            width="100px"
            height="40px"
            alt="banner"
          />
        </Space>
      }
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Created",
      dataIndex: "creationDate",
      key: "date",
      render: (creationDate: any, record: any) => (
        <span>
          {moment(new Date(creationDate))
            .locale("en")
            .format("DD/MM/YYYY, hh:mm A")}
        </span>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (_: any, record: any) => (
        <Switch
          style={customStyles}
          checked={record.active}
          disabled={
            !(
              userRolePermission["UpdateOperations"] ||
              userRolePermission["Admin"]
            )
          }
          onChange={(event) => onChangeStatus(event, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: 'right',
      align: 'center',
      width: 130,
      render: (_: any, record: any) => (
        <Space className="action-button" size="small">
          <Button
            className="custome-btn-icon"
            style={customStyles}
            size="small"
            type="text"
            icon={<IconEyeBorder />}
            onClick={() => goToLeadDetails1(record)}
          />
          <Button
            className="custome-btn-icon-secondary"
            style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
            size="small"
            type="text"          
            icon={<IconEditBorder />}
            disabled={userRolePermission["ReadOnly"]}
            hidden={
              !(
                userRolePermission["UpdateLead"] ||
                userRolePermission["UpdateOperations"] ||
                userRolePermission["Admin"]
              )
            }
            onClick={() => goToLeadDetails(record)}
          />
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={(event) => handleDelete(event, record)}
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
          >
              <Button
                style={{backgroundColor:"transparent", border:'none'}}
                size="small"
                type="text"
                disabled={userRolePermission["ReadOnly"]}
                hidden={
                  !(
                    userRolePermission["UpdateLead"] ||
                    userRolePermission["UpdateOperations"] ||
                    userRolePermission["Admin"]
                  )
                }
                icon={<IconDeleteBorder width={24} height={24}/>}
              />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Offers"}));
    getAllOffers();
  }, []);

  const handleDelete = (event: any, item: any) => {
    const id = item.busCommId;
    setLoading(true);
    API_SERVICE.getAPIForDeleteDataInList(id)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          let page = null as any;
          notification.success({ message: data.message });
          if ((totalCount / 10) === parseFloat((currentPage - 1) + "." + 1)) {
            setCurrentPage(1);
            page = 1;
          } else {
            setCurrentPage(currentPage);
            page = currentPage;
          }

          getAllOffers(page, pageSize, searchValue);
        }
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const goToLeadDetails = (item: any) => {
    history.push(`/offers/edit-offer/${item.busCommId}`);
  };

  const goToLeadDetails1 = (item: any) => {
    history.push(`/offers/view-offer/${item.busCommId}`);
  };

  const getAllOffers = (pageNo: number = 1, pageSize: any = null, searchVal: any = null) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, searchParam: searchVal };
    API_SERVICE.getOffers(params)
      .then(({ data }: any) => {
        if (data.payload.content.length > 0) {
          // setAllOffers(data.payload);
          mapLeadsData(data);
        } else {
          notification.error({ message: "No Offer found." });
          mapLeadsData({});
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mapLeadsData = (data: any) => {
    setTotalCount(data?.payload?.totalElements ?? 0);
    setAllOffers(data?.payload?.content ?? []);
    setApiCount(1);
  };

  const onChangeStatus = (event: any, item: any) => {
    item.status = event;
    const id = item.busCommId;
    setLoading(true);
    API_SERVICE.updateOffers(
      {
        active: item.status,
        type: "OFFER",
        title: item.title,
        description: item.description,
      },
      id
    )
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          getAllOffers(currentPage, pageSize, searchValue)
        }
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handlePaginationChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setTotal(pagination.total);
    getAllOffers(pagination.current, pagination.pageSize, searchValue);
    // getAllOffers(pagination.current);
    // setPages(pagination.current)
  };

  // const onSelectOfferStatusFilter = (value, pageNo = 1, searchVal?: null) => {

  // }

  const addOfferHandler = () => {
    history.push("/offers/add-offer");
  };

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearchValue(event.target.value);
    const res = getAllOffers(1, pageSize, event.target.value);
  };

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};
  
  return (
    <>
      <div className="content-box">
        <Form form={form}> 
        <Row>
          <Col className="filter-bar" span={20} xs={{ span: 12 }}>
          <Form.Item name="searchBox">
            <Input
              size="large"
              className="searchBox"
              onChange={debounce(handleSearch, 1000)}
              placeholder="Search"
              allowClear
              prefix={<FaSearch />}
            />
            </Form.Item>
          </Col>

          <Col span={4} xs={{ span: 12 }} className="filter-bar filter-bar-right">
            <Button
              type="primary"
              
              className="refresh-button"
              onClick={() => {
                form.setFieldsValue({
                  searchBox: ""
                })
                setSearchValue("");
                getAllOffers(currentPage, pageSize);
              }}
              shape="round"
              //icon={<ReloadOutlined />}
              size={"middle"}
            ><img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}}/></Button>
            <Button
              type="primary"
              className="add-button dynamic-btn-primary"
              style={btnPrimaryStyles}
              disabled={!(userRolePermission["Admin"] || userRolePermission["CreateOperations"])}
              hidden={
                !(
                  userRolePermission["CreateLead"] ||
                  userRolePermission["CreateOperations"] ||
                  userRolePermission["Admin"]
                )
              }
              onClick={addOfferHandler}
              shape="round"
              icon={<FaPlus />}
              size={"middle"}
            >
              Add Offer
            </Button>
          </Col>
        </Row>
        </Form>
        <Row>
          <Col span={24}>
            <Table
              className="dynamic-pagination-checkbox"
              style={customStyles}
              loading={loading}
              columns={columns}
              dataSource={allOffers}
              scroll={{ x: 'max-content' }}
              size="small"
              onChange={handlePaginationChange}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Offers;
