import React from "react";
import "./FeatureItem.scss";
interface IFeatureItem {
  feature: any;
  isImageLeft: boolean;
}

const FeatureItem: React.FC<IFeatureItem> = (props: IFeatureItem) => {
  const { feature, isImageLeft } = props;

  const renderText = () => {
    return (
      <div className="feature-item-text">
        <span className="feature-item-title">{feature?.title}</span>
        <span className="feature-item-subtitle">{feature?.subTitle ?? ""}</span>
        {feature?.desc?.map((item, idx) => {
          return <span className="feature-item-desc">{item}</span>;
        })}
      </div>
    );
  };

  return (
    <div className="feature-item">
      {!isImageLeft && renderText()}
      <div className="feature-item-image">
        <div className="img-bg-shadow" />
        {feature.image}
      </div>
      {isImageLeft && renderText()}
    </div>
  );
};

export default FeatureItem;
