import React, { useEffect, useState } from "react";
import { ReactComponent as LogoLoantalk } from "../../../../assets/images/lending_page/logo_loantalk.svg";
import { ReactComponent as LogoM3 } from "../../../../assets/images/lending_page/logo_m3.svg";
import { ReactComponent as LogoSaiInfinity } from "../../../../assets/images/lending_page/logo_sai_infinity.svg";
import { ReactComponent as LogoVittakosh } from "../../../../assets/images/lending_page/logo_vittakosh.svg";
import { ReactComponent as UserLoantalk } from "../../../../assets/images/lending_page/user_loantalk.svg";
import { ReactComponent as UserM3 } from "../../../../assets/images/lending_page/user_M3.svg";
import { ReactComponent as UserSaiInfinity } from "../../../../assets/images/lending_page/user_sai_infinity.svg";
import { ReactComponent as UserVittakosh } from "../../../../assets/images/lending_page/user_vittakosh.svg";
import "./Reviews.scss";
import { Button, Rate } from "antd";

interface Props {}

const Reviews: React.FC<Props> = () => {
  const [selectedItem, setSelectedItems] = useState(0);
  const [timer, setTimer] = useState(0 as any);

  const reviewList = [
    {
      name: "Suraj Gupta",
      desc: "“OneFlo has been a game-changer for us at Moneybazar. Its user-friendly interface and powerful features have streamlined our loan processing, helping us manage leads more effectively. It’s the perfect tool for any DSA looking to scale.”",
      logo: <LogoM3 />,
      imageUser: <UserM3 />,
    },
    {
      name: "Premal Panchal",
      desc: "“OneFlo has been a great addition to our team at Sai Infinity. It simplifies how we manage leads and communicate with clients, making our work much easier. Thanks to OneFlo, we’re quicker and more efficient than ever before.”",
      logo: <LogoSaiInfinity />,
      imageUser: <UserLoantalk />,
    },
    {
      name: "Madhu Iyer",
      desc: "“Vittakosh has transformed our secured loan services with OneFlo! As experts in navigating through a network of Chartered Accountants, we've found the perfectally in OneFlo's DSA CRM system. It's not just a tool; it's our superpower, making every client interaction smoother and faster. Thanks to OneFlo, we’re not just keeping pace; we’re setting the pace in secured loans!”",
      logo: <LogoVittakosh />,
      imageUser: <UserSaiInfinity />,
    },
    {
      name: "Him Thakkar",
      desc: "“Using OneFlo @Loan Talk has truly transformed our operations. Its robust features have simplified our loan processing and lead management, making it an ideal solution for any DSA aiming to expand.”",
      logo: <LogoLoantalk />,
      imageUser: <UserVittakosh />,
    },
  ];

  useEffect(() => {
    if (timer) clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        if (selectedItem == reviewList.length - 1) {
          setSelectedItems(0);
        } else {
          setSelectedItems(selectedItem + 1);
        }
      }, 5000)
    );
    return function () {
      if (timer) clearTimeout(timer);
    };
  }, [selectedItem]);

  const previous = selectedItem == 0 ? reviewList.length - 1 : selectedItem - 1;
  const next = selectedItem == reviewList.length - 1 ? 0 : selectedItem + 1;

  return (
    <div className="review-main">
      <span className="name">{reviewList.at(selectedItem)?.name}</span>
      <div className="founder-of">
        Founder of {reviewList.at(selectedItem)?.logo}
      </div>
      <p className="review-desc">{reviewList.at(selectedItem)?.desc}</p>
      <Rate disabled defaultValue={5} style={{ justifySelf: "center" }} />
      <div className="review-users">
        <div className={`review-user`}>
          {reviewList.at(previous)?.imageUser}
        </div>
        <div className="review-user selected">
          {reviewList.at(selectedItem)?.imageUser}
        </div>
        <div className="review-user">{reviewList.at(next)?.imageUser}</div>
      </div>
      <Button className="btn-Get-in-Touch">Get in Touch!</Button>
    </div>
  );
};
export default Reviews;
