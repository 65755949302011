import React, { useEffect, useState } from "react";
import { Table, notification, Typography, Button, Tabs, Tooltip, Space, Form, Checkbox } from "antd";
import API_SERVICE from "shared/services/api-service";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import {ReactComponent as IconInfo} from "../../../../../assets/images/info.svg";
import passIcon from "../../../../../assets/images/pass.svg";
import failIcon from "../../../../../assets/images/fail.svg";

const { Title } = Typography;

interface IListLeadDetails {
  leadData: any;
  showDecisioning?: any;
  setLeadViewType?: any;
  hideCancel?: boolean;
  selectedLenderExecutionDataList?:any,
  setSelectedLenderExecutionDataList?:any
  descisioningDetail?:any
  setListDescisioningDetail?:any
  decisioningnTabkey?:any,
  setDecisioningTabkey?:any
}

const ListLeadDetails: React.FC<IListLeadDetails> = (
  props: IListLeadDetails
) => {
  const { 
    leadData, 
    showDecisioning, 
    setLeadViewType, 
    hideCancel,
    selectedLenderExecutionDataList,
    setSelectedLenderExecutionDataList,
    descisioningDetail,
    setListDescisioningDetail,
    decisioningnTabkey,
    setDecisioningTabkey
  } = props;
  // const [decisioningTabkey, setDecisioningTabkey] = useState(leadData?.journeyConfigResponseList?.at(0)?.journeyId);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableWithData, setTableWithData] = useState(false)
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    listDecisioning();
  }, []);

  const handlePaginationChange = (current: any, pageSize: any) => {
    setPageNo(current);
    setPageSize(pageSize);
  };

  const listDecisioning = () => {
    let tmpId = leadData?.journeyConfigResponseList?.map((item: any) => {
      return item?.journeyId
    })
    API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, true, '', tmpId)
      .then(({ data }) => {
        setListDescisioningDetail(data?.payload?.journeyWiseDecisioningDataList);
        setDecisioningTabkey(data?.payload?.journeyWiseDecisioningDataList?.at(0)?.journeyId)
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <Tooltip
          title="Select lenders to run offers for."
          placement="topLeft"
          color={appColors?.appPrimaryColor ?? "#1A3175"} 
        >
          <p className="border-edit-icon"><IconInfo style={{'--borderColor': appColors?.appPrimaryColor ?? ''}as any}/></p>
        </Tooltip>
      
      ),
      dataIndex: "lenderOfferExcelPublished",
      key: "lenderOfferExcelPublished",
      width:30,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderAvailableInOfferDecision &&
              record?.lenderOfferExcelPublished &&
              record?.gatingPassed && (
                <Form.Item name="lenderOfferExcelPublished">
                  <Checkbox
                    checked={selectedLenderExecutionDataList?.some(
                      (item) =>
                        `${item?.lenderInfoId}-${item?.decisioningPurpose}` ==
                        `${record?.lenderInfoId}-${record?.decisioningPurpose}`
                    )}
                    onChange={(e: any) => {
                      let tmpArr: any = [...selectedLenderExecutionDataList];
                      if (
                        selectedLenderExecutionDataList?.some(
                          (item) =>
                            `${item?.lenderInfoId}-${item?.decisioningPurpose}` ==
                            `${record?.lenderInfoId}-${record?.decisioningPurpose}`
                        )
                      ) {
                        tmpArr = selectedLenderExecutionDataList?.filter(
                          (item: any) =>
                            `${item?.lenderInfoId}-${item?.decisioningPurpose}` !=
                            `${record?.lenderInfoId}-${record?.decisioningPurpose}`
                        );
                      } else {
                        tmpArr.push({
                          lenderInfoId: record?.lenderInfoId,
                          decisioningPurpose: record?.decisioningPurpose,
                        });
                      }
                      setSelectedLenderExecutionDataList(tmpArr);
                    }}
                  />
                </Form.Item>
              )}
          </>
        );
      },
    },
    {
      title: "Lender Name",
      dataIndex: "lenderName",
      key: "lenderName",
      width:100,
      render: (_: any, record: any) => {
               return (
              <div style={{ width: "150px", whiteSpace: "pre-wrap" }}>
                <text>{record?.lenderName}</text>
              </div>
            );
          }
      },
    {
      title: (
        <div style={{ whiteSpace: "nowrap", textAlign: "center", fontSize:"13px" }}>
          Gating Eligibility
          <br />
          Passed
        </div>
      ),
      dataIndex: "gatingPassed",
      key: "gatingPassed",
      align: "center",
      width:120,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.gatingPassed ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: (
        <div style={{ whiteSpace: "nowrap", textAlign: "center", fontSize:"13px" }}>
          Offer 
          <br />
          Computation
        </div>
      ),
      dataIndex: "offerComputation",
      key: "offerComputation",
      align: "center",
      width:  100,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.offerComputation ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: "Max Loan Amount",
      dataIndex: "maxLoanAmount",
      key: "maxLoanAmount",
      width:120,
      align:"center",
      render: (_: any, record: any) => {
        return (
               record?.maxLoanAmount ?? "-"
        );
      },
    },
    {
      title: "Total EMI",
      dataIndex: "totalEmi",
      key: "totalEmi",
      width:  100,
      align:  "center",
      render: (_: any, record: any) => {
        return (
               record?.totalEmi ?? "-"
        );
      },
    },
    {
      title: "Tenure",
      dataIndex: "tenureInMonths",
      key: "tenureInMonths",
      width:  70,
      align:"center",
      render: (_: any, record: any) => {
        return (
               record?.tenureInMonths ?? "-"
        );
      },
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
      width:50,
      align:"center",
      render: (_: any, record: any) => {
        return (
               record?.roi ?? "-"
        );
      },
    },
    {
      title: (
        <div style={{ whiteSpace: "nowrap", textAlign: "center", fontSize:"13px" }}>
          Scorecard
          <br />
          Available
        </div>
      ),
      dataIndex: "lenderScorecardAvailable",
      key: "lenderScorecardAvailable",
      align: "center",
      width: 100,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderScorecardAvailable ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: (
        <div style={{ whiteSpace: "nowrap", textAlign: "center", fontSize:"13px" }}>
          Offer Decision
          <br />
          Configuration
        </div>
      ),
      dataIndex: "lenderAvailableInOfferDecision",
      key: "lenderAvailableInOfferDecision",
      align: "center",
      width:  100,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderAvailableInOfferDecision ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: (
        <div style={{ whiteSpace: "nowrap", textAlign: "center", fontSize:"13px" }}>
          Offer Calculation
          <br />
          Configuration
        </div>
      ),
      dataIndex: "lenderOfferExcelPublished",
      key: "lenderOfferExcelPublished",
      align: "center",
      width:  120,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderOfferExcelPublished ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      width:80,
      align:"center"
    },    
    
  ];

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? "",width:'110px',
    marginTop: "5px",marginBottom: "5px",
    marginLeft: "15px",borderRadius: "5px",display: hideCancel ? "none" : "",};

  return (
    <>
      <div className="">
        {!showDecisioning && (
          <div className="heading" style={{ marginLeft: "1.1rem" }}>
            <Title level={5} style={{ marginTop: "15px" }}>
              Decisioning
            </Title>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <Tabs
            className="lender-sub-tabs"
            style={{'--borderColor': appColors?.appPrimaryColor ?? ""} as any}
            size="small"
            activeKey={decisioningnTabkey}
            onChange={(key) => { setDecisioningTabkey(key); setSelectedLenderExecutionDataList([]) }}
          >
            {leadData?.journeyConfigResponseList?.map((data: any) => {
              let tmpFindData = descisioningDetail?.find((item: any) =>
                item?.journeyId == data?.journeyId
              );
              let sortedList = tmpFindData?.decisioningDataListResponseList?.sort((a: any, b: any) =>
                b.maxLoanAmount - a.maxLoanAmount
              );
              return (
                <Tabs.TabPane
                  key={data?.journeyId}
                  tab={data?.journeyName}
                >
                <Table
                    columns={columns}
                    dataSource={sortedList ?? []}
                    className="dynamic-pagination-checkbox"
                    style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
                    size="small"
                    onChange={(pagination: any) => {
                      handlePaginationChange(pagination.current, pagination.pageSize);
                    }}
                    pagination={{
                      current: pageNo,
                      pageSize: pageSize,
                      total: tmpFindData?.decisioningDataListResponseList?.length,
                      showSizeChanger: true
                    }}
                    scroll={{ x: "max-content", y:300 }}
                  />
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </div>
        <Button
          type="default"
          className="dynamic-btn-default"
          style={btnDefaultBorderStyle}
          size="large"
          onClick={() => setLeadViewType("LeadDetailNewTabs")}
        >
          Back
        </Button>
      </div>
    </>
  );
};
export default ListLeadDetails;
