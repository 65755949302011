import React, { useState } from "react";
import { Typography, Dropdown, Menu, Button, Drawer, Collapse } from "antd";
import { ReactComponent as Icon } from "../../../assets/images/lending_page/1flo.svg";
import { ReactComponent as MenuIcon } from "../../../assets/images/lending_page/Group1000001783.svg";
import close from "../../../assets/images/lending_page/material-symbols_close-rounded.svg";
import { Link } from "react-router-dom";
import { pageNames } from "./LendingPage";
import "./Header.scss"; // Import SCSS file
import { CONNECTOR_APP_URL } from "shared/parameters";

interface IHeader {
  page: pageNames;
  setPage: React.Dispatch<React.SetStateAction<pageNames>>;
}
const { Panel } = Collapse;

const Header: React.FC<IHeader> = (props: IHeader) => {
  const { page, setPage } = props;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Menu for the dropdown
  const menu = (
    <Menu className="custom-dropdown-menu">
      <Menu.Item className="employee-sign-in">
        <Link to="/login">Employee Sign In</Link>
      </Menu.Item>
      <Menu.Item className="connector-sign-in">
        <a href={CONNECTOR_APP_URL}>Connector Sign In</a>
      </Menu.Item>
    </Menu>
  );
  const showDrawer = () => {
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const renderDrawer = () => {
    return (
      <Drawer
        title={null}
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerOpen}
        width="65%"
        closable={false}
      >
        <div className="drawer-content">
          <Button
            className="drawer-close-btn"
            onClick={closeDrawer}
            style={{
              color: "black",
              border: "none",
              borderColor: "white",
              textAlign: "right",
              position: "absolute", // Positions the button absolutely
              top: 0, // Positions the button at the top
              right: 0,
            }}
          >
            <img src={close} alt="" />
          </Button>
          <div className="menu-row">
            <Button
              type="text"
              className={page == "SOLUTIONS" ? "btn-selected" : ""}
              onClick={() => {
                setPage("SOLUTIONS");
                closeDrawer();
              }}
            >
              Solutions
            </Button>
          </div>
          <div className="menu-row">
            <Button
              type="text"
              className={page == "FEATURES" ? "btn-selected" : ""}
              onClick={() => {
                setPage("FEATURES");
                closeDrawer();
              }}
            >
              Features
            </Button>
          </div>

          <div className="menu-row">
            <Button
              type="text"
              className={page == "CONTACT" ? "btn-selected" : ""}
              onClick={() => {
                setPage("CONTACT");
                closeDrawer();
              }}
            >
              Contact Sales
            </Button>
          </div>
          <Collapse
            defaultActiveKey={["1"]}
            expandIconPosition="end"
            className="lending-collapse"
          >
            <Panel
              header="Sign In"
              key="1"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              {" "}
              <Link to="/login" style={{ border: "none", color: "black" }}>
                Employee Sign In
              </Link>
              <br />
              <br />
              <a
                style={{ border: "none", color: "black" }}
                href={CONNECTOR_APP_URL}
              >
                Connector Sign In
              </a>
            </Panel>
          </Collapse>
        </div>
      </Drawer>
    );
  };

  return (
    <>
      <div className="header-container">
        <div className="container">
          <div className="first-column">
            <div className="logo-container">
              <Icon width={"70px"} onClick={() => setPage("HOME")} />
              <div className="divider"></div>
              <div className="filter-text">
                <Typography.Text
                  style={{ fontSize: "12px", textAlign: "left", width: "70px" }}
                >
                  Filter,Fulfill & Cross Sell
                </Typography.Text>
              </div>
            </div>
          </div>

          {/* Second Column */}
          <div className="second-column">
            <div className="text-container">
              <Button className={page == "SOLUTIONS" ? "btn-selected" : ""} type="text" onClick={() => setPage("SOLUTIONS")}>
                Solutions
              </Button>
              <Button className={page == "FEATURES" ? "btn-selected" : ""} type="text" onClick={() => setPage("FEATURES")}>
                Features
              </Button>
              <Button className={page == "CONTACT" ? "btn-selected" : ""} type="text" onClick={() => setPage("CONTACT")}>
                Contact Sales
              </Button>
            </div>
          </div>

          {/* Third Column */}
          <div className="third-column">
            {/* Sign In Dropdown */}
            <Dropdown
              overlay={menu}
              trigger={["click", "hover"]} // Trigger dropdown on both click and hover
              placement="bottomCenter"
            >
              <div className="sign-in">
                <Button style={{ border: "none", color: "black" }}>
                  Sign In
                </Button>
              </div>
            </Dropdown>
          </div>
          {page == "CONTACT" ? (
            <div className="mobile-menu-button">
              <Button className="btn-book-now">Book Now</Button>
            </div>
          ) : (
            <div className="mobile-menu-button" onClick={showDrawer}>
              <MenuIcon width="30px" />
            </div>
          )}
        </div>
      </div>
      <div
        className="whats-new"
        style={{
          background: "  linear-gradient(90deg, #0F467C 20%, #ED217C 100%)",
        }}
      >
        <Typography.Title style={{ color: "white" }} level={4}>
          What's New at OneFlo
        </Typography.Title>
      </div>
      {renderDrawer()}
    </>
  );
};

export default Header;
