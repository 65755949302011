import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Customer360 from "./Customer360";
import CRMSoftwareFeatures from "./CRMSoftwareFeatures";
import Reviews from "./Reviews";
import BoostProduction from "./BoostProduction";
import BoostProductionFetures from "./BoostProductionFetures";

interface props {}

const Home: React.FC<props> = () => {
  return (
    <div>
      <Customer360 />
      <CRMSoftwareFeatures />
      <BoostProduction />
      <BoostProductionFetures />
      <Reviews />
    </div>
  );
};
export default Home;
