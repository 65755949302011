import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { AuthConsumer } from 'shared/contexts/AuthContext';
import LendingPage from './components/LendingPage/LendingPage';

export default function ProtectedRoute({ component: Component, ...rest }: any) {
    return (
        <Route
            {...rest}
            render={(props) => (
                <AuthConsumer>
                    {({ isAuth, apiCreds }) => {
                        if (apiCreds?.userDetails?.changePasswordNextLogin) {
                            return <Redirect
                                to={{
                                    pathname: '/change-password',
                                    state: {data: true}
                                }}
                            />;
                        }
                        return isAuth ? (
                            <Component {...props} />
                        ) : (
                        <Switch>
                            <Route exact path={"/"} component={LendingPage} />
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: { from: rest.location },
                                }}
                            />
                        </Switch>
                        );
                    }
                    }
                </AuthConsumer>
            )}
        />
    );
}
