import React, { useState } from "react";
import {
  Layout,
  Typography,
  Row,
  Col,
  Button,
  Form,
  Input,
  notification,
} from "antd";
import contactIcon from "../../../../assets/images/lending_page/contact_banner.svg";
import ReCAPTCHA from "react-google-recaptcha";
import "./Contact.scss";
import emailjs from "emailjs-com";

const { Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;

const Contact = () => {
  const [form] = Form.useForm();
  const [reCaptchaToken, setReCaptchaToken] = useState("");
  
  const NOTIFICATION_DETAILS = {
    success: {
      message: "Details Submitted!",
      description:
        "We've got your information. Our team will get in touch you shortly!",
    },
    error: {
      message: "Something went wrong!",
      description: "Please try again later",
    },
    captchaError: {
      message: "Error!",
      description: "Please Verify Captcha",
    },
  };
  const showNotification = (type, details) => {
    notification[type]({
      message: details.message,
      description: details.description,
    });
  };

  const onFinish = (values) => {
    if (reCaptchaToken === "") {
      showNotification("error", NOTIFICATION_DETAILS.captchaError);
    } else if (values.error) {
      showNotification("error", NOTIFICATION_DETAILS.error);
    } else {
      emailjs
        .sendForm(
          "service_4qutqkp",
          "template_k49ixxh",
          "#myForm",
          "SKvHIFpxmCB8DtgPo"
        )
        .then(
          (result) => {
            console.log("email sent successfully");
            showNotification("success", NOTIFICATION_DETAILS.success);
            form.resetFields();
            setReCaptchaToken("");
          },
          (error) => {
            setReCaptchaToken("");
            console.log("email not sent");
          }
        );
    }
  };

  return (
    <>
      <div className="white">                          
        <div className="pagePadding">
          <div className="site-layout-content">
            <Row
              justify="space-between"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            >
              <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 13 }}>
                <img src={contactIcon} className="image-style" alt="contact" />
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 9 }}
                className="contactRight"
              >
                <div className="contactRightPnl">
                  <Title level={5} className="sectionTitle">
                    Fill in the form below and one of our experts will get in
                    touch with you.
                  </Title>
                  <Form
                    id="myForm"
                    labelCol={{ span: 24 }}
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    className="contact-form"
                  >
                    <Form.Item
                      name="fname"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your full name",
                        },
                      ]}
                    >
                      <Input
                        name="fname"
                        type="text"
                        placeholder="Full Name *"
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter valid email",
                        },
                        {
                          required: true,
                          message: "Please enter your email",
                        },
                      ]}
                    >
                      <Input name="email" placeholder="Email *" />
                    </Form.Item>
                    <Form.Item
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: "Please enter mobile number",
                        },
                        {
                          min: 10,
                          max: 10,
                          message: "Please enter 10 digit mobile number",
                        },
                      ]}
                    >
                      <Input
                        name="mobile"
                        type="Number"
                        placeholder="Mobile Number *"
                      />
                    </Form.Item>
                    <Form.Item
                      name="company"
                      rules={[
                        {
                          required: true,
                          message: "Please enter company name",
                        },
                      ]}
                    >
                      <Input
                        name="company"
                        type="text"
                        placeholder="Company Name *"
                      />
                    </Form.Item>
                    <Form.Item name="textarea">
                      <TextArea
                        name="textarea"
                        rows={4}
                        placeholder="Send your query to us"
                      />
                    </Form.Item>
                    <Form.Item>
                      <ReCAPTCHA
                        sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY"
                        onChange={(token) => setReCaptchaToken(token)}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="button-style"
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
