import React from "react";
import { notification } from "antd";
import { ReactComponent as LifeTerm } from "../../../assets/images/lifeterm.svg";
import { ReactComponent as Health } from "../../../assets/images/Health.svg";
import { ReactComponent as Invesment } from "../../../assets/images/Invesment.svg";
import { ReactComponent as Motor } from "../../../assets/images/motor.svg";
import API_SERVICE from "shared/services/api-service";
import { useLocation } from "react-router-dom";
import "./AddLeadBrokerStyle.scss";

type Props = {};

const AddLeadBroker: React.FunctionComponent<Props> = () => {
  const location = useLocation() as any;
  const products = location.state?.products;

  const setIcon = (Icon: any, strokeFill: boolean = false) => {
    return (
      <Icon
        height={50}
        width={50}
        className={strokeFill ? "stroke-fill" : ""}
      />
    );
  };

  const getLeadIcon = (name: string) => {
    if (name == "LIFE_TERM") {
      return setIcon(LifeTerm);
    } else if (name == "HEALTH") {
      return setIcon(Health, true);
    } else if (name == "LIFE_SAVE") {
      return setIcon(Invesment, true);
    } else if (name == "MOTOR") {
      return setIcon(Motor, true);
    }
  };

  const getLeadUrl = (leadItem: any) => {
    const payload = { oneSbProduct: leadItem.key };
    API_SERVICE.getLinkByLeadKey(payload)
      .then(({ data }) => {
        window.location.replace(data?.payload?.url);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  return (
    <div className="lead-wrapper">
      <div className="lead-container">
        <div className={`lead-items items-${products.length}`}>
          {products.map((item, i) => (
            <div
              onClick={() => getLeadUrl(item)}
              key={`${item.key}-${i}`}
              className="lead-item"
            >
              {getLeadIcon(item.key)}
              <p className="lead-label">{`${item.value}`}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddLeadBroker;
