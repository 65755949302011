import "./index.scss";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Typography,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect, useState } from "react";
import {ReactComponent as AddFileGray} from "../../assets/images/upload-file-gray.svg";
import { Link, useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import type { UploadProps } from "antd/es/upload/interface";
import Compressor from 'compressorjs';
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { Title } = Typography;
const AddOffer: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([] as any);
  const [fileListWeb, setfileListWeb] = useState([] as any);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [uploadedFileMobile, setuploadedFileMobile] = useState(null as any);
  const [uploadedFileWeb, setuploadedFileWeb] = useState(null as any);
  const [imageUrl1, setImageUrl1] = useState(null as any);
  const [compressedFile, setCompressedFile] = useState({} as any);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Offers",
        backScreenPath: "/offers",
        screenTitle: "Add New Offer",
      })
    );
  }, []);

  const uploadPropsMobile: UploadProps = {
    listType: "text",
    beforeUpload: (file) => {
      let uploadExt = ['image/jpeg', 'image/png']
      if (!uploadExt.includes(file.type)) {
        setFileList(null);
        setuploadedFileMobile(null);
        setImageUrl(null);
        notification.error({ message: 'Please upload valid image' })
        return false;
      }
    },
    fileList,
  };

  const uploadPropsWeb: UploadProps = {
    listType: "text",
    beforeUpload: (file) => {
      let uploadExt = ['image/jpeg', 'image/png']
      if (!uploadExt.includes(file.type)) {
        setfileListWeb(null);
        setuploadedFileWeb(null);
        setImageUrl1(null);
        notification.error({ message: 'Please upload valid image' })
        return false;
      }
    },
    fileList : fileListWeb,
  };
 
  const uploadDocumentHandlerMobile = (file: any) => {
    let name = file?.name;
    new Compressor(file, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        const formData = new FormData();
        formData.append("file", compressedResult, name);

        API_SERVICE.fileUpload(formData)
          .then(({ data }) => {
            if (data) {
              setImageUrl(data);
            }
          })
          .catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
          });
      },

    });
  };

  const uploadDocumentHandlerWeb = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    API_SERVICE.fileUpload(formData)
      .then(({ data }) => {
        if (data) {
          setImageUrl1(data);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onMobileUpload = useCallback((acceptedFiles: any) => {
    let uploadExt = ['image/jpeg', 'image/png']
    if (!uploadExt.includes(acceptedFiles.file.type)) {
      return;
    }
    setuploadedFileMobile(acceptedFiles.file.originFileObj);
    uploadDocumentHandlerMobile(acceptedFiles.file.originFileObj);
  }, []);

  const onWebUpload = useCallback((acceptedFiles: any) => {
    let uploadExt = ['image/jpeg', 'image/png']
    if (!uploadExt.includes(acceptedFiles.file.type)) {
      return;
    }
    setuploadedFileWeb(acceptedFiles.file.originFileObj);
    uploadDocumentHandlerWeb(acceptedFiles.file.originFileObj);
  }, []);

  const createPayload = (formData: any) => {
    let userData = localStorage.getItem("user") as any;
    if (userData) {
      userData = JSON.parse(userData);
    }
    const data = {
      type: "OFFER",
      title: formData.title,
      description: formData.description,
      webBannerDetail: [{
        documentName: uploadedFileWeb.name,
        documentURL: imageUrl1,
      }],
      mobileBannerDetail: [{
        documentName: uploadedFileMobile.name,
        documentURL: imageUrl,
      }],
    };
    return data;
  };

  const onFinish = (values: any) => {
    if(imageUrl === null){
      notification.error({message: "please select mobile file"});
      return;
    }

    if(imageUrl1 === null){
      notification.error({message: "please select web file"});
      return;
    }


    const payload = createPayload(values);
    API_SERVICE.addOfferOrNotice(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/offers");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <div className="add-offer-wrapper content-box">
      <Form
        layout="vertical"
        initialValues={{
          phoneCode: "+91",
          profession: "Builder",
          gender: "M",
        }}
        form={form}
        autoComplete="off"
        name="leadForm"
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              name="Mobile Upload"
              label="MOBILE image should be 340*170(JPEG and PNG only)"
              rules={[
                {
                  required: true,
                  message: "Please Select Mobile Offer",
                }
              ]}
            >
              <Upload
                className="upload-wrapper1"
                listType="picture"
                accept="image/png , image/jpeg"
                onChange={onMobileUpload}
                {...uploadPropsMobile}
                maxCount={1}
              >
                {uploadedFileMobile ? (
                  <img
                    src={URL?.createObjectURL(uploadedFileMobile)}
                    width="370px"
                    height="80px"
                    alt="mobile offer"
                  />
                ) : (
                  <>
                    <AddFileGray /> Upload Mobile Offer
                  </>
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="Web Upload"
              label="WEB image should be 1100*270(JPEG and PNG only)"
              rules={[
                {
                  required: true,
                  message: "Please Select Web Offer",
                }
              ]}
            >
              <Upload
                className="upload-wrapper1"
                listType="picture"
                accept="image/png , image/jpeg"
                onChange={onWebUpload}
                {...uploadPropsWeb}
                // beforeUpload={() => false}
              >
                {uploadedFileWeb ? (
                  <img
                    src={URL.createObjectURL(uploadedFileWeb)}
                    width="370px"
                    height="80px"
                    alt="mobile offer"
                  />
                ) : (
                  <>
                    <AddFileGray /> Upload Web Offer
                  </>
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter Title",
                },
                {
                  pattern: /^[A-Za-z\\s ]+$/,
                  message: "Plesae enter valid Title",
                },
              ]}
            >
              <Input
                className="custom-input"
                placeholder="Please enter Title"
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter Description",
                },
              ]}
            >
              <TextArea
                className="custom-textarea"
                autoSize={{ minRows: 5, maxRows: 8 }}
                placeholder="Please enter Description"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddOffer;
