import React, { useEffect, useState } from "react";
import { Badge, Button, Drawer, Dropdown, Layout, Menu, Space, Typography, notification } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {ReactComponent as IconMenu} from "../../../assets/images/menu.svg";
import {ReactComponent as IconUser} from "../../../assets/images/my_account.svg";
import {ReactComponent as IconLogout} from "../../../assets/images/logout.svg";
import {ReactComponent as IconHelp} from "../../../assets/images/sidebar/Help.svg";
import {ReactComponent as Back_Arrow} from "../../../assets/images/back_arrow.svg";
import SideBar from "./SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { AuthConsumer } from "shared/contexts/AuthContext";
import headerNotificationIcon from "../../../assets/images/headerNotificationIcon.svg";
import { Link, useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { setNotificationCount } from "shared/redux/notificationCount-reducer";
import { getUser, hasUserRole, setAppTitle } from "shared/services/Utility";
import { FaBars } from "react-icons/fa";
import "./AppLayout.scss";
import { DASHBOARD_ROUTE } from "shared/constants/RouteConstants";
import { setAppColors } from "shared/redux/common-reducer";
import { setTenantInfo } from "shared/redux/tenant-reducer";
import { setPostLenderCase, setPreLenderCase } from "shared/redux/lead-reducer";
import { border, borderColor, position } from "polished";

const { Content, Sider } = Layout;
const { Title } = Typography;

export default function AppLayout({ children }: any) {
  //   const { screenTitle } = useSelector((state: RootState) => state.common);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [collapsedSlider, setCollapsedSider] = useState(false);
  const [showSiderMobile, setShowSiderMobile] = useState(false);
  const [userData, setUserData] = React.useState(null as any);
  const [businessDocumentId, setBusinessDocumentId] = useState("");
  const [documentData, setDocumentData] = useState(null as any);
  const [docType, setDocType] = useState(null as any);
  
  const { notificationCount } = useSelector(
    (state: RootState) => state.notificationCount
  );
  const { screenHeader } = useSelector((state: RootState) => state.common);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const { screenTitle, backScreenTitle, backScreenPath } = screenHeader;
  const isMobile = width <= 768;

  const tenantDetails = getUser()?.tenantDocumentDetails ?? {};
  const isBroker = tenantDetails?.businessProductType == "INSURANCE" && tenantDetails?.insuranceMode == "ONLINE"
  const hideSideBar = isBroker;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      setUserData(JSON.parse(user));
    }

    setAppTitle();
    getTenantDetails();
    getUnreadNotificationCnt();

    if(isBroker) {
      getDocUpload(getUser()?.tenantDocumentDetails?.tenantLogoDocumentDetail?.businessDocumentId)
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const getDocUpload = (Id: any) => {
    API_SERVICE.getDocDetails(Id)
      .then(({ data }) => {
        localStorage.setItem("getlogoDetail", JSON.stringify(data?.payload));
        setDocumentData(data?.payload?.documentDetails);
        setDocType(data?.payload?.fileExtn);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const getUnreadNotificationCnt = () => {
    API_SERVICE.getUnreadNotificationCnt()
      .then(({ data }) => {
        dispatch(setNotificationCount(data?.payload?.unReadCount));
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getTenantDetails = () => {
    API_SERVICE.getTenantDocumentDetails()
      .then(({ data }) => {

        const payload = {
          ...data?.payload,
        };

        if((!userRolePermission["DecisioningAudit"] || !userRolePermission["Admin"]) && !payload?.showDecisioningAudit && window.location.pathname == "/decisioning-audit"){
          history.push(DASHBOARD_ROUTE);
        }
        let obj = {} as any;
        payload?.tenantColorConfig?.map((item: any) => {
          obj[item.element] = item.hexCode;
        })

        const tmpObj = {
          appPrimaryColor: obj?.primary_color,
          appSecondaryColor: obj?.secondary_color,
          appSecondaryLightColor: obj?.secondary_color ? `${obj?.secondary_color}33` : "",
          appPrimaryLightColor: obj?.primary_color ? `${obj?.primary_color}33` : "",
        }
        dispatch(setAppColors(tmpObj));
        dispatch(setTenantInfo(payload));
        localStorage.setItem(
          "tenantWorkingCapitalEnabled",
          data?.payload?.tenantWorkingCapitalEnabled
        );
        localStorage.setItem(
          "dialerEnabled",
          data?.payload?.dialerEnabled
        );
        localStorage.setItem(
          "customer360Enabled",
          data?.payload?.customer360Enabled
        );
        if (payload?.businessProductType === "INSURANCE") {
          dispatch(setPostLenderCase("LOGGED"));
        }

        if (payload?.tenantLogoDocumentDetail?.businessDocumentId) {
          setBusinessDocumentId(payload?.tenantLogoDocumentDetail?.businessDocumentId)
        } else {
          localStorage.setItem("getlogoDetail", "{}");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const customStyles :any = {'--bgColor': appColors?.appPrimaryColor ?? ""}

  const menu = (
    <Menu style={{ borderRadius: "13px" }} >
      <Menu.Item
        key="1"
        icon={<IconUser height={20} width={20} />}
        onClick={() => history.push("/view-profile")}
        style={{paddingRight:'20px'}}
      >
        My Account
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<IconLogout height={20} width={20} />}
        onClick={() => {
          localStorage.clear();
          history.push(`/dashboard`);
          window.location.reload();
          document.title = "OneFlo";
        }}
        style={{paddingRight:'20px'}}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const renderLogo = () => {
    return documentData ? (
      <div className="logo-sidebar" style={{marginLeft:"100px"}}>
        <img src={"data:" + docType + ";base64," + documentData} alt="" />
      </div>
    ) : <></>
  }

  const renderHeader = () => {
    return (
      <div className={isMobile ? "app-header mview" : "app-header"}>
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {(isMobile && !hideSideBar) ? (
            <Button
              type="text"
              size={"middle"}
              onClick={() => setShowSiderMobile(true)}
              icon={<FaBars />}
            />
          ) : (
            <></>
            // <img
            //   src={iconMenu}
            //   alt=""
            //   className={"sider-menu-icon" + (collapsedSlider ? " collapsed" : "")}
            //   onClick={() => setCollapsedSider(!collapsedSlider)}
            // />
          )}
          {isBroker && 
           !window.location.pathname.includes("employees") && 
           !window.location.pathname.includes("report") && 
            renderLogo()
          }
          <Title
            level={4}
            style={{
              marginBottom: "0px",
              marginLeft: "15px",
              fontWeight: "normal",
            }}
          >
            {(backScreenTitle?.length ?? 0) > 0 && (
              <>
                <Link className="link" to={backScreenPath ?? ""} style={customStyles}>
                  {backScreenTitle}
                </Link>
                &nbsp; &gt; &nbsp;
              </>
            )}
            {screenTitle}
          </Title>
        </div>
        <div className="header" hidden={isBroker}>
          <Badge count={notificationCount} className="notification-bell">
            <img
              src={headerNotificationIcon}
              style={{ width: "25px", height: "25px" }}
              onClick={() => history.push("/notificationbar")}
            />
          </Badge>
          <Dropdown overlay={menu} trigger={['hover']} placement="bottomRight" arrow>
            <Space>
              <div
                className="header-logo"
                onClick={() => history.push("/view-profile")}
              >
                {userData?.userDetails?.firstName
                  ? userData?.userDetails?.firstName?.at(0)
                  : " "}
              </div>             
              <DownOutlined
                style={{
                  marginRight: "5px",
                  marginBottom: "4px",
                }}
              />
            </Space>
          </Dropdown>
        </div>
        {isBroker && 
          <Button
            className="back-icon-btn"
            style={{
              width:"80px", 
              paddingLeft:"0px", 
              paddingRight:"6px",
              alignSelf:"center",
              marginRight:"50px"
            }}
            onClick={() => {
              if(window.location.pathname.includes("dashboard")) {
                localStorage.clear();
                history.push(`/login`);
                window.location.reload();
                document.title = "OneFlo";
              } else {
                history.goBack();
              }
            }}
          >
            <Back_Arrow /> Back
          </Button>
        }
      </div>
    );
  };

  const renderBody = () => {
    return (
      <>
        {/* <Content><AppRoutes /></Content> */}
        <Content>{children}</Content>
      </>
    );
  };

  return (
    <AuthConsumer>
      {({ isAuth, apiCreds }) => {
        return (
          <>
            <Layout style={{ minHeight: "100vh" }} className={`desktop-layout`}>
              {isMobile ? (
                <Drawer
                  footer={false}
                  visible={showSiderMobile}
                  placement="left"
                  closable={false}
                  width={230}
                  className="navbar-box"
                  onClose={() => setShowSiderMobile(false)}
                >
                  <div style={{ width: "220px", marginLeft: "0px" }}>
                    <SideBar key={2} closeNavHandler={setShowSiderMobile} businessDocumentId={businessDocumentId}/>
                  </div>
                </Drawer>
              ) : (
                <Sider collapsed={collapsedSlider} hidden={hideSideBar}>
                  <>
                    <SideBar
                      key={2}
                      closeNavHandler={setShowSiderMobile}
                      collapsedSlider={collapsedSlider}
                      businessDocumentId={businessDocumentId}
                    />
                    <IconMenu style={customStyles} height={24} width={24}  className="sider-icon-collapse"  onClick={() => setCollapsedSider(!collapsedSlider)} />
                  </>
                </Sider>
              )}

              <Layout>
                  {renderHeader()}
                <div className="content-wrapper">
                  <div className="body1">{renderBody()}</div>
                </div>
                <Link to={{ pathname: '/help', state: "fromFolatingButton" }} hidden={isBroker}> 
                  <Button
                 type="primary"
                 className='custome-btn-icon'
                 style={{'--bgColor': appColors?.appPrimaryColor ?? "",
                  borderColor:"lightgrey",
                  backgroundColor:"lightgrey",
                  position:"absolute",
                  bottom:"40px",
                  right:"40px",
                   zIndex:"1000"
                 } as any}
                shape="circle"
                icon={<IconHelp  width={"40px"}/>}  
                size="large"
                  />
                </Link>

              
              </Layout>
            </Layout>
          </>
        );
      }}
    </AuthConsumer>
  );
}
