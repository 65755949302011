import React, { useEffect, useState } from "react";
import welcomeBanner from "assets/images/welcomeBanner.png";
import { Typography, Carousel } from "antd";
import "./index.scss";
import { useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveKey,
  setPostLenderCase,
  setPreLenderCase,
  setRestFilterData,
} from "shared/redux/lead-reducer";
import { setScreenHeader } from "shared/redux/common-reducer";
import { ReactComponent as IconLead } from "../../assets/images/dashboard/lead.svg";
import { ReactComponent as IconVisit } from "../../assets/images/dashboard/visit.svg";
import { ReactComponent as IconProspect } from "../../assets/images/dashboard/prospect.svg";
import { ReactComponent as IconDocs } from "../../assets/images/dashboard/docs.svg";
import { ReactComponent as IconLenderSelection } from "../../assets/images/dashboard/lender_selection.svg";
import { ReactComponent as IconLoginIn } from "../../assets/images/dashboard/login_in.svg";
import { ReactComponent as IconSanctioned } from "../../assets/images/dashboard/sanctioned.svg";
import { ReactComponent as IconRejected } from "../../assets/images/dashboard/rejected.svg";
import { ReactComponent as IconWithdrawn } from "../../assets/images/dashboard/withdrawn.svg";
import { ReactComponent as IconPendency } from "../../assets/images/dashboard/pendency.svg";
import { ReactComponent as IconDisbursed } from "../../assets/images/dashboard/disbursed.svg";
import { ReactComponent as IconBil } from "../../assets/images/dashboard/bil.svg";
import { ReactComponent as IconSmallBil } from "../../assets/images/dashboard/small_bil.svg";
import { ReactComponent as IconHomeLoan } from "../../assets/images/dashboard/home_loan.svg";
import { ReactComponent as IconLap } from "../../assets/images/dashboard/lap.svg";
import { ReactComponent as IconCommercialPurchase } from "../../assets/images/dashboard/commercial_purchase.svg";
import { ReactComponent as IconCreditCard } from "../../assets/images/dashboard/credit_card.svg";
import { ReactComponent as IconLifeInsurance } from "../../assets/images/dashboard/life_insurance.svg";
import { ReactComponent as IconServiceOrderFinancing } from "../../assets/images/dashboard/service_order_financing.svg";
import { ReactComponent as IconUnsecureOverdraft } from "../../assets/images/dashboard/unsecure_overdraft.svg";
import { ReactComponent as IconWorkingCapital } from "../../assets/images/dashboard/working_capital.svg";
import { ReactComponent as IconSupplyChainFinance } from "../../assets/images/dashboard/supply_chain_finance.svg";
import { ReactComponent as IconLas } from "../../assets/images/dashboard/las.svg";
import { ReactComponent as IconRealty } from "../../assets/images/dashboard/realty.svg";
import { ReactComponent as IconPL } from "../../assets/images/dashboard/personal_loan.svg";
import { ReactComponent as IconEL } from "../../assets/images/dashboard/education_loan.svg";
import { ReactComponent as Iconbnpl } from "../../assets/images/dashboard/bnpl.svg";
import { ReactComponent as IconAddConnector } from "../../assets/images/dashboard/add_connector.svg";
import { ReactComponent as IconHealthInsurance } from "../../assets/images/dashboard/health_insurance.svg";
import { ReactComponent as IconTwoInsurance } from "../../assets/images/dashboard/two_insurance.svg";
import { ReactComponent as IconFourInsurance } from "../../assets/images/dashboard/four_insurance.svg";
import { ReactComponent as IconAutoLoan } from "../../assets/images/dashboard/auto_loan.svg";
import { ReactComponent as IconMicroLAP } from "../../assets/images/dashboard/micro-lap.svg";
import { ReactComponent as IconNewProduct } from "../../assets/images/dashboard/new-product.svg";
import { ReactComponent as IconCounterOffer } from "../../assets/images/dashboard/counter_Offer.svg";
import { ReactComponent as IconUnderwritingReject } from "../../assets/images/dashboard/underwriting_Reject.svg";
import { RootState } from "shared/redux/store";
import { getUser } from "shared/services/Utility";

const { Title } = Typography;

type Props = {};

const Dashboard: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [allOffers, setAllOffers] = useState<"" | any>([]);
  const [preLenderItems, setPreLenderItems] = useState(null as any);
  const [postLenderItems, setPostLenderItems] = useState(null as any);
  const [loanProducts, setLoanProducts] = useState(null as any);
  const [enumValues, setEnumValues] = useState(null as any);
  const [totalLeads, setTotalLeads] = useState(0);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "My Dashboard"}));
    // getAllLeads();
    // getPostLender();
    getAllTypes();
    getAllOffers();
  }, []);

  useEffect(() => {
    getAllLeads();
    getPostLender();
  }, [tenantInfo]);

  const setIcon = (Icon: any, strokeFill: boolean = false) => {
    return (
      <Icon
        height={30}
        width={30}
        className={strokeFill ? "stroke-fill" : ""}
      />
    );
  };

  const createConnector = [
    {
      icon: setIcon(IconAddConnector),
      name: "Connector",
      path: "/connectors/add-connector",
    },
  ];

  const updateCountInsureLender = (data) => {
    let preMenusItems = [
      {
        icon: setIcon(IconLead),
        name: "Lead",
        path: "/lead",
        count: data.totalCasesInLeadState,
        state: {
          selected: true,
          name: "LEAD",
          type: "leadStage",
          activeTab: "PRE_LENDER",
        },
      },
      {
        icon: setIcon(IconProspect, true),
        name: "Prospect",
        path: "/lead",
        count: data.totalCasesInProspectState,
        state: {
          selected: true,
          name: "PROSPECT",
          type: "leadStage",
          activeTab: "PRE_LENDER",
        },
      },
      {
        icon: setIcon(IconDocs, true),
        name: "Documentation",
        path: "/lead",
        count: data.totalCasesInDocumentState,
        state: {
          selected: true,
          name: "DOCUMENTATION",
          type: "leadStage",
          activeTab: "PRE_LENDER",
        },
      },
    ];
    setPreLenderItems(preMenusItems);
  };

  const updateCount = (data) => {
    let preMenusItems = [
      {
        icon: setIcon(IconLead),
        name: "Lead",
        path: "/lead",
        count: data.totalCasesInLeadState,
        state: {
          selected: true,
          name: "LEAD",
          type: "leadStage",
          activeTab: "PRE_LENDER",
        },
      },
      {
        icon: setIcon(IconProspect, true),
        name: "Prospect",
        path: "/lead",
        count: data.totalCasesInProspectState,
        state: {
          selected: true,
          name: "PROSPECT",
          type: "leadStage",
          activeTab: "PRE_LENDER",
        },
      },
      {
        icon: setIcon(IconVisit, true),
        name: "Visit",
        path: "/lead",
        count: data.totalCasesInVisitState,
        state: {
          selected: true,
          name: "VISIT",
          type: "leadStage",
          activeTab: "PRE_LENDER",
        },
      },
      {
        icon: setIcon(IconDocs, true),
        name: "Docs",
        path: "/lead",
        count: data.totalCasesInDocumentState,
        state: {
          selected: true,
          name: "DOCUMENTATION",
          type: "leadStage",
          activeTab: "PRE_LENDER",
        },
      },
    ];
    setPreLenderItems(preMenusItems);
  };

  const updateCountPostInsurer = (data) => {
    let postMenuItems = [
      {
        icon: setIcon(IconProspect, true),
        name: "Logged",
        path: "/lead",
        count: data?.totalLenderLoggedInCases,
        state: {
          selected: true,
          name: "LOGGED",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
      {
        icon: setIcon(IconVisit, true),
        name: "Policy Issued",
        path: "/lead",
        count: data.totalInsurerPolicyIssued,
        state: {
          selected: true,
          name: "POLICY_ISSUED",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
      {
        icon: setIcon(IconWithdrawn),
        name: "Withdrawn",
        path: "/lead",
        count: data.totalLenderWithdrawnCases,
        state: {
          selected: true,
          name: "WITHDRAWN",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
      
      {
        icon: setIcon(IconCounterOffer),
        name: "Counter Offer",
        path: "/lead",
        count: data.totalInsurerPolicyCounterOffer,
        state: {
          selected: true,
          name: "COUNTER_OFFER",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
      {
        icon: setIcon(IconUnderwritingReject),
        name: "Underwriting Reject",
        path: "/lead",
        count: data.totalInsurerPolicyUnderwritingReject ,
        state: {
          selected: true,
          name: "UNDERWRITING_REJECT",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
    ];

    setPostLenderItems(postMenuItems);
  };

  const updateCountPostLender = (data) => {
    let postMenuItems = [
      {
        icon: setIcon(IconLenderSelection),
        name: "Lender Selection",
        path: "/lead",
        count: data.totalLenderAssignedCases,
        state: {
          selected: true,
          name: "ASSIGNED",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
      {
        icon: setIcon(IconLoginIn),
        name: "Log in",
        path: "/lead",
        count: data?.totalLenderLoggedInCases,
        state: {
          selected: true,
          name: "LOGGED",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
      {
        icon: setIcon(IconSanctioned),
        name: "Sanctioned",
        path: "/lead",
        count: data.totalLenderSanctionedCases,
        state: {
          selected: true,
          name: "SANCTIONED",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
      {
        icon: setIcon(IconRejected, true),
        name: "Rejected",
        path: "/lead",
        count: data.totalLenderRejectedCases,
        state: {
          selected: true,
          name: "REJECTED",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
      {
        icon: setIcon(IconWithdrawn),
        name: "Withdrawn",
        path: "/lead",
        count: data.totalLenderWithdrawnCases,
        state: {
          selected: true,
          name: "WITHDRAWN",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
      {
        icon: setIcon(IconPendency),
        name: "Pendency",
        path: "/lead",
        count: data.totalLenderPendencyCases,
        state: {
          selected: true,
          name: "PENDENCY",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
      {
        icon: setIcon(IconDisbursed),
        name: "Disbursed",
        path: "/lead",
        count: data.totalLenderDisbursedCases,
        state: {
          selected: true,
          name: "DISBURSED",
          type: "leanderStage",
          activeTab: "POST_LENDER",
        },
      },
    ];

    setPostLenderItems(postMenuItems);
  };

  const getAllLeads = () => {
    API_SERVICE.getLeadsByFilter(1, 1,"DASHBOARD_COUNT", {}).then(({ data }) => {
      if (data?.payload?.content) {
        if (tenantInfo?.businessProductType === "INSURANCE") {
          updateCountInsureLender(data?.payload);
        } else {
          updateCount(data?.payload);
        }
        setTotalLeads(data?.payload?.totalCases);
      }
    });
  };

  const getPostLender = () => {
    API_SERVICE.getLeadsByFilterPostCase("ASSIGNED", 1, 1,"DASHBOARD_COUNT", {}).then(
      ({ data }) => {
        if (data?.payload?.content) {
          if (tenantInfo?.businessProductType === "INSURANCE") {
            updateCountPostInsurer(data?.payload);
          } else {
            updateCountPostLender(data?.payload);
          }
        }
      }
    );
  };

  const getLoanIcon = (name: string) => {
    if (name == "Business Installment Loan") {
      return setIcon(IconBil);
    } else if (name == "Small Business Loan") {
      return setIcon(IconSmallBil, true);
    } else if (name == "Home loan") {
      return setIcon(IconHomeLoan, true);
    } else if (name == "Loan Against Property") {
      return setIcon(IconLap);
    } else if (name == "Commercial Purchase") {
      return setIcon(IconCommercialPurchase, true);
    } else if (name == "Credit Card") {
      return setIcon(IconCreditCard, true);
    } else if (name == "Life Insurance Policy") {
      return setIcon(IconLifeInsurance);
    } else if (
      name == "Service order financing" ||
      name == "Service order  financing"
    ) {
      return setIcon(IconServiceOrderFinancing, true);
    } else if (name == "Unsecured Overdraft") {
      return setIcon(IconUnsecureOverdraft);
    } else if (name == "Working Capital Overdraft") {
      return setIcon(IconWorkingCapital);
    } else if (name == "Supply Chain Finance") {
      return setIcon(IconSupplyChainFinance, true);
    } else if (name == "Loan Against Securities") {
      return setIcon(IconLas, true);
    } else if (name == "Realty") {
      return setIcon(IconRealty, true);
    } else if (name == "Personal Loan") {
      return setIcon(IconPL);
    } else if (name == "Education Loan") {
      return setIcon(IconEL, true);
    } else if (name == "Buy Now Pay Later") {
      return setIcon(Iconbnpl, true);
    } else if (name == "Auto loan") {
      return setIcon(IconAutoLoan, true);
    } else if (name == "Two Wheeler Insurance") {
      return setIcon(IconTwoInsurance, true);
    } else if (name == "Four Wheeler Insurance") {
      return setIcon(IconFourInsurance, true);
    } else if (name == "Health Insurance") {
      return setIcon(IconHealthInsurance);
    } else if (name == "Micro LAP") {
      return setIcon(IconMicroLAP);
    }
    return setIcon(IconNewProduct, true);
  };

  const getAllTypes = () => {
    API_SERVICE.getTypes().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
        setEnumValues(data);
        let filteredLoanType = data.find(
          (item: any) => item.propertyName == "LOAN_TYPE"
        );
        if (filteredLoanType) {
          filteredLoanType = filteredLoanType.keyAndDisplayValueList;
        }
        let products = [] as any;
        filteredLoanType.map((item) => {
          products.push({
            name: item.value,
            path: "/lead/add-lead",
            icon: getLoanIcon(item.value),
            state: { radioValue: item.key },
          });
        });
        setLoanProducts(products);
      }
    });
  };

  const handleLeadRoute = (item) => {
    if (item?.path && "/lead" === item?.path) {
      dispatch(setRestFilterData());
      dispatch(setActiveKey(item?.state?.activeTab));
      if ("PRE_LENDER" === item?.state?.activeTab) {
        dispatch(setPreLenderCase(item?.state?.name));
      } else if ("POST_LENDER" === item?.state?.activeTab) {
        dispatch(setPostLenderCase(item?.state?.name));
      }
    }
    history.push(`${item.path}`, item.state);
  };

  useEffect(() => {
    getAllOffers();
  }, [flag]);

  const getAllOffers = (pageNo: number = 1, searchVal: any = null) => {
    const params = { status: "Active", pageNo: pageNo, pageSize: 4 };
    API_SERVICE.getOffers(params)
      .then(({ data }: any) => {
        if (data.payload.content.length > 0) {
          setAllOffers(data.payload.content);
          //   mapLeadsData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setFlag(true);
      });
  };

  const goToLeadDetails = (item: any) => {
    // history.push(`/lead/details/${item.caseDetails.caseId}`)
    history.push(`/offers/view-offer/${item.busCommId}`);
  };

  const customStyles: any = {
    "--appPrimary": appColors?.appPrimaryColor ?? "",
    "--appSecondary": appColors?.appSecondaryColor ?? "",
  };
  return (
    <div className="dashboard-wrapper content-box">
      <div className="offer-slider">
        {allOffers?.length > 0 ? (
          <>
            <Carousel className="custom-carousel" effect="fade">
              {allOffers.map((item: any, i: number) => {
                const document =
                  (item?.webOfferDocumentDetails?.length ?? 0) > 0
                    ? item.webOfferDocumentDetails[0]
                    : {};
                return (
                  <div key={i} onClick={() => goToLeadDetails(item)}>
                    <img
                      src={
                        "data:image/" +
                        document.fileExtn +
                        ";base64," +
                        document.documentDetails
                      }
                      alt="Carousel"
                      style={{ height: "230px" }}
                    />
                  </div>
                );
              })}
            </Carousel>
          </>
        ) : (
          <div className="custom-carousel">
            {(getUser()?.tenantDocumentDetails?.subDomain?.length ?? 0) > 0
            ? <div style={{ height: "230px", width: "100%"}}/>
            :
            <img
              src={welcomeBanner}
              alt="Carousel"
              style={{ height: "230px", width: "100%", objectFit: "contain" }}
            />
            }
          </div>
        )}
      </div>
      <div className="dashboard-menus" style={customStyles} >
        {preLenderItems?.length > 0 && (
          <Title level={5}>
            {tenantInfo?.businessProductType === "INSURANCE"
              ? "Pre Insurer"
              : "Pre Lender"}
          </Title>
        )}
        <ul>
          {preLenderItems?.map((item: any, index: number) => (
            <li>
              <a onClick={() => handleLeadRoute(item)}>
                {item.icon}
                <span className="count">{item.count}</span>
                <span>{item.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className="dashboard-menus" style={customStyles}>
        {postLenderItems?.length > 0 && (
          <Title level={5}>
            {tenantInfo?.businessProductType === "INSURANCE"
              ? "Post Insurer"
              : "Post Lender"}
          </Title>
        )}
        <ul>
          {postLenderItems?.map((item: any, index: number) => (
            <li>
              <a onClick={() => handleLeadRoute(item)}>
                {item.icon}
                <span className="count">{item.count}</span>
                <span>{item.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className="dashboard-menus" style={customStyles}>
        <Title level={5}>Add Lead</Title>
        <ul>
          {loanProducts?.map((item: any, index: number) => (
            <li>
              <a onClick={() => handleLeadRoute(item)}>
                {item.icon}
                <span>{item.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="dashboard-menus" style={customStyles}>
        <Title level={5}>Add Connector</Title>
        <ul>
          {createConnector.map((item: any, index: number) => (
            <li>
              <a onClick={() => handleLeadRoute(item)}>
                {item.icon}
                <span>{item.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;
