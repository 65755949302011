import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Space, Table, Tabs, Tooltip, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import info from "../../../../assets/images/info.svg";
import {ReactComponent as IconInfo} from "../../../../assets/images/info.svg";
import API_SERVICE from "shared/services/api-service";
import passIcon from "../../../../assets/images/pass.svg";
import failIcon from "../../../../assets/images/fail.svg";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IDecisioning {
  setIsLoading: any;
  leadData: any;
  getTaskData: any;
  setIsListDecisioning: any;
  isListDecisioning: any;
  submitTask: any;
  descisioningDetail:any;
  setListDescisioningDetail:any;
  selectedLenderExecutionDataList:any;
  setSelectedLenderExecutionDataList:any;
  decisioningnTabkey:any
  setDecisioningTabkey:any
  getNextTask:any
}

const Decisioning: React.FC<IDecisioning> = (props: IDecisioning) => {
  const {
    setIsLoading,
    leadData,
    getTaskData,
    setIsListDecisioning,
    isListDecisioning,
    submitTask,
    descisioningDetail,
    setListDescisioningDetail,
    selectedLenderExecutionDataList,
    setSelectedLenderExecutionDataList,
    decisioningnTabkey, 
    setDecisioningTabkey,
    getNextTask
  } = props;

  const { appColors }: any = useSelector((state: RootState) => state.common);
  useEffect(() => {
    if (isListDecisioning) {
      listDecisioning();
      setIsListDecisioning(false);
    }
    setSelectedLenderExecutionDataList([])
  }, [isListDecisioning]);

  const listDecisioning = () => {
    setIsLoading(true);
    //change true
    let tmpId = leadData?.journeyConfigResponseList?.map((item: any) => {
      return item?.journeyId
    })
    API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, true, '', tmpId)
      .then(({ data }) => {
        setDecisioningTabkey(data?.payload?.journeyWiseDecisioningDataList?.at(0)?.journeyId)
        setListDescisioningDetail(data?.payload?.journeyWiseDecisioningDataList);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  
  const actionContinue = (journeyId: any) => {
    setSelectedLenderExecutionDataList([]);
    if (getTaskData?.taskDefinitionKey == "decisioning_output_lender_list") {
      const tmp = selectedLenderExecutionDataList?.map((lender: any) => {
        return {
          decisioningPurpose: lender?.decisioningPurpose,
          lenderId: lender?.lenderInfoId,
        };
      });
      const payload = {
        taskId: getTaskData?.taskId,
        formData: JSON.stringify({
          caseId: leadData?.caseDetails?.caseId,
          selectedLenderExecutionDataList: tmp,
          journeyId: journeyId
        }),
      };
      submitTask(payload);
    } else {
      const payload = {
        processInstanceId: leadData?.processInstanceId,
        gotoStep: "loginStage",
      };
      getNextTask(payload);      
      
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <Tooltip
          title="Select lenders to run offers for."
          placement="topLeft"
          color={appColors?.appPrimaryColor ?? "#1A3175"} 
        >
          <p className="border-edit-icon"><IconInfo style={{'--borderColor': appColors?.appPrimaryColor ?? ''}as any}/></p>
        </Tooltip>      
      ),
      dataIndex: "lenderOfferExcelPublished",
      key: "lenderOfferExcelPublished",
      width:30,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderAvailableInOfferDecision &&
              record?.lenderOfferExcelPublished && 
               record?.gatingPassed && (
                <Form.Item name="lenderOfferExcelPublished">
                  <Checkbox
                    checked={selectedLenderExecutionDataList?.some(
                      (item) =>
                        `${item?.lenderInfoId}-${item?.decisioningPurpose}` ==
                        `${record?.lenderInfoId}-${record?.decisioningPurpose}`
                    )}
                    onChange={(e: any) => {
                      let tmpArr: any = [...selectedLenderExecutionDataList];
                      if (
                        selectedLenderExecutionDataList?.some(
                          (item) =>
                            `${item?.lenderInfoId}-${item?.decisioningPurpose}` ==
                            `${record?.lenderInfoId}-${record?.decisioningPurpose}`
                        )
                      ) {
                        tmpArr = selectedLenderExecutionDataList?.filter(
                          (item: any) =>
                            `${item?.lenderInfoId}-${item?.decisioningPurpose}` !=
                            `${record?.lenderInfoId}-${record?.decisioningPurpose}`
                        );
                      } else {
                        tmpArr.push({
                          lenderInfoId: record?.lenderInfoId,
                          decisioningPurpose: record?.decisioningPurpose,
                        });
                      }
                      setSelectedLenderExecutionDataList(tmpArr);
                    }}
                  />
                </Form.Item>
              )}
          </>
        );
      },
    },
    {
      title: "Lender Name",
      dataIndex: "lenderName",
      key: "lenderName",
      width:100,
      render: (_: any, record: any) => {
        return (
       <div style={{ width: "150px", whiteSpace: "pre-wrap" }}>
         <text>{record?.lenderName}</text>
       </div>
     );
    }
    },
    {
      title: "Purpose",
      dataIndex: "decisioningPurpose",
      key: "decisioningPurpose",
      width:65,
    },
    {
      title: (
        <div style={{ whiteSpace: "nowrap", textAlign: "center", fontSize:"13px" }}>
          Gating Eligibility
          <br />
          Passed
        </div>
      ),
      dataIndex: "gatingPassed",
      key: "gatingPassed",
      align: "center",
      width:120,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.gatingPassed ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: (
        <div style={{ whiteSpace: "nowrap", textAlign: "center", fontSize:"13px" }}>
          Offer 
          <br />
          Computation
        </div>
      ),
      dataIndex: "offerComputation",
      key: "offerComputation",
      width:100,
      align: "center",
      render: (_: any, record: any) => {
        return (
          <>
            {record?.offerComputation ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: "Max Loan Amount",
      dataIndex: "maxLoanAmount",
      key: "maxLoanAmount",
      align: "center",
      width:120,
      render: (_: any, record: any) => {
        return (
               record?.maxLoanAmount ?? "-"
        );
      },
    },
    {
      title: (
        <div style={{ whiteSpace: "nowrap", textAlign: "center", fontSize:"13px" }}>
          Scorecard
          <br />
          Available
        </div>
      ),dataIndex: "lenderScorecardAvailable",
      key: "lenderScorecardAvailable",
      width:100,
      align: "center",
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderScorecardAvailable ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: (
        <div style={{ whiteSpace: "nowrap", fontSize:"13px" }}>
          Offer Decision
          <br />
          Configuration
        </div>
      ),dataIndex: "lenderAvailableInOfferDecision",
      key: "lenderAvailableInOfferDecision",
      align: "center",
      width:100,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderAvailableInOfferDecision ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: (
        <div style={{ whiteSpace: "nowrap", fontSize:"13px" }}>
          Offer Calculation
          <br />
          Configuration
        </div>
      ),dataIndex: "lenderOfferExcelPublished",
      key: "lenderOfferExcelPublished",
      align: "center",
      width:120,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderOfferExcelPublished ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    }    
  ];



  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};
  return (
    <>
      <Tabs
        className="lender-sub-tabs"
        style={{'--borderColor': appColors?.appPrimaryColor ?? ""}as any}
        size="small"
        activeKey={decisioningnTabkey}
        onChange={(key) => { setDecisioningTabkey(key); setSelectedLenderExecutionDataList([]) }}
      >
        {leadData?.journeyConfigResponseList?.map((data: any) => {
          let tmpFindData = descisioningDetail?.find((item: any) =>
            item?.journeyId == data?.journeyId
          );
          return (
            <Tabs.TabPane
              key={data?.journeyId}
              tab={data?.journeyName}
            >
              <Table
                columns={columns}
                className="dynamic-pagination-checkbox"
                style={customStyles}
                dataSource={tmpFindData?.decisioningDataListResponseList ?? []}
                size="small"
                pagination={{ showSizeChanger: true }}
                tableLayout="fixed"
                scroll={{x:"max-content", y:300}}// y:410 for 10 records
              />
              <Button
                type="primary"
                className="dynamic-btn-primary"
                style={{'--btnColor' :appColors?.appPrimaryColor ?? "", marginLeft: "15px", 
                          marginTop : tmpFindData?.decisioningDataListResponseList ?? [] ? "15px": "-15px", marginBottom:"5px"}as any}
                size="large"
                htmlType="submit"
                disabled={decisioningnTabkey !== tmpFindData?.journeyId}
                onClick={() => actionContinue(tmpFindData?.journeyId)}
              >
                Continue
              </Button>
            </Tabs.TabPane>
          )
        })}
      </Tabs>
    </>
  );
};

export default Decisioning;
