import React from "react";
import { ReactComponent as ImgCustomer360 } from "../../../assets/images/lending_page/pageFeatures/customer360.svg";
import { ReactComponent as ImgBureauAnalysis } from "../../../assets/images/lending_page/pageFeatures/bureauAnalysis.svg";
import { ReactComponent as ImgBusinessRuleEngine } from "../../../assets/images/lending_page/pageFeatures/businessRuleEngine.svg";
import { ReactComponent as ImgCasePush } from "../../../assets/images/lending_page/pageFeatures/casePush.svg";
import { ReactComponent as ImgDocumentWhatsapp } from "../../../assets/images/lending_page/pageFeatures/documentWhatsapp.svg";
import { ReactComponent as ImgPayout } from "../../../assets/images/lending_page/pageFeatures/payout.svg";
import { ReactComponent as ImgStatementAnalysis } from "../../../assets/images/lending_page/pageFeatures/statementAnalysis.svg";
import FeatureItem from "./FeatureItem";
import "./Footer.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const Features: React.FC = () => {
  const { isMobile }: any = useSelector((state: RootState) => state.common);
  
  const featuresList = [
    {
      title: "Customer 360",
      subTitle: "One view of customer",
      desc: [
        "Complete financial health of customer",
        "Pitch right product, right lender at right time",
        "Free integrated dialler, customized script ",
      ],
      image: <ImgCustomer360 />,
    },
    {
      title: "Case push through API",
      subTitle: "One window to connect to all BFSI",
      desc: [
        "50+ Lender APIs, 20+ Insurance APIs & Wealth APIs",
        "Connect APIs in hours, use your code",
        "No API integration cost, pay as you use",
      ],
      image: <ImgCasePush />,
    },
    {
      title: "Bureau Analysis",
      subTitle: "One window to customer credit history",
      desc: [
        "Customer bureau history in real time",
        "Derive insights for gating",
        "Understand customer leverage & debt restructuring",
      ],
      image: <ImgBureauAnalysis />,
    },
    {
      title: "Document collection through Whatsapp",
      subTitle: "One window to all conversation",
      desc: [
        "Converse with customers/connectors via integrated Whatsapp",
        "Collect/push documents on Whatsapp",
        "Documents auto-updated on CRM",
      ],
      image: <ImgDocumentWhatsapp />,
    },
    {
      title: "Statement Analyzer",
      subTitle: "One window to customer financials",
      desc: [
        "Easily calculate ABB for surrogates",
        "Insights from GST, ITR, P&L-BS",
        "Understand investment, spending & income patterns",
      ],
      image: <ImgStatementAnalysis />,
    },
    {
      title: "Business Rule Engine",
      subTitle: "One window to all decisioning",
      desc: [
        "Use model BRE to predict right products",
        "Configure gating & eligibility BREs",
        "Access custom rule engines",
      ],
      image: <ImgBusinessRuleEngine />,
    },
    {
      title: "Payout Module",
      subTitle: "One window to all payout needs",
      desc: [
        "Manage connector invoicing & payouts",
        "Manage invoicing to Lenders/Insurers",
        "Manage cashflow, regulate incoming & outgoing",
      ],
      image: <ImgPayout />,
    },
  ];

  return (
    <div className="">
      {featuresList.map((item, idx) => {
        return <FeatureItem feature={item} isImageLeft={isMobile ? false : (idx % 2 != 0)} />;
      })}
    </div>
  );
};

export default Features;
